<template>
  <div class="p-2">
    <div class="font-medium">
      {{ item.title }} <span
        v-if="!item.optional"
        class="text-red-800">*</span>
    </div>
    <div class="text-sm text-gray-800">
      {{ item.description }}
    </div>
    <div class="mt-4">
      <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div
            v-for="option in item.options"
            :key="option"
            class="relative flex items-start">
          <div class="flex items-center h-5">
            <input
                type="checkbox"
                class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded">
          </div>
          <div class="ml-3 text-sm">
            <label class="font-medium text-gray-700">{{ option }}</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { QLFormField } from "@/graphql/queries/ql/composables";
import { toRef }       from "vue";

interface Props {
  item: QLFormField;
}

const props = withDefaults(defineProps<Props>(), {});

const emit = defineEmits([]);

const item = toRef(props, "item");
</script>
