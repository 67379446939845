<template>
  <div v-if="hasClients">
    <div  class="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-6">
      <member-card v-for="client in visibleClients" :key="client.id" :single-event="singleEvent" :visit="client" />
    </div>
  </div>
</template>

<style scoped></style>

<script lang="ts" setup>
import { QLEventCalculateSegmentQueryVariables, QLSegment, QLSingleClassFragmentFragment, QLSortOrder, useEventCalculateSegmentQuery } from "@/graphql/queries/ql/composables";
import { computed, ref, toRef, watch, useSlots } from "vue";
import SegmentDecorator from "@/composables/decorators/SegmentDecorator";
import MemberCard from "@/views/Events/Components/Show/MemberCard.vue";

interface Props {
  singleEvent: QLSingleClassFragmentFragment;
  segment: QLSegment;
}

const props = withDefaults(defineProps<Props>(), {});

const singleEvent = toRef(props, "singleEvent");

const clientsVariables = computed<QLEventCalculateSegmentQueryVariables>(() => {
  return {
    class_model_id: singleEvent.value.id,
    segment: SegmentDecorator(props.segment).computed.toPost,
    page: 1,
    first: 200,
    orderBy: [
      {
        column: "last_name",
        order: QLSortOrder.ASC
      }
    ]
  };
});

const hasError = ref(false);

const { result: clients, loading } = useEventCalculateSegmentQuery(clientsVariables);

const clientsIdsToShow = computed(() => clients.value?.event_calculate_segment.data ?? []);

const visibleClients = computed(() => {
  return singleEvent.value.visits.filter((visit) => {
    return clientsIdsToShow.value.some(client => client.id == visit.client.id);
  });
});

const emit = defineEmits(["visibleCountChanged", "clientIdsChanged", "client-ids-visible"]);
watch(() => visibleClients.value, (newClients) => {
  emit("visibleCountChanged", newClients.length);
  emit("clientIdsChanged", newClients.map(client => client.client.id));
  emit("client-ids-visible", newClients.map(client => client.client.id));
});

const hasClients = computed(() => {
  return !loading.value && (visibleClients.value.length > 0);
});

const isEmpty = computed(() => {
  return !loading.value && (visibleClients.value.length == 0);
});

</script>
