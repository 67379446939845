import {BookingGroup}     from "hm-filters/filters/partials/BookingGroup";
import FixedValueOperator from "hm-filters/operators/FixedValueOperator";
import { ThisEventGroup } from "hm-filters/filters/partials/ThisEventGroup";

export class ThisEventBookingStatus extends ThisEventGroup {

	constructor(name: string, options: any) {
		super(name, options);

		this.clearOperators();
		this.addOperators([
			                  new FixedValueOperator("any", "any booking status"),
			                  new FixedValueOperator("attended", "attended"),
			                  new FixedValueOperator("upcoming", "upcoming"),
			                  new FixedValueOperator("absent", "absent"),
			                  new FixedValueOperator("late_cancelled", "late cancelled"),
			                  new FixedValueOperator("early_cancelled", "early cancelled"),
			                  new FixedValueOperator("any_cancelled", "all cancelled/absent"),
		                  ]);
	}

	get type(): string {
		return "ThisEventBookingStatus";
	}

}
