<template>
  <div>
    <label v-if="label"
           class="form-label"
           v-bind:class="{'has-error': hasError}"
           v-bind:for="id">
      {{ label }} <span v-if="required" class="text-xs opacity-50" v-bind:class="{'is-required': requiredAndEmpty}">(required)</span>
    </label>
    <input v-bind:id="id"
           ref="htmlinput"
           v-model="inputData"
           :max="max"
           :maxlength="maxLength"
           :min="min"
           :minlength="minLength"
           :placeholder="placeholder"
           :type="type"
           class="form-input form-input-default mt-1"
           v-bind:autocomplete="autocomplete !== null ? autocomplete : undefined"
           v-bind:autofocus="focus"
           v-bind:class="{'has-error': hasError}"
           v-bind:disabled="disabled"
           v-bind:required="required">
    <span v-if="hasError" class="form-error-message">{{ errorMessage }}</span>
    <div v-if="smallInfo && smallInfo.length > 0" class="leading-tight form-small-info text-gray-600">{{
        smallInfo
                                                                                                      }}
    </div>
    <slot></slot>
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref } from "vue";

interface TextInputProps {
  label?: string | null;
  placeholder?: string | null;
  type?: string;
  smallInfo?: string;
  errorMessage?: string | null;
  required?: boolean;
  disabled?: boolean;
  focus?: boolean;
  min?: number | null;
  minLength?: number | null;
  max?: number | null;
  maxLength?: number | null;
  modelValue?: string | null;
  autocomplete?: string | null;
}

const props = defineProps<TextInputProps>();

const emit = defineEmits([ "update:modelValue" ]);

const id = ref(null);
const htmlinput = ref(null);

const inputData = computed({
                             get: () => props.modelValue,
                             set: (val) => {
                               emit("update:modelValue", val);
                             }
                           });

const hasError = computed(() => {
  return props.errorMessage && props.errorMessage.length > 0;
});

const requiredAndEmpty = computed(() => {
  if (hasError.value) {
    return false;
  }
  if (props.required && (!props.modelValue || props.modelValue.toString().length === 0)) {
    return true;
  }
  return false;
});

onMounted(() => {
  id.value = Math.random().toString();
  if (props.focus) {
    htmlinput.value.focus();
  }
});
</script>