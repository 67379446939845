<template>
  <div class="grow">
    <page-title
      :margin_class="'m-0'"
      :section="singleEvent ? decorator.classDefinition : ''"
      :title="singleEvent?.name">
    <template #right>
      <div class="flex items-center space-x-2">
        <button
          class="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-loopspark-700 bg-loopspark-100 hover:bg-loopspark-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
          type="button"
          @click="contactModalOpen = true">
          <svg-icon class="w-4 h-4" name="chats-circle-duotone" />
          <span class="ml-2">Contact</span>
        </button>
      </div>
    </template>
  </page-title>
    <div class="container mx-auto space-y-8 mt-0">
      <!-- Event Core Info - Full Width -->
      <div class="w-full">
        <event-core-info
            v-if="singleEvent"
            :single-event="singleEvent"
            @updated="classQ.refetch()"
            @open-virtual-sidebar="isVirtualSidebarOpen = true">
          <template #actions>
            <button
                class="inline-flex items-center justify-center px-4 py-2 border-transparent text-sm font-medium rounded-b-md shadow-sm text-loopspark-700 bg-loopspark-100 hover:bg-loopspark-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
                type="button"
                @click="contactModalOpen = true">
              <svg-icon
                  class="w-4 h-4"
                  name="chats-circle-duotone" />
              <span class="ml-2">Contact</span>
            </button>
          </template>
        </event-core-info>
      </div>
      
      <!-- Stats Boxes - Responsive Grid -->
      <div class="w-full">
        <div class="grid grid-cols-2 sm:grid-cols-3  gap-5" :class="{'md:grid-cols-4': orderedCustomSegments.length > 0}">
          <SingleTopBox
              v-for="sectionData in visibleCoreSections"
              :key="sectionData.key"
              :color="sectionData.main_color"
              :counts="sectionData.count"
              :icon="sectionData.icon"
              :loading="sectionData.loading"
              :segment="sectionData.segment"
              :title="sectionData.segment.name" />
          <SingleTopBox
              v-for="sectionData in orderedCustomSegments"
              :key="sectionData.key"
              :color="sectionData.main_color"
              :counts="sectionData.count"
              :icon="sectionData.icon"
              :loading="sectionData.loading"
              :segment="sectionData.segment"
              :title="sectionData.segment.name" />
        </div>
      </div>
    </div>
    <div class="container mx-auto grow">
      <!-- Event Core Information -->

      <div class="mt-10 grow">
        <!-- Detailed Sections -->
        <div class="space-y-10">
          <!-- Core Sections -->
          <SingleSection
              v-for="sectionData in visibleCoreSections"
              :key="sectionData.key"
              :from_gradient="sectionData.from_gradient"
              :icon="sectionData.icon"
              :main_color="sectionData.main_color"
              :segment="sectionData.segment"
              :single-event="singleEvent"
              :subtitle="sectionData.subtitle"
              :title="sectionData.segment.name"
              @attending-status="(count) => updateSectionData(sectionData.key, count)"
              @client-ids-visible="(ids) => updateSectionClientIds(sectionData.key, ids)" />

          <!-- Custom Segments -->
          <SingleSection
              v-for="sectionData in orderedCustomSegments"
              :key="sectionData.key"
              :from_gradient="sectionData.from_gradient"
              :icon="sectionData.icon"
              :main_color="sectionData.main_color"
              :segment="sectionData.segment"
              :single-event="singleEvent"
              :subtitle="sectionData.subtitle"
              :title="sectionData.segment.name"
              @attending-status="(count) => updateSectionData(sectionData.key, count)"
              @client-ids-visible="(ids) => updateSectionClientIds(sectionData.key, ids)" />

          <!-- Non-highlighted segments collapsible section -->
          <div v-if="hasAddon && nonHighlightedSegments.length > 0" class="mt-10">
            <button
                class="inline-flex items-center justify-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                type="button"
                @click="isExpandedSegments = !isExpandedSegments">
              <svg-icon
                  :name="isExpandedSegments ? 'arrow-up' : 'arrow-down'"
                  class="w-4 h-4" />
              <span class="ml-2">{{ isExpandedSegments ? "Hide" : "Show" }} Other Segments</span>
            </button>

            <div v-if="isExpandedSegments" class="space-y-10 mt-10">
              <SingleSection
                  v-for="sectionData in nonHighlightedSegments"
                  :key="sectionData.key"
                  :from_gradient="sectionData.from_gradient"
                  :icon="sectionData.icon"
                  :main_color="sectionData.main_color"
                  :segment="sectionData.segment"
                  :single-event="singleEvent"
                  :subtitle="sectionData.subtitle"
                  :title="sectionData.segment.name"
                  @attending-status="(count) => updateSectionData(sectionData.key, count)"
                  @client-ids-visible="(ids) => updateSectionClientIds(sectionData.key, ids)" />
            </div>
          </div>
        </div>
      </div>

      <event-contact-modal
          v-if="singleEvent"
          v-model:open="contactModalOpen"
          :segments="allVisibleSections.map(section => ({
          id: section.segment.id,
          name: section.segment.name,
          loading: section.loading,
          clientIds: section.clientIds,
          visible_dashboard: section.segment.visible_dashboard
        }))"
          :single-event="singleEvent" />
    </div>
    
    <event-edit-virtual-event-sidebar
      v-if="singleEvent"
      v-model:open="isVirtualSidebarOpen"
      :single-event="singleEvent"
      @updated="classQ.refetch()" /></div>
</template>

<script lang="ts" setup>
import EventContactModal                                    from "@/views/Events/Components/Show/EventContactModal.vue";
import EventCoreInfo                                        from "@/views/Events/Components/Show/EventCoreInfo.vue";
import EventEditVirtualEventSidebar                         from "@/views/Events/Components/Show/EventEditVirtualEventSidebar.vue";
import { useRouter }                                        from "vue-router";
import { QLSegment, useSegmentsQuery, useSingleClassQuery } from "@/graphql/queries/ql/composables";
import { echoListener }                                     from "@/composables/echoListener";
import { EventTypes }           from "@/classes/notifications/EventTypes";
import { computed, ref, toRef } from "vue";
import SvgIcon                  from "@/components/SvgIcon.vue";
import { Segment }                                          from "@/classes/entities/Segment";
import SingleSection                                        from "@/views/Events/Components/Show/SingleSection.vue";
import SingleTopBox                                         from "@/views/Events/Components/Show/SingleTopBox.vue";
import currentTeamQuery                                     from "@/composables/queries/currentTeamQuery";
import { PossibleIcons }                                    from "@/assets/icons/iconNames";
import TimestampToDate                                      from "@/components/layout/TimestampToDate.vue";
import PageTitle                                            from "@/components/navbar/PageTitle.vue";
import { EventDecorator }                                   from "@/composables/decorators/EventDecorator";

interface Props {
  id: string;
}

const props = withDefaults(defineProps<Props>(), {});
const router = useRouter();
const contactModalOpen = ref<boolean>(false);
const isVirtualSidebarOpen = ref<boolean>(false);

const classQ = useSingleClassQuery(() => ({ id: props.id }), { fetchPolicy: "cache-and-network" });

const { loading } = classQ;
const singleEvent = computed(() => classQ.result.value?.single_class ?? null);

const { computed: decorator } = EventDecorator(singleEvent);

const { currentTeam, loading: currentTeamLoading } = currentTeamQuery();
// Addon functionality check
const hasAddon = computed(() => {
  const addons = currentTeam.value?.addons || [];
  return addons.some(addon => addon.name === "event_insights" && addon.active);
});

interface SectionInformation {
  key: string;
  from_gradient: string;
  main_color: string;
  subtitle: string;
  loading: boolean;
  segment: QLSegment;
  count: number;
  icon: PossibleIcons;
  clientIds: string[];
}

// Core sections state
const coreSections = ref<Array<SectionInformation>>([
                                                      {
                                                        key:           "attended",
                                                        from_gradient: "from-green-50",
                                                        main_color:    "green",
                                                        subtitle:      "Clients that attended the event",
                                                        loading:       true,
                                                        segment:       Segment.createEmptyWithEvent("attended",
                                                                                                    "Attended"),
                                                        count:         0,
                                                        icon:          "users",
                                                        clientIds:     []
                                                      },
                                                      {
                                                        key:           "upcoming",
                                                        from_gradient: "from-green-50",
                                                        main_color:    "green",
                                                        subtitle:      "Clients that are going to attend the event",
                                                        loading:       true,
                                                        segment:       Segment.createEmptyWithEvent("upcoming",
                                                                                                    "Upcoming"),
                                                        count:         0,
                                                        icon:          "users",
                                                        clientIds:     []
                                                      },
                                                      {
                                                        key:           "any_cancelled",
                                                        from_gradient: "from-red-50",
                                                        main_color:    "red",
                                                        subtitle:      "Cancelled bookings",
                                                        loading:       true,
                                                        segment:       Segment.createEmptyWithEvent("any_cancelled",
                                                                                                    "Cancelled"),
                                                        count:         0,
                                                        icon:          "trash",
                                                        clientIds:     []
                                                      },
                                                      {
                                                        key:           "any",
                                                        from_gradient: "from-blue-50",
                                                        main_color:    "blue",
                                                        subtitle:      "Overview of all bookings",
                                                        loading:       true,
                                                        segment:       Segment.createEmptyWithEvent("any",
                                                                                                    "All members"),
                                                        count:         0,
                                                        icon:          "users",
                                                        clientIds:     []
                                                      }
                                                    ]);

// Dynamic segments state
const dynamicSections = ref<Array<SectionInformation>>([]);

// Visible core sections based on event timing
const visibleCoreSections = computed(() => {
  if (!singleEvent.value) {
    return [];
  }

  const eventStart = new Date(singleEvent.value.start_time * 1000);
  const sectionsToShow = eventStart > new Date()
                         ? [ "upcoming", "any_cancelled", "any" ]
                         : [ "attended", "any_cancelled", "any" ];

  return coreSections.value.filter(section =>
                                       sectionsToShow.includes(section.key)
  );
});

// Process segments from API
const segmentsQ = hasAddon.value
                  ? useSegmentsQuery({ is_communication: false, is_event: true },
                                     { fetchPolicy: "cache-and-network" })
                  : { loading: ref(false), result: ref({ segments: [] }) };
const segments = computed(() => segmentsQ.result.value?.segments ?? []);

const isExpandedSegments = ref<boolean>(false);

// Highlighted custom segments
const orderedCustomSegments = computed(() => {
  if (!hasAddon.value) {
    return [];
  }

  return segments.value
                 .filter(seg => !seg.archived && seg.visible_dashboard)
                 .sort((a, b) => (a.order ?? 0) - (b.order ?? 0))
                 .map(seg => {
                   const existingSection = dynamicSections.value.find(section => section.segment.id === seg.id);
                   if (existingSection) {
                     existingSection.segment = seg;
                     return existingSection;
                   }

                   const newSection: SectionInformation = {
                     key:           `dynamic-${ seg.id }`,
                     from_gradient: "from-gray-50",
                     main_color:    "gray",
                     subtitle:      seg.name,
                     loading:       true,
                     segment:       seg,
                     count:         0,
                     icon:          "users",
                     clientIds:     []
                   };
                   dynamicSections.value.push(newSection);
                   return newSection;
                 });
});

// Non-highlighted segments
const nonHighlightedSegments = computed(() => {
  return segments.value
                 .filter(seg => !seg.archived && !seg.visible_dashboard)
                 .sort((a, b) => (a.order ?? 0) - (b.order ?? 0))
                 .map(seg => {
                   const existingSection = dynamicSections.value.find(section => section.segment.id === seg.id);
                   if (existingSection) {
                     existingSection.segment = seg;
                     return existingSection;
                   }

                   const newSection: SectionInformation = {
                     key:           `dynamic-${ seg.id }`,
                     from_gradient: "from-gray-50",
                     main_color:    "gray",
                     subtitle:      seg.name,
                     loading:       true,
                     segment:       seg,
                     count:         0,
                     icon:          "users",
                     clientIds:     []
                   };
                   dynamicSections.value.push(newSection);
                   return newSection;
                 });
});

// All visible sections for contact modal
const allVisibleSections = computed(() => {
  return [
    ...visibleCoreSections.value,
    ...orderedCustomSegments.value,
    ...(isExpandedSegments.value ? nonHighlightedSegments.value : [])
  ];
});

// Update section data
const updateSectionData = (sectionKey: string, payload: { count: number; loading: boolean }) => {
  const section = [ ...visibleCoreSections.value, ...dynamicSections.value ]
      .find(section => section.key === sectionKey);

  if (section) {
    section.loading = payload.loading;
    section.count = payload.count;
  }
};

// Update client IDs for a section
const updateSectionClientIds = (sectionKey: string, clientIds: string[]) => {
  const section = [ ...visibleCoreSections.value, ...dynamicSections.value ]
      .find(section => section.key === sectionKey);

  if (section) {
    section.clientIds = clientIds;
  }
};

echoListener([ EventTypes.VisitWasUpdated ], (ev) => {
  if (ev.data.visit_id == props.id) {
    classQ.refetch();
  }
}, 5000);
</script>
