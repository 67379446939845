<template>
  <div class="bg-white shadow overflow-hidden sm:rounded-md">

    <div v-if="client.mutes.length === 0" class="p-4 text-center text-gray-500">
      No mute history found for this client.
    </div>
    <div v-else>
      <div class="table-container">
        <table class="table min-w-full divide-y divide-gray-200">
          <thead class="bg-gray-50">
          <tr>
            <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider" scope="col">
              Muted By
            </th>
            <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider" scope="col">
              Muted At
            </th>
            <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider" scope="col">
              Muted Until
            </th>
            <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider" scope="col">
              Unmuted By
            </th>
            <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider" scope="col">
              Unmuted At
            </th>
          </tr>
          </thead>
          <tbody class="bg-white divide-y divide-gray-200">
          <tr v-for="mute in client.mutes"
              :key="mute.id"
              :class="{ 'opacity-60': mute.unmuted_at || mute.muted_until < (now) }">
            <td :class="mute.unmuted_at || mute.muted_until < (now)  ? 'text-gray-400' : 'text-gray-900'"
                class="px-6 py-4 whitespace-nowrap text-sm">
              {{ mute.muted_by?.name || "N/A" }}
            </td>
            <td :class="mute.unmuted_at || mute.muted_until < (now)  ? 'text-gray-400' : 'text-gray-900'"
                class="px-6 py-4 whitespace-nowrap text-sm">
              <timestamp-to-date :timestamp="mute.muted_at" has-date has-time studio />
            </td>
            <td :class="mute.unmuted_at || mute.muted_until < (now)  ? 'text-gray-400' : 'text-gray-900'"
                class="px-6 py-4 whitespace-nowrap text-sm">
              <timestamp-to-date :timestamp="mute.muted_until" has-date has-time studio />
            </td>
            <td :class="mute.unmuted_at || mute.muted_until < (now)  ? 'text-gray-400' : 'text-gray-900'"
                class="px-6 py-4 whitespace-nowrap text-sm">
              {{ mute.unmuted_by?.name || "" }}
            </td>
            <td :class="mute.unmuted_at || mute.muted_until < (now)  ? 'text-gray-400' : 'text-gray-900'"
                class="px-6 py-4 whitespace-nowrap text-sm">
              <timestamp-to-date v-if="mute.unmuted_at"
                                 :timestamp="mute.unmuted_at"
                                 has-date
                                 has-time
                                 studio />

            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<style scoped>
.table-container {
  max-height:calc(3 * 53px + 42px); /* 3 rows (53px each) + header height (42px) */
  overflow-y:auto;
}

.table {
  table-layout:fixed;
}

thead {
  position:sticky;
  top:0;
  z-index:1;
  background-color:rgb(249, 250, 251); /* bg-gray-50 */
}
</style>

<script lang="ts" setup>
import { QLClient }    from "@/graphql/queries/ql/composables";
import { ref, toRef }  from "vue";
import Loader          from "@/components/layout/Loader.vue";
import dayjs           from "dayjs";
import TimestampToDate from "@/components/layout/TimestampToDate.vue";

interface Props {
  client: QLClient;
}

const props = withDefaults(defineProps<Props>(), {});
const client = toRef(props, "client");

const loading = ref(true);
const error = ref("");
const mutes = ref<any[]>([]);

const now = dayjs().unix();

</script>
