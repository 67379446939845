import Routes       from "@/router/Routes";
import OptOutReport from "@/views/Clients/OptOutReport.vue";
import UploadLeads  from "@/views/Clients/UploadLeads.vue";
import MutedClients from "@/views/Clients/MutedClients.vue";
import ClientsIndex from "@/views/Clients/ClientsIndex.vue";

export const ClientsRoutes = [
	{
		path:      "/clients/opt-out-report",
		component: OptOutReport,
		name:      Routes.clients.optOutReport,
		meta:{
			title: "Opt Out Report"
		}
	},
	{
		path:      "/clients/upload-leads",
		component: UploadLeads,
		name:      Routes.clients.uploadLeads,
		meta:{
			title: "Upload Leads"
		}
	},
	{
		path:      "/clients/muted-clients",
		component: MutedClients,
		name:      Routes.clients.mutedClients,
		meta:{
			title: "Muted Clients"
		}
	},
	{
		path:      "/clients",
		component: ClientsIndex,
		name:      Routes.clients.index,
		meta:{
			title: "Clients"
		}
	}
];

