<template>
  <div class="space-y-4">
    <div class="flex justify-between items-center">
      <h3 class="text-lg font-medium text-gray-900">
        Tags
      </h3>
      <div class="flex space-x-2">
        <button
            class="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            @click="fetchTags()">
          Refresh
        </button>
      </div>
    </div>

    <div v-if="loading" class="flex justify-center py-8">
      <loader class="w-16 text-gray-500 animate-pulse" />
    </div>

    <Empty v-else-if="tags.length === 0">
      <template #title>No tags found</template>
      <template #subtitle>Tags will appear here once they are created by adding a tag in the Client profile page.
      </template>
    </Empty>

    <div v-else class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
      <table class="min-w-full divide-y divide-gray-300">
        <thead class="bg-gray-50">
        <tr>
          <TableTh :is-down="sortColumn === 'name' && !sortAscending" :is-up="sortColumn === 'name' && sortAscending"
                   class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6" has-sort scope="col"
                   @down="changeOrder('name', false)" @up="changeOrder('name', true)">
            Name
          </TableTh>
          <TableTh :is-down="sortColumn === 'status' && !sortAscending"
                   :is-up="sortColumn === 'status' && sortAscending"
                   class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                   has-sort
                   scope="col"
                   @down="changeOrder('status', false)"
                   @up="changeOrder('status', true)">
            Status
          </TableTh>
          <th class="relative py-3.5 pl-3 pr-4 sm:pr-6" scope="col">
            <span class="sr-only">Actions</span>
          </th>
        </tr>
        </thead>
        <tbody class="divide-y divide-gray-200 bg-white">
        <tr v-for="tag in tags" :key="tag.id">
          <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
            {{ tag.name }}
          </td>
          <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
              <span :class="tag.archived ? 'bg-gray-100 text-gray-800' : 'bg-green-100 text-green-800'"
                    class="inline-flex rounded-full px-2 text-xs font-semibold leading-5">
                {{ tag.archived ? "Archived" : "Active" }}
              </span>
          </td>
          <td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
            <button v-if="!tag.archived" :disabled="isActionInProgress(tag.id)"
                    class="text-indigo-600 hover:text-indigo-900" @click="archiveTag(tag.id)">
              Archive
            </button>
            <button v-else :disabled="isActionInProgress(tag.id)" class="text-indigo-600 hover:text-indigo-900"
                    @click="unarchiveTag(tag.id)">
              Unarchive
            </button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, ref }        from "vue";
import Loader                    from "@/components/layout/Loader.vue";
import { gql }                   from "@apollo/client/core";
import { useMutation, useQuery } from "@vue/apollo-composable";
import TableTh                   from "@/components/layout/Tables/TableTh.vue"; // Import TableTh component
import Empty                     from "@/components/layout/Empty.vue";

interface Tag {
  id: string;
  name: string;
  kind: string;
  archived: boolean;
}

const tags = ref<Tag[]>([]);
const actionsInProgress = ref<Set<string>>(new Set());
const sortColumn = ref<"name" | "status" | null>(null); // Track sort column
const sortAscending = ref<boolean>(true); // Track sort direction

// GraphQL query to fetch all tags
const TAGS_QUERY = gql`
  query GetTags($includeArchived: Boolean!) {
    tags(includeArchived: $includeArchived) {
      id
      name
      kind
      archived
    }
  }
`;

// Archive mutation
const ARCHIVE_TAG_MUTATION = gql`
  mutation ArchiveTag($id: ID!) {
    archiveTag(id: $id) {
      id
      archived
    }
  }
`;

// Unarchive mutation
const UNARCHIVE_TAG_MUTATION = gql`
  mutation UnarchiveTag($id: ID!) {
    unarchiveTag(id: $id) {
      id
      archived
    }
  }
`;

const { mutate: archiveMutation } = useMutation(ARCHIVE_TAG_MUTATION);
const { mutate: unarchiveMutation } = useMutation(UNARCHIVE_TAG_MUTATION);
const { result, loading, refetch: refetchTags } = useQuery(TAGS_QUERY, { includeArchived: true });

const fetchTags = async () => {
  try {
    await refetchTags();

    if (result.value && result.value.tags) {
      const fetchedTags = result.value.tags.filter(tag => tag.kind === "CLIENT");
      sortTags(fetchedTags);
      tags.value = fetchedTags;
    }
  } catch (error) {
    console.error("Error fetching tags:", error);
  } finally {
  }
};

const sortTags = (tagList: Tag[]) => {
  if (sortColumn.value === "name") {
    tagList.sort((a, b) => {
      const nameA = a.name.toLowerCase();
      const nameB = b.name.toLowerCase();
      return sortAscending.value ? nameA.localeCompare(nameB) : nameB.localeCompare(nameA);
    });
  } else {
    if (sortColumn.value === "status") {
      tagList.sort((a, b) => {
        if (a.archived === b.archived) {
          return a.name.localeCompare(b.name); // Then sort by name
        }
        return a.archived ? (sortAscending.value ? 1 : -1) : (sortAscending.value ? -1 : 1); // Active first or last based on sortAscending
      });
    }
  }
};

const changeOrder = (column: "name" | "status", isAscending: boolean) => {
  sortColumn.value = column;
  sortAscending.value = isAscending;
  sortTags(tags.value);
};

const isActionInProgress = (tagId: string): boolean => {
  return actionsInProgress.value.has(tagId);
};

const archiveTag = async (id: string): Promise<void> => {
  actionsInProgress.value.add(id);

  try {
    await archiveMutation({ id });
    // Update local state using immutable pattern
    const updatedTags = tags.value.map(tag =>
                                           tag.id === id ? { ...tag, archived: true } : tag
    );
    sortTags(updatedTags);
    tags.value = updatedTags;
  } catch (error) {
    console.error("Error archiving tag:", error);
  } finally {
    actionsInProgress.value.delete(id);
  }
};

const unarchiveTag = async (id: string): Promise<void> => {
  actionsInProgress.value.add(id);

  try {
    await unarchiveMutation({ id });
    // Update local state using immutable pattern
    const updatedTags = tags.value.map(tag =>
                                           tag.id === id ? { ...tag, archived: false } : tag
    );
    sortTags(updatedTags);
    tags.value = updatedTags;
  } catch (error) {
    console.error("Error unarchiving tag:", error);
  } finally {
    actionsInProgress.value.delete(id);
  }
};

onMounted(() => {
  fetchTags();
});
</script>