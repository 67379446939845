<template>
  <div class="bg-white rounded-xl shadow-lg border border-gray-100 overflow-hidden">
    <div :class="[props.from_gradient]" class="p-8 bg-gradient-to-r  to-white">
      <div class="flex items-center justify-between align-middle">
        <div class="flex items-center space-x-3">
          <svg-icon :class="['text-' + props.main_color + '-500']" class="w-8 h-8 " :name="icon" />

          <div>
            <h3 class="text-xl font-semibold text-gray-900">{{ title }}</h3>
            <p class="text-sm text-gray-600">{{ subtitle }}</p>
          </div>
        </div>
        <div class="text-right flex flex-col items-end">
          <template v-if="attendingLoading">
            <div class="inline-flex">
              <div :class="['text-' + props.main_color + '-600']" class="text-4xl font-bold ">
                &nbsp;
              </div>
              <loader :class="['text-' + props.main_color + '-600']" class="h-10 w-10 " />
            </div>
          </template>
          <template v-else>
            <div :class="['text-' + props.main_color + '-600']" class="text-4xl font-bold ">
              {{ attendingCount }}
            </div>
          </template>
        </div>
      </div>
    </div>
      <members-by-segment v-if="singleEvent" :segment="segment" :single-event="singleEvent"
        @visible-count-changed="updateAttendingCount" @client-ids-visible="(ids) => emit('client-ids-visible', ids)"
        class="p-6" />
  </div>
</template>
<script lang="ts" setup>

import MembersBySegment from "@/views/Events/Components/Show/MembersBySegment.vue";
import { QLSegment, QLSingleClassFragmentFragment } from "@/graphql/queries/ql/composables";
import { ref } from "vue";
import Loader from "@/components/layout/Loader.vue";
import SvgIcon from "@/components/SvgIcon.vue";
import { PossibleIcons } from "@/assets/icons/iconNames";

const emit = defineEmits<{
  (e: "attendingStatus", payload: { count: number; loading: boolean }): void;
  (e: "client-ids-visible", clientIds: string[]): void;
}>();

const attendingCount = ref(0);
const attendingLoading = ref(true);

function updateAttendingCount(count: number) {
  attendingCount.value = count;
  attendingLoading.value = false;
  emit("attendingStatus", { count: attendingCount.value, loading: attendingLoading.value });
}

const props = defineProps<{
  from_gradient: string;
  main_color: string;
  title: string;
  subtitle: string;
  singleEvent: QLSingleClassFragmentFragment;
  segment: QLSegment;
  icon: PossibleIcons;
}>();

</script>
