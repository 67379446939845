<template>
  <confirm-modal
      v-model:open="openModel"
      :loading="sending"
      :title="isMuted ? 'Unmute Client?' : 'Mute Client'"
      :type="!isMuted ? 'warning' : 'regular'"
      close-text="Cancel"
      @clicked="handleAction">
    <template #text>
      <div v-if="isMuted">
        <p>Are you sure you want to unmute {{ client.first_name }}?</p>
        <p>{{ client.first_name }} will start receiving messages again.</p>
      </div>
      <div v-else>
        <p>Mute {{ client.first_name }} until:</p>
        <div class="mt-4">
          <date-time-picker
              v-model="mutedUntil"
              :class="{ 'border-red-500': dateError }"
              :min-date="minDate"
              class="w-full" />
          <p v-if="dateError" class="text-red-500 text-sm mt-1">{{ dateError }}</p>
        </div>
        <p class="mt-4 text-sm text-gray-600">
          {{ client.first_name }} will not receive any messages until this date.
        </p>
      </div>
    </template>
    <template #button>
      {{ isMuted ? "Unmute " : "Mute " }} {{ client.first_name }}
    </template>
  </confirm-modal>
</template>

<script lang="ts" setup>
import { QLClient, useMuteClientMutation, useUnmuteClientMutation } from "@/graphql/queries/ql/composables";
import { computed, ref, toRef, watch }                              from "vue";
import ConfirmModal                                                 from "@/components/layout/ConfirmModal.vue";
import DateTimePicker                                               from "@/components/layout/Forms/DateTimePicker.vue";
import $toast                                                       from "@/composables/toast";
import dayjs                                                        from "dayjs";

interface Props {
  client: QLClient;
  open: boolean;
  isMuted: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  isMuted: false
});
const emit = defineEmits([ "update:open", "muted", "unmuted" ]);

const client = toRef(props, "client");
const isMuted = toRef(props, "isMuted");

const openModel = computed({
                             get: () => props.open,
                             set: (val) => emit("update:open", val)
                           });

const sending = ref(false);
const dateError = ref("");
const mutedUntil = ref(dayjs().add(1, "day").unix());
const minDate = dayjs().add(1, "hour").unix();

// Validate date is in the future
watch(mutedUntil, (newDate) => {
  if (newDate && dayjs.unix(newDate).isBefore(dayjs())) {
    dateError.value = "Mute date must be in the future";
  } else {
    dateError.value = "";
  }
});

// Mute client mutation
const muteMutation = useMuteClientMutation({});
muteMutation.onDone(({ data }) => {
  sending.value = false;
  if (data?.muteClient?.success) {
    $toast({
             type:         "success",
             message:      `Successfully muted ${ client.value.first_name } until ${ dayjs(data.muteClient.mute?.muted_until * 1000)
                 .format("MMM D, YYYY") }`,
             linkRouteObj: null,
             linkText:     null
           });
    emit("muted");
    openModel.value = false;
  } else {
    $toast({
             type:         "error",
             message:      data?.muteClient?.message || "Failed to mute client",
             linkRouteObj: null,
             linkText:     null
           });
  }
});

muteMutation.onError((error) => {
  sending.value = false;
  $toast({
           type:         "error",
           message:      `Error muting client: ${ error.message }`,
           linkRouteObj: null,
           linkText:     null
         });
});

// Unmute client mutation
const unmuteMutation = useUnmuteClientMutation({});
unmuteMutation.onDone(({ data }) => {
  sending.value = false;
  if (data?.unmuteClient?.success) {
    $toast({
             type:         "success",
             message:      `Successfully unmuted ${ client.value.first_name }`,
             linkRouteObj: null,
             linkText:     null
           });
    emit("unmuted");
    openModel.value = false;
  } else {
    $toast({
             type:         "error",
             message:      data?.unmuteClient?.message || "Failed to unmute client",
             linkRouteObj: null,
             linkText:     null
           });
  }
});

unmuteMutation.onError((error) => {
  sending.value = false;
  $toast({
           type:         "error",
           message:      `Error unmuting client: ${ error.message }`,
           linkRouteObj: null,
           linkText:     null
         });
});

const handleAction = () => {
  sending.value = true;

  if (isMuted.value) {
    // Unmute client
    unmuteMutation.mutate({
                            client_id: client.value.id
                          });
  } else {
    // Validate date
    if (!mutedUntil.value || dayjs.unix(mutedUntil.value).isBefore(dayjs())) {
      dateError.value = "Mute date must be in the future";
      sending.value = false;
      return;
    }

    // Mute client
    muteMutation.mutate({
                          client_id:   client.value.id,
                          muted_until: mutedUntil.value
                        });
  }
};
</script>