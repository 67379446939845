import { ThisEventBookingStatus } from "hm-filters/filters/ThisEventBookingStatus";
import ThisEventBookingPackage    from "hm-filters/filters/ThisEventBookingPackage";

export const ThisEventBookingFilters = [
	new ThisEventBookingStatus("Status", { "field": "status" }).setDescription(
		"Booking Status is one of the most commonly used booking filters, and will exist in most of your booking rules"),
// 	TODO: description and text
new ThisEventBookingPackage("Package used", { "field": "package" }).setDescription(
		"Booking package used TODO: description")

];
