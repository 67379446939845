<template>
  <Disclosure
      v-slot="{ open }"
      as="header"
      class="bg-loopspark-500">
    <div class="w-full container mx-auto px-2 sm:px-4  lg:divide-y lg:divide-loopspark-600 md:px-2">
      <div class="relative h-16 flex justify-between">
        <div class="relative z-10 px-2 flex lg:px-0">
          <div class="hidden shrink-0 md:flex items-center">
            <change-team
                :current-team="currentTeam"
                :teams="teams" />
          </div>
          <div class="flex shrink-0 md:hidden items-center">
            <img
                :src="logoImg"
                alt="LoopSpark"
                class="block h-8 w-auto">
          </div>
        </div>

        <div class="relative z-10 flex items-center lg:hidden">
          <!-- Mobile menu button -->
          <DisclosureButton class="rounded-md p-2 inline-flex items-center justify-center text-loopspark-400 hover:bg-loopspark-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
            <span class="sr-only">Open menu</span>
            <svg-icon
                v-if="!open"
                aria-hidden="true"
                class="block h-6 w-6"
                name="menu" />
            <svg-icon
                v-else
                aria-hidden="true"
                class="block h-6 w-6"
                name="close" />
          </DisclosureButton>
        </div>
        <div class="hidden lg:relative lg:z-10 lg:ml-4 lg:flex lg:items-center">
          <button
              v-if="unreadNotifications > 0 || unreadAnnouncements > 0"
              class="shrink-0 rounded-full p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white flex items-center justify-center"
              type="button"
              @click="readNotifications">
            <span class="sr-only">View notifications</span>
            <svg-icon
                class="h-6 w-6 text-yellow-100 animate-ping absolute inline-flex opacity-50"
                name="bell" />
            <svg-icon
                class="h-6 w-6 text-yellow-200 relative inline-flex"
                name="bell" />
          </button>
          <!-- Profile dropdown LG-->
          <Popover
              v-if="me"
              as="div"
              class="shrink-0 relative ml-4">
            <PopoverButton class="flex items-center group">
              <div class="bg-gray-800 rounded-full flex text-sm text-white group-focus:outline-none group-focus:ring-2 group-focus:ring-offset-2 group-focus:ring-offset-gray-800 group-focus:ring-white">
                <span class="sr-only">Open user menu</span>
                <img
                    :alt="me.name"
                    :src="me.photo_url"
                    class="h-8 w-8 rounded-full">
              </div>
              <svg-icon
                  class="w-5 h-5 text-white"
                  name="down" />
            </PopoverButton>
            <transition
                enter-active-class="transition ease-out duration-100"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95">
              <PopoverPanel
                  v-slot="{ close }"
                  class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none overflow-hidden">
                <div class="block bg-gray-50 overflow-hidden py-3 px-4 text-sm text-gray-700 font-semibold border-b border-gray-200 mb-2">
                  {{ me.name }}
                </div>
                <div class="divide-y space-y-2">
                  <div class="">
                    <router-link
                        :to="{ name: routeList.user.settings }"
                        class="block py-2 px-4 text-sm text-gray-700 hover:bg-gray-100"
                        @click="close()">
                      My account
                    </router-link>
                    <button
                        class="w-full flex justify-between items-center py-2 px-4 text-sm text-gray-700 hover:bg-gray-100"
                        @click="readAnnouncements">
                      <span>Announcements</span>
                      <span
                          v-if="unreadAnnouncements > 0"
                          class="rounded-full bg-red-100 text-red-900 py-0.5 px-2 font-bold leading-none inline-block align-middle text-center border-red-900 border text-xs">{{
                          unreadAnnouncements
                                                                                                                                                                              }}</span>
                    </button>
                    <button
                        class="w-full flex justify-between items-center py-2 px-4 text-sm text-gray-700 hover:bg-gray-100"
                        @click="readNotifications">
                      <span>Notifications</span>
                      <span
                          v-if="unreadNotifications > 0"
                          class="rounded-full bg-red-100 text-red-900 py-0.5 px-2 font-bold leading-none inline-block align-middle text-center border-red-900 border text-xs">{{
                          unreadNotifications
                                                                                                                                                                              }}</span>
                    </button>
                    <button
                        class="w-full flex justify-between items-center py-2 px-4 text-sm text-gray-700 hover:bg-gray-100 group"
                        @click="soundSystem.toggleSound()">

                      <span class="group-hover:hidden">{{ muted ? "Sound: ON" : "Sound: OFF" }}</span>
                      <span class="hidden group-hover:block">{{ muted ? "Mute" : "Unmute" }}</span>

                    </button>
                  </div>
                  <div class="pt-2">
                    <div class="block py-2 pb-1 px-4 text-sm text-gray-500 font-semibold">
                      Support
                    </div>
                    <a
                        class="w-full flex items-center py-2 px-4 text-sm text-gray-700 hover:bg-gray-100"
                        href="mailto:support@LoopSpark.com">
                      <svg-icon
                          class="w-4 h-4"
                          name="email" />
                      <span class="pl-2">Email us</span>
                    </a>
                    <a
                        class="w-full flex items-center py-2 px-4 text-sm text-gray-700 hover:bg-gray-100"
                        href="https://docs.loopspark.com/"
                        target="_blank">
                      <svg-icon
                          class="w-4 h-4"
                          name="question-duotone" />
                      <span class="pl-2">Docs</span>
                    </a>
                  </div>
                  <div class="pt-2">
                    <router-link
                        :to="{ name: routeList.logout }"
                        class="w-full flex items-center py-2 px-4 text-sm text-gray-700 hover:bg-gray-100"
                        @click="close()">
                      <svg-icon
                          class="w-4 h-4"
                          name="signout-duotone" />
                      <span class="pl-2">Logout</span>
                    </router-link>
                  </div>
                </div>
              </PopoverPanel>
            </transition>
          </Popover>
        </div>
      </div>

      <PopoverGroup class="hidden lg:py-2 lg:flex lg:space-x-2 items-center">
        <div
            v-for="item in navigation"
            :key="item.name">
          <Popover

              v-if="item.children.length > 0"
              v-slot="{openSub}">
            <PopoverButton :class="[openSub || hasMatchedRoute(item) ? 'bg-loopspark-900 text-white' : 'text-loopspark-200 hover:bg-loopspark-700 hover:text-white', 'rounded-md py-2 px-3 inline-flex items-center space-x-2 text-sm font-medium']">
              <svg-icon
                  :name="item.icon"
                  class="w-5 h-5" />
              <span>{{ item.name }}</span>
              <svg-icon
                  :class="[openSub ? 'text-gray-600' : 'text-gray-400', 'ml-1 h-5 w-5 group-hover:text-gray-500']"
                  aria-hidden="true"
                  name="down" />
            </PopoverButton>
            <transition
                enter-active-class="transition ease-out duration-200"
                enter-from-class="opacity-0 translate-y-1"
                enter-to-class="opacity-100 translate-y-0"
                leave-active-class="transition ease-in duration-150"
                leave-from-class="opacity-100 translate-y-0"
                leave-to-class="opacity-0 translate-y-1">
              <PopoverPanel
                  v-slot="{ close }"
                  class="absolute z-10 -ml-4 mt-3 transform w-screen max-w-md">
                <div class="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                  <div class="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8 ">
                    <router-link
                        v-for="itemSub in item.children"
                        :key="itemSub.name"
                        :to="{name: itemSub.route}"
                        class="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
                        @click="close()">
                      <div :class="['shrink-0 flex items-center justify-center h-10 w-10 rounded-md sm:h-12 sm:w-12', itemSub.iconColor ? itemSub.iconColor : 'text-blue-600']">
                        <svg-icon
                            :name="itemSub.icon"
                            class="h-6 w-6" />
                      </div>
                      <div class="ml-4">
                        <p class="text-base font-medium text-gray-900">
                          {{ itemSub.name }}
                        </p>
                        <p class="mt-1 text-sm text-gray-500">
                          {{ itemSub.description }}
                        </p>
                      </div>
                    </router-link>
                  </div>
                  <div
                      v-if="item.docsLink"
                      class="">
                    <div class="relative grid gap-6 bg-gray-50 px-5 py-4 sm:gap-8 sm:px-8">
                      <a
                          :href="item.docsLink"
                          class="-m-3 p-3 py-1 flex items-center rounded-lg hover:bg-gray-50"
                          target="_blank">
                        <div
                            class="shrink-0 flex items-center justify-center h-10 w-10 rounded-md sm:h-12 sm:w-12 text-blue-600">
                          <svg-icon
                              class="h-6 w-6"
                              name="question-duotone" />
                        </div>
                        <div class="ml-4 text-blue-900">
                          <span>Docs</span>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </PopoverPanel>
            </transition>
          </Popover>
          <router-link
              v-if="item.children.length ===0 "
              v-slot="{href, navigate, isExactActive}"
              :to="{name: item.route}"
              custom>
            <a
                :aria-current="isExactActive ? 'page' : undefined"
                :class="[isExactActive ? 'bg-loopspark-900 text-white' : 'text-loopspark-200 hover:bg-loopspark-700 hover:text-white', 'rounded-md py-2 px-3 inline-flex items-center space-x-2 text-sm font-medium']"
                :href="href"
                @click="navigate">
              <svg-icon
                  :name="item.icon"
                  class="w-5 h-5" />

              <span>{{ item.name }}</span>
              <svg-icon
                  v-if="item.children.length > 0"
                  class="w-4 h-4"
                  name="down" />
              <span
                  v-if="item.badge > 0"
                  :class="['inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium', item.badgeColor === 'yellow' ? 'bg-lightyellow-300 text-yellow-900': 'bg-green-300 text-green-900']">{{
                  item.badge
                                                                                                                                                                                                         }}</span>
            </a>
          </router-link>
        </div>
        <div class="flex justify-end grow">
          <search-palette />
        </div>
      </PopoverGroup>
    </div>

    <!--    mobile menu-->
    <DisclosurePanel
        aria-label="Global"
        as="nav"
        class="lg:hidden">
      <div
          v-if="currentTeam"
          class="pt-2 pb-3 px-2 space-y-1 border-b border-loopspark-400 ">
        <div class="px-3">
          <span class="block text-white font-medium">{{ currentTeam.name }}</span>
          <div class="text-sm font-medium text-loopspark-300">
            {{ phone(currentTeam.phone_number) }}
          </div>
        </div>
        <div
            v-if="teams.length > 0"
            class="pt-4">
          <span class="text-base font-medium pb-1 block text-loopspark-300 px-3">Change workspace</span>
          <div class="space-y-2 px-2">
            <a
                v-for="team in teams"
                :key="team.id"
                :href="switchTeamUrl(team)"
                class="mobile-regular-link">
              <span>{{ team.name }}</span>
              <div>
                <svg-icon
                    class="h-4 w-4"
                    name="right" />
              </div>
            </a>
          </div>
        </div>
      </div>
      <div class="pt-2 pb-3 px-2 space-y-1 ">
        <div
            v-for="item in navigation"
            :key="item.name">
          <router-link
              v-if="item.children.length === 0"
              :to="{name:item.route }">
            <DisclosureButton
                :class="[(item as NavigationMain).current ? 'bg-gray-900 text-white' : 'text-loopspark-100 hover:bg-loopspark-700 hover:text-white', 'flex items-center justify-between text-left w-full rounded-md py-2 px-3 text-base font-medium']">
              <span>{{ item.name }}</span>
              <span
                  v-if="item.badge > 0"
                  class="inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-yellow-200 text-yellow-800">{{
                  item.badge
                                                                                                                              }}</span>
            </DisclosureButton>
          </router-link>
          <div
              v-else
              class="py-2 px-3">
            <span class="text-base font-medium pb-1 block text-loopspark-300">{{ item.name }}</span>

            <router-link
                v-for="itemSub in item.children"
                :key="itemSub.name"
                :to="{name:itemSub.route }">
              <DisclosureButton
                  class="mobile-regular-link">
                {{ itemSub.name }}
              </DisclosureButton>
            </router-link>

            <!--            <span class="text-white">{{ item.name }}</span>-->
          </div>
        </div>

        <div class="py-2 px-3">
          <span class="text-base font-medium py-2 block text-loopspark-300">Support</span>

          <DisclosureButton
              as="a"
              class="mobile-regular-link"
              href="mailto:support@LoopSpark.com">
            Email us
          </DisclosureButton>
          <DisclosureButton
              as="a"
              class="mobile-regular-link"
              href="https://docs.loopspark.com/"
              target="_blank">
            Docs
          </DisclosureButton>
        </div>
      </div>
      <div class="border-t border-gray-700 pt-4 pb-3">
        <div
            v-if="me"
            class="px-4 flex items-center">
          <div class="shrink-0">
            <img
                :alt="me.name"
                :src="me.photo_url"
                class="h-10 w-10 rounded-full">
          </div>
          <div class="ml-3">
            <div class="text-base font-medium text-white">
              {{ me.name }}
            </div>
            <div class="text-sm font-medium text-loopspark-300">
              {{ me.email }}
            </div>
          </div>
        </div>
        <div class="mt-3 px-2 space-y-1">
          <router-link :to="{ name: routeList.user.settings }">
            <DisclosureButton
                class="mobile-regular-link">
              My account
            </DisclosureButton>
          </router-link>
          <DisclosureButton
              class="mobile-regular-link"
              @click="readAnnouncements">
            <span>Announcements</span>
            <span
                v-if="unreadAnnouncements > 0"
                class="rounded-full bg-red-100 text-red-900 py-0.5 px-2 font-bold leading-none inline-block align-middle text-center border-red-900 border text-xs">{{
                unreadAnnouncements
                                                                                                                                                                    }}</span>
          </DisclosureButton>
          <DisclosureButton
              class="mobile-regular-link"
              @click="readNotifications">
            <span>Notifications</span>
            <span
                v-if="unreadNotifications > 0"
                class="rounded-full bg-red-100 text-red-900 py-0.5 px-2 font-bold leading-none inline-block align-middle text-center border-red-900 border text-xs">{{
                unreadNotifications
                                                                                                                                                                    }}</span>
          </DisclosureButton>
          <DisclosureButton
              class="mobile-regular-link"
              @click="soundSystem.toggleSound()">
            <span>{{ muted ? "Unmute sounds" : "Mute sounds" }}</span>
          </DisclosureButton>
          <router-link :to="{ name: routeList.logout }">
            <DisclosureButton
                class="mobile-regular-link">
              Logout
            </DisclosureButton>
          </router-link>
        </div>
      </div>
      <div class="border-t pt-4 pb-3 px-4">
        <client-search input-classes="bg-loopspark-700 border-loopspark-400 text-white focus:text-loopspark-900 border-gray-600 focus:border-gray-100 focus:text-loopspark-800 focus:bg-white" />
      </div>
    </DisclosurePanel>
  </Disclosure>
  <notifications-panel v-model:open="notificationsOpen" />
  <announcements-panel v-model:open="announcementsOpen" />
</template>
<style scoped>

.mobile-regular-link {
  @apply text-loopspark-100 hover:bg-loopspark-700 hover:text-white flex items-center justify-between text-left w-full rounded-md py-2 px-3 text-base font-medium;
}

.nav-item {
  @apply block py-1 pl-1 align-middle text-loopspark-200 no-underline border-b-2 border-transparent;
}

.nav-item.router-link-active {
  @apply text-loopspark-100 border-loopspark-100;
}

.nav-item:hover {
  @apply text-loopspark-100 border-loopspark-100;
}

@screen md {
  .nav-item {
    @apply py-3 text-center;
  }
}
</style>
<script lang="ts" setup>
import { Disclosure, DisclosureButton, DisclosurePanel, Popover, PopoverButton, PopoverGroup, PopoverPanel } from "@headlessui/vue";
import ChangeTeam                                                                                            from "@/components/navbar/ChangeTeam.vue";
import ClientSearch                                                                                          from "@/components/navbar/ClientSearch.vue";
import Routes                                                                                                from "@/router/Routes";
import meQuery                                                                                               from "@/composables/queries/meQuery";
import currentTeamQuery                                                                                      from "@/composables/queries/currentTeamQuery";
import { useAnnouncementsQuery, useQuickNotificationsQuery, useStatusQuery, useTeamsQuery }                  from "@/graphql/queries/ql/composables";
import { computed, ref }                                                                                     from "vue";
import SvgIcon                                                                                               from "@/components/SvgIcon.vue";
import { useRouter }                                                                                         from "vue-router";
import { PossibleIcons }                                                                                     from "@/assets/icons/iconNames";
import logoImg                                                                                               from "@/assets/images/logo.svg";
import { SoundSystem }                                                                                       from "@/classes/notifications/SoundSystem";
import phone                                                                                                 from "@/classes/vue-filters/PhoneFilter";
import { switchTeamUrl }                                                                                     from "@/composables/switchTeamUrl";
import NotificationsPanel                                                                                    from "@/components/notifications/NotificationsPanel.vue";
import AnnouncementsPanel                                                                                    from "@/components/notifications/AnnouncementsPanel.vue";
import { echoListener }                                                                                      from "@/composables/echoListener";
import { AllEventTypes }                                                                                     from "@/classes/notifications/EventTypes";
import { addonEnabled }                                                                                      from "@/classes/billing/addons";
import SearchPalette                                                                                         from "@/components/navbar/SearchPalette.vue";

const routeList = Routes;
const $router = useRouter();

const { me } = meQuery();
const { currentTeam } = currentTeamQuery();
const teamQuery = useTeamsQuery();
const teams = computed(() => teamQuery.result.value?.teams || []);

// const teams = useResult(useTeamsQuery().result, [], data => data.teams);

const { result: statusResult, refetch: refetchStatusQuery } = useStatusQuery();
const notificationsQuery = useQuickNotificationsQuery();
const notifications = computed(() => notificationsQuery.result.value?.notifications || []);

const announcementsQuery = useAnnouncementsQuery();
const announcements = computed(() => announcementsQuery.result.value?.announcements || []);

const soundSystem = SoundSystem();
const muted = soundSystem.muted;

const unreadNotifications = computed(() => {
  return notifications.value.filter((notification) => !notification.read).length;
});
const unreadAnnouncements = computed(() => {
  if (me.value === null || me.value.last_read_announcements_at === null) {
    return announcements.value.length;
  }
  return announcements.value.filter((announcement) => announcement.created_at > me.value.last_read_announcements_at).length;
});
const readAnnouncements = function () {
  announcementsOpen.value = true;
};
const readNotifications = function () {
  notificationsOpen.value = true;
};

const notificationsOpen = ref(false);
const announcementsOpen = ref(false);

const status = computed(() => statusResult.value?.status ?? {
  discussions:   {
    open:     0,
    open_sms: 0
  },
  total_clients: 0,
  todos:         {
    open:      0,
    overdue:   0,
    postponed: 0
  }
});

const user = computed(() => {
  return me;
});

const canSeeBilling = computed(() => {
  return currentTeam.value && me.value && !currentTeam.value.is_free && me.value.policy.is_main_owner;
});

echoListener([
               AllEventTypes.UpdateStatus,
               AllEventTypes.TodoNew,
               AllEventTypes.TodoUpdated,
               AllEventTypes.DiscussionNew,
               AllEventTypes.DiscussionUpdated
             ], () => {
  refetchStatusQuery();
}, 5000);
echoListener([ AllEventTypes.UserNotification ], () => {
  notificationsQuery.refetch();
}, 5000);

interface NavigationMain {
  name: string;
  route: string;
  badge: number;
  icon: PossibleIcons;
  children: NavigationSub[];
  badgeColor?: "yellow" | "green";
  docsLink?: string;
  skippable?: boolean;
  current?: boolean;
}

interface NavigationSub {
  name: string;
  route: string;
  icon: PossibleIcons;
  description: string;
  iconColor?: string;
}

const hasMatchedRoute = (route: NavigationMain) => {
  const matchedRoutes = ($router.currentRoute.value.matched);

  for (const matchedRoutesKey in matchedRoutes) {
    for (const childrenKey in route.children) {
      if (matchedRoutes[matchedRoutesKey].name === route.children[childrenKey].route && route.skippable !== true) {
        return true;
      }
    }
  }

  return false;
};

const navigation = computed<NavigationMain[]>(() => {
  const nav: NavigationMain[] = [
    { name: "Home", route: routeList.dashboard, badge: 0, children: [], icon: "gauge-duotone", current: false }
  ];

  const clients: NavigationSub[] = [
    {
      name:        "Clients",
      route:       routeList.clients.index,
      icon:        "users-duotone",
      description: "View your clients, segment them into targeted audiences, and manage existing audience segments"
    },
    {
      name:        "Opt Out Report",
      route:       routeList.clients.optOutReport,
      icon:        "user-minus-duotone",
      iconColor:   "text-red-600",
      description: "View and export list of clients that have unsubscribed from email and sms communications."
    },
    {
      name:        "Upload Leads",
      route:       routeList.clients.uploadLeads,
      icon:        "user-plus-duotone",
      description: "Upload a list of leads that are not yet in your booking software."
    },
    {
      name:        "Muted Clients",
      route:       routeList.clients.mutedClients,
      icon:        "bell",
      description: "View and manage clients who are temporarily muted from receiving messages."
    }
  ];

  nav.push({
             name:     "Clients",
             route:    routeList.clients.index,
             badge:    0,
             children: clients,
             icon:     "users-duotone",
             current:  false
           });
  nav.push({
             name: "Events", route: routeList.event.calendar, badge: 0, icon: "calendar-blank-duotone", children: [
      {
        name:        "Calendar",
        route:       routeList.event.calendar,
        icon:        "calendar-duotone",
        description: "Calendar view of events at your location. Send targeted messages to attendees of specific events."
      },
      {
        name:        "Virtual Event Manager",
        route:       routeList.event.virtual_manager,
        icon:        "monitor-play-duotone",
        description: "Create and manage Virtual Event Templates for sending livestream event information."
      }

    ], current:    false
           });

  nav.push({
             name:       "Discussions",
             route:      routeList.discussions,
             badge:      status.value.discussions.open,
             icon:       "chats-circle-duotone",
             badgeColor: "green",
             children:   [], current: false
           });
  nav.push({
             name:       "ToDos",
             route:      routeList.todos,
             badge:      status.value.todos.open,
             icon:       "todo",
             badgeColor: "yellow",
             children:   [], current: false
           });

  const automations: NavigationSub[] = [
    {
      name:        "List",
      route:       routeList.automations.index,
      icon:        "list",
      description: "View, manage and edit automations in your account."
    }
  ];
  if (me.value && me.value.policy.create_automations) {
    automations.push({
                       name:        "Create",
                       route:       routeList.automations.create,
                       icon:        "list-plus",
                       iconColor:   "text-green-600",
                       description: "Create a new continuous or one-time-only automation."
                     });
  }

  if (me.value && me.value.policy.view_email_templates) {
    automations.push({
                       name:        "Email Templates",
                       route:       routeList.automations.email_templates.index,
                       icon:        "table-duotone",
                       description: "Create and manage library of custom email templates."
                     });
  }
  if (me.value && me.value.policy.create_automations) {
    automations.push({
                       name:        "Catalog",
                       route:       routeList.automations.catalog.index,
                       icon:        "stack-duotone",
                       description: "Create the most commonly used automations by choosing templates professionally prepared by LoopSpark"
                     });
  }
  nav.push({
             name:     "Automations",
             route:    routeList.automations.index,
             badge:    0,
             icon:     "user-circle-gear-duotone",
             docsLink: "https://docs.loopspark.com/automations/automation_setup/",
             children: automations, current: false
           });

  if (addonEnabled(currentTeam.value, "forms")) {
    const forms: NavigationSub[] = [
      {
        name:        "List",
        route:       routeList.forms.index,
        icon:        "list",
        description: "View, manage and edit forms and waivers in your account."
      }
    ];
    if (me.value && me.value.policy.create_forms) {
      forms.push({
                   name:        "Create",
                   route:       routeList.forms.create,
                   icon:        "list-plus",
                   iconColor:   "text-green-600",
                   description: "Create a new form or waiver."
                 });
      forms.push({
                   name:        "Catalog",
                   route:       routeList.forms.catalog.index,
                   icon:        "stack-duotone",
                   description: "Create the most commonly used forms & waivers by choosing templates professionally prepared by LoopSpark"
                 });
    }
    nav.push({
               name:     "Forms & Waivers",
               route:    routeList.forms.index,
               badge:    0,
               icon:     "signature-duotone",
               children: forms, current: false
             } as NavigationMain);

  } else {
    if (currentTeam.value.is_free === false) {
      nav.push({
                 name:       "Forms & Waivers",
                 route:      routeList.forms.index,
                 badge:      0,
                 icon:       "signature-duotone",
                 skippable:  true,
                 children:   [
                   {
                     name:        "Addon",
                     route:       canSeeBilling.value === true
                                  ? routeList.studio.settings.billing
                                  : routeList.dashboard,
                     icon:        "billing-duotone",
                     description: canSeeBilling.value === true
                                  ?
                                  "Customizable liability waivers, client forms, surveys and questionnaires! Organized in client profiles with rich form submission analytics, streamline customer intake and learn more about your client base. Add it to your account now!"
                                  :
                                  "Customizable liability waivers, client forms, surveys and questionnaires! Ask " + currentTeam.value.owner.name + " to add Forms & Waivers."
                   }
                 ], current: false
               } as NavigationMain);
    }
  }

  if (me.value && (me.value.policy.is_owner || me.value.policy.is_manager || me.value.policy.is_admin)) {
    const settings: NavigationSub[] = [];
    if (me.value.policy.is_owner || me.value.policy.is_admin) {
      settings.push({
                      name:        "Settings",
                      route:       routeList.studio.settings.profile,
                      icon:        "gear-duotone",
                      description: "Manage studio information and outbound message settings."
                    });
    }

    if (me.value.policy.staff_invite || me.value.policy.staff_change || me.value.policy.staff_remove) {
      settings.push({
                      name:        "Staff",
                      route:       routeList.studio.settings.staff,
                      icon:        "staff-duotone",
                      description: "Invite and manage staff accounts and staff permissions."
                    });
    }

    settings.push({
                    name:        "Manage Segments",
                    route:       routeList.studio.settings.segments,
                    icon:        "segment-duotone",
                    description: "Organize, archive and re-order saved segments."
                  });

    settings.push({
                    name:        "Manage Note Templates",
                    route:       routeList.studio.settings.noteTemplates,
                    icon:        "notes",
                    description: "Create and manage Note Templates"
                  });

    settings.push({
                    name:        "Club Count Manager",
                    route:       routeList.studio.settings.clubCountManager,
                    icon:        "club-count",
                    description: "Control event types that contribute to club count celebrations, and annual reset dates for period count."
                  });
    settings.push({
                    name:        "Manage Groups",
                    route:       routeList.studio.settings.groups,
                    icon:        "groups-duotone",
                    description: "Manage staff role groups for To-Do assignment."
                  });
    if (me.value.policy.is_owner || me.value.policy.is_admin) {
      settings.push({
                      name:        "Quick Responses",
                      route:       routeList.studio.settings.quickResponses,
                      icon:        "quick-responses-duotone",
                      description: "Create and manage Quick Responses to SMS discussions for your team to access."
                    });
    }
    if (me.value.policy.is_owner || me.value.policy.is_admin) {
      settings.push({
                      name:        "Tag Manager",
                      route:       routeList.studio.settings.tagManager,
                      icon:        "tag",
                      description: "Manage tags for clients"
                    });
    }

    if (canSeeBilling.value === true) {
      settings.push({
                      name:        "Billing",
                      route:       routeList.studio.settings.billing,
                      icon:        "billing-duotone",
                      description: "Manage subscription, update payment method, and view invoices."
                    });

    }
    if (me.value && (me.value.policy.is_admin || me.value.policy.is_owner)) {

      settings.push({
                      name:        "Studio Usage",
                      route:       routeList.studio.settings.usage,
                      icon:        "chart-bar",
                      description: "View your studio's SMS usage and available SMS units remaining."
                    });

      if (addonEnabled(currentTeam.value, "zapier")) {

        settings.push({
                        name:        "Zapier",
                        route:       routeList.studio.settings.zapier,
                        icon:        "zapier",
                        description: "Manage your Zapier integration."
                      });
      } else {
        if (currentTeam.value.is_free === false) {
          settings.push({
                          name:  "Zapier",
                          route: routeList.studio.settings.billing,
                          icon:  "zapier",

                          description: canSeeBilling.value === true
                                       ?
                                       "Integrate Zapier with your LoopSpark account, and unlock even more automation options. Add it to your account now!"
                                       :
                                       "Integrate Zapier with your LoopSpark account, and unlock even more automation options! Ask " + currentTeam.value.owner.name + " to add Forms & Waivers."
                        });
        }
      }
    }

    nav.push({
               name:     "Settings",
               route:    routeList.studio.settings.profile,
               badge:    0,
               icon:     "gear-duotone",
               children: settings, current: false
             });

  }

  return nav;
});

</script>
