import {RouteRecordRaw} from "vue-router";
import Routes           from "@/router/Routes";
import BillingInsight   from "@/views/Insights/BillingInsight.vue";

export const InsightsRoutes: Array<RouteRecordRaw> = [
	{
		path:      "/insights",
		component: BillingInsight,
		name:      Routes.insights.index,
		meta:      {
			title: "Billing"
		}
	}
];
