<template>
  <div
      @mouseenter="showTip"
      @mouseleave="hideTip">
    <div ref="popcorn" class="block w-full h-full">
      <slot name="visible" />
    </div>
    <div
        ref="tooltip"
        class="hidden bg-white rounded border border-gray-300 z-50"
        data-popper-tooltip
        role="tooltip">
      <slot name="tooltip" />
      <div
          ref="arrow"
          data-popper-arrow />
    </div>
  </div>
</template>
<script lang="ts" setup>
import {onMounted, ref, useAttrs, useSlots} from "vue";
import {createPopper}                       from "@popperjs/core";

const slots = useSlots();
const attrs = useAttrs();

const emit = defineEmits([]);

const popcorn = ref(null);
const tooltip = ref(null);
const arrow = ref(null);

let popperInstance = null;

onMounted(() => {
  popperInstance = createPopper(popcorn.value, tooltip.value, {
    modifiers: [
      {
        name:    "arrow",
        options: {
          element: arrow.value
        }
      },
      {
        name:    "offset",
        options: {
          offset: [0, 8]
        }
      }
    ]
  });
});

const showTip = () => {
  tooltip.value.setAttribute("data-show", "");
  // // Enable the event listeners
  popperInstance.setOptions((options) => ({
    ...options,
    modifiers: [
      ...options.modifiers,
      {name: "eventListeners", enabled: true}
    ]
  }));
  popperInstance.update();
};

const hideTip = () => {
  tooltip.value.removeAttribute("data-show");
  // Disable the event listeners
  popperInstance.setOptions((options) => ({
    ...options,
    modifiers: [
      ...options.modifiers,
      {name: "eventListeners", enabled: false}
    ]
  }));
};

</script>
