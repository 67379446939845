<template>
  <Menu
      as="div"
      class="inline-block relative text-sm w-full grow" v-if="noteTemplates.length > 0">
    <div class="flex items-end justify-end pr-2">
      <MenuButton class="inline-flex items-center justify-center focus:outline-none text-blue-500 text-sm font-medium px-2 py-1 rounded bg-opacity-50 bg-white hover:bg-green-200 hover:text-green-900">
        <span class="block">Note templates</span>
        <svg-icon
            name="down"
            class="h-4 w-4 block" />
      </MenuButton>
    </div>
    <div class="relative">
    <transition
        enter-active-class="transition ease-out duration-100"
        enter-from-class="transform opacity-0 scale-95"
        enter-to-class="transform opacity-100 scale-100"
        leave-active-class="transition ease-in duration-75"
        leave-from-class="transform opacity-100 scale-100"
        leave-to-class="transform opacity-0 scale-95">
      <MenuItems class="origin-top-right absolute right-0 mt-2 max-w-5xl max-h-[20rem] rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none overflow-y-auto">
        <div class="py-1 divide-y divide-gray-100 ">
          <MenuItem v-slot="{ active }" v-for="template in noteTemplates" :key="template.id">
            <div :class="['py-2 text-sm block cursor-pointer block text-gray-900', active ? 'bg-loopspark-50': '']"
                 @click="selectedTemplate(template)">
              <div class="px-4 py-2">
                <span class="font-medium text-base">{{ template.title }}</span>
                <div :class="['text-xs pt-1', active ? 'text-gray-900': 'text-gray-600']">
                  <div class="font-sans whitespace-pre-line line-clamp-2 max-h-[3em] overflow-hidden" v-html="truncateToTwoLines(replacePlaceholders(template.body))"/>
                </div>
              </div>
            </div>
          </MenuItem>
        </div>
      </MenuItems>
    </transition>
    </div>
  </Menu>

</template>
<script lang="ts" setup>
import SvgIcon                                                               from "@/components/SvgIcon.vue";
import { Menu, MenuButton, MenuItem, MenuItems }                             from "@headlessui/vue";
import { computed, toRef, useAttrs, useSlots }                               from "vue";
import { QLClient, QLFragmentNoteTemplateFragment, useNoteTemplatesQuery } from "@/graphql/queries/ql/composables";

interface Props {
  client: QLClient;
}

const props = withDefaults(defineProps<Props>(), {});

const slots = useSlots();
const attrs = useAttrs();

const emit = defineEmits(["selected"]);

const client = toRef(props, "client");

const templatesQuery = useNoteTemplatesQuery({
  fetchPolicy: "cache-first"
});

const noteTemplates = computed(() => {
  const templates = templatesQuery.result.value?.noteTemplates?.data ?? [];
  return templates;
});

const replacePlaceholders = (body: string): string => {
  const toReplace = [
    {
      holder: "{{FIRST_NAME}}",
      val:    client.value.first_name
    },
    {
      holder: "{{LAST_NAME}}",
      val:    client.value.last_name
    },
    {
      holder: "{{PERIOD_COUNT}}",
      val:    client.value.total_period_counts
    },
    {
      holder: "{{CLUB_COUNT}}",
      val:    client.value.total_club_counts
    }
  ];
  toReplace.forEach((rep) => {
    const regex = new RegExp(rep.holder, 'g'); // Create a regex with the global flag
    body = body.replace(regex, rep.val ? rep.val.toString() : null);
  });
  return body;
};

const truncateToTwoLines = (text: string): string => {
  // Handle different types of line breaks and split the text
  const lines = text.split(/\r?\n/);
  
  // Maximum characters per line
  const maxCharsPerLine = 80;
  
  // If there are 2 or fewer lines, check if any line is too long
  if (lines.length <= 2) {
    const truncatedLines = lines.map(line => {
      if (line.length > maxCharsPerLine) {
        return line.substring(0, maxCharsPerLine) + '...';
      }
      return line;
    });
    return truncatedLines.join('\n');
  }
  
  // Otherwise, take only the first two lines, truncate if needed, and add a visible ellipsis
  const truncatedLines = lines.slice(0, 2).map((line, index) => {
    if (line.length > maxCharsPerLine) {
      // For the last line we're keeping, add ellipsis if truncated
      if (index === 1) {
        return line.substring(0, maxCharsPerLine) + '...';
      }
      return line.substring(0, maxCharsPerLine);
    }
    return line;
  });
  
  // Add ellipsis indicator if we truncated lines
  return truncatedLines.join('\n') + (lines.length > 2 ? ' [...]' : '');
};

const selectedTemplate = (template: QLFragmentNoteTemplateFragment) => {
  emit("selected", replacePlaceholders(template.body));
};
</script>