<template>
  <div>
    <div class="mb-8">
      <div class="pb-4 md:pb-0 max-w-5xl px-2 md:px-0">
        <div class="text-lg font-medium text-gray-900">
          To-Do
        </div>
        <div class="mt-1 text-gray-600 text-sm">
          <p>
            Streamline tasks with automated To-Do lists. To-Dos help schedule, assign, and track tasks related to
            clients, including celebrating club counts, personal follow up calls, hand written communications and
            more.
          </p>
        </div>
      </div>
    </div>

    <div class="split-view-left">
      <div class="pb-4 md:pb-0 md:w-1/3">
        <div class="text-lg font-medium text-gray-900">
          To-Do information
        </div>
        <div class="mt-1 text-gray-600 text-sm">
          <p>
            The Title and Content of each To-Do created by this automation will help your staff understand what's
            needed to successfully complete this ToDo.
          </p>

          <div class="text-base pt-4">
            Title Tips
          </div>
          <p>
            Add personalized details to help your staff better manage and prioritize their task list.
          </p>

          <div class="text-base pt-4">
            Content Tips
          </div>

          <ul class="list-disc list-inside space-y-2 mt-2">
            <li>Purpose of the To-Do</li>
            <li>Step by step instructions of what your staff needs to accomplish</li>
            <li>
              Prompt your staff to check client profile information before taking action to ensure To-Do is still
              relevant.
            </li>
            <li>Phone Scripts (for To-Dos related to phone calls)</li>
            <li>Voicemail Scripts (for To-Dos related to phone calls)</li>
            <li>Pre-populated follow up SMS and/or Emails (incase staff receives voicemail)</li>
          </ul>
        </div>
      </div>

      <div class="block shadow-md rounded-lg flex-1 bg-white">
        <div class="p-4 space-y-6">
          <text-input
              v-model:modelValue="subjectModel"
              :error-message="formErrors.get('name')"
              :placeholder="'Send birthday card to {{FIRST_NAME}} {{LAST_NAME}}'"
              data-name
              label="Title"
              required
          >
            <div class="leading-tight form-small-info text-gray-600">
              Each To-Do created by this automation will have the above title. Adding personalized details in a To-Do
              title will help your staff better manage and prioritize their task list.
            </div>
          </text-input>

          <div>
            <label
                class="form-label mb-2"
                for=""
            >Content <span
                class="text-xs opacity-50"
                :class="{'is-required': !contentModel||contentModel.length === 0}"
            >(required)</span></label>
            <div class="">
              <rich-editor
                  ref="editor"
                  v-model:modelValue="contentModel"
                  class="h-56"
                  placeholder="To-Do content"
              />
            </div>
          </div>
          <div class="clear-both">
            <div class="text-sm font-medium text-gray-700 mb-2">
              You can use the following merge tags:
            </div>
            <placeholders-selector @selected="addPlaceholder" />
          </div>
        </div>
      </div>
    </div>

    <div class="split-view-left">
      <div class="pb-4 md:pb-0 md:w-1/3">
        <div class="text-lg font-medium text-gray-900">
          To-Do priority
        </div>
        <div class="mt-1 text-gray-600 text-sm">
          Prioritize To-Dos to help staff focus on time sensitive or urgent tasks before moving on to less urgent or
          lower priority tasks.
        </div>
      </div>

      <div class="block shadow-md rounded-lg flex-1 bg-white">
        <div class="p-4 grid grid-cols-2 lg:grid-cols-4 gap-4">
          <div
              class="radio-box group"
              role="button"
              :class="{'selected': priorityModel === priorities.NONE || priorityModel === null}"
              @click="priorityModel = priorities.NONE"
          >
            <svg-icon
                name="minus"
                class="w-8 h-8 text-gray-300 "
            />
            <div class="text-center">
              <div class="mt-2 pb-1 font-medium">
                No priority
              </div>
              <p class="text-xs">
                To-Do doesn't require a priority and can be accomplished whenever.
              </p>
            </div>
          </div>
          <div
              class="radio-box group"
              role="button"
              :class="{'selected': priorityModel === priorities.LOW}"
              @click="priorityModel = priorities.LOW"
          >
            <svg-icon
                name="thermometerLow"
                class="w-8 h-8 text-green-500 icon"
            />
            <div class="text-center">
              <div class="mt-2 pb-1 font-medium">
                Low
              </div>
              <p class="text-xs">
                To-Do is not time sensitive and can be completed without any urgency.
              </p>
            </div>
          </div>
          <div
              class="radio-box group"
              role="button"
              :class="{'selected': priorityModel === priorities.MEDIUM}"
              @click="priorityModel = priorities.MEDIUM"
          >
            <svg-icon
                name="thermometerMed"
                class="w-8 h-8 icon text-yellow-500"
            />
            <div class="text-center">
              <div class="mt-2 pb-1 font-medium">
                Medium
              </div>
              <p class="text-xs">
                To-Do is relatively important and should be completed in a timely manner.
              </p>
            </div>
          </div>
          <div
              class="radio-box group"
              role="button"
              :class="{'selected': priorityModel === priorities.URGENT}"
              @click="priorityModel = priorities.URGENT"
          >
            <svg-icon
                name="thermometerHigh"
                class="w-8 h-8 icon text-red-500"
            />
            <div class="text-center">
              <div class="mt-2 pb-1 font-medium">
                Urgent
              </div>
              <p class="text-xs">
                To-Do needs immediate attention and should be completed ASAP.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="split-view-left">
      <div class="pb-4 md:pb-0 md:w-1/3">
        <div class="text-lg font-medium text-gray-900">
          Overdue settings
        </div>
        <div class="mt-1 text-gray-600 text-sm">
          <p>
            To-Dos can be set as <span class="italic">"overdue"</span> if they have not been closed after a defined
            period following creation.
            <br>
            Overdue tasks are flagged as “overdue” and counted, helping staff work on outstanding tasks before moving on
            to new tasks. Adding overdue settings will help ensure To-Dos are completed in a timely manner.
          </p>
        </div>
      </div>

      <div class="block shadow-md rounded-lg flex-1 bg-white">
        <div class="p-4">
          <div
              class="border-2 rounded-lg py-4 mb-4 px-4 hover:border-blue-200 hover:bg-blue-100 hover:text-blue-800"
              role="button"
              :class="{'selected-box': !overdueDays}"
              @click="overdueDays = 0"
          >
            <span class="text-base font-medium block mb-2">No overdue</span>
            <p class="text-xs">
              To-Do will remain open until completed, but will never be marked as overdue.
            </p>
          </div>
          <div
              class="border-2 rounded-lg py-4 px-4 hover:border-blue-200 hover:bg-blue-100 hover:text-blue-800"
              role="button"
              :class="{'selected-box': overdueDays > 0}"
              @click="overdueDays < 1 ?overdueDays = 1 : overdueDays = overdueDays"
          >
            <span class="text-base font-medium block mb-2">Overdue after</span>
            <p class="text-xs">
              To-Do is time sensitive and should be marked as "overdue" if staff has not closed the task in a specific
              timeframe.
            </p>

            <div
                v-if="overdueDays > 0"
                class="lg:flex mt-4 items-center lg:space-x-2"
            >
              <div class="inline-flex">
                <input
                    v-model="overdueDays"
                    class="form-input rounded-r-none"
                    min="1"
                    step="1"
                    type="number"
                >
                <div class="form-input form-input-default shrink w-auto rounded-l-none border-l-0 bg-gray-50 text-gray-600">
                  days
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="split-view-left">
      <div class="pb-4 md:pb-0 md:w-1/3">
        <div class="text-lg font-medium text-gray-900">
          Schedule
        </div>
        <div class="mt-1 text-gray-600 text-sm">
          <p>
            Create the To-Do immediately after client enters audience segment, or schedule the To-Do creation a few days
            after client enters audience segment.
          </p>
        </div>
      </div>

      <div class="block shadow-md rounded-lg flex-1 bg-white">
        <div class="p-4">
          <div
              class="border-2 rounded-lg py-4 mb-4 px-4 hover:border-blue-200 hover:bg-blue-100 hover:text-blue-800"
              role="button"
              :class="{'selected-box': !postponedDays}"
              @click="postponedDays = 0"
          >
            <span class="text-base font-medium block mb-2">Right away</span>
            <p class="text-xs">
              <span class="italic">Most commonly used</span>: To-Do will populate immediately when client meets all
                                                            criteria in the automation's audience segment.
            </p>
          </div>
          <div
              class="border-2 rounded-lg py-4 px-4 hover:border-blue-200 hover:bg-blue-100 hover:text-blue-800"
              role="button"
              :class="{'selected-box': postponedDays > 0}"
              @click="postponedDays < 1 ?postponedDays = 1 : postponedDays = postponedDays"
          >
            <span class="text-base font-medium block mb-2">Postpone for</span>
            <p class="text-xs">
              <span class="italic">Rarely used</span>: Schedule the To-Do creation for {{
                postponedDays > 0
                ? postponedDays
                : "X"
                                                     }} days after the automation creates it.
            </p>

            <div
                v-if="postponedDays > 0"
                class="lg:flex mt-4 items-center lg:space-x-2"
            >
              <div class="inline-flex">
                <input
                    v-model="postponedDays"
                    class="form-input rounded-r-none"
                    min="1"
                    step="1"
                    type="number"
                >
                <div class="form-input form-input-default shrink w-auto rounded-l-none border-l-0 bg-gray-50 text-gray-600">
                  days
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="md:flex mt-10 mb-10 md:space-x-4">
      <div class="pb-4 md:pb-0 md:w-1/3">
        <div class="text-lg font-medium text-gray-900">
          Assign
        </div>
        <div class="mt-1 text-gray-600 text-sm">
          <p>To help your staff focus on their assigned To-Dos, automations can assign To-Dos in a number of ways:</p>

          <div class="pt-2 space-y-4">
            <p>
              <span class="font-semibold">Assign to person</span>: Great option if you have a designated manager that
                                                                 needs to handle all sensitive
                                                                 To-Dos,
                                                                 such as membership renewals. To-Dos assigned to a
                                                                 specific person will appear on that staff member’s
                                                                 dashboard.
            </p>
            <p>
              <span class="font-semibold">Assign to Group</span>: Great option if you have multiple staff members with
                                                                a similar functional role at your
                                                                studio, such as assigning all welcome calls to any
                                                                front desk employee. To-Dos assigned by group will
                                                                appear on the dashboard of all staff members in that
                                                                role group.
            </p>
            <p>
              <span class="font-semibold">Assign Tags</span>: In addition to To-Do assignment, tags can be added to
                                                            each To-Do to help staff organize
                                                            their
                                                            To-Do List. Staff can then filter the To-Do list by tag,
                                                            helping them work on specific items, for
                                                            example: <br>
                                                            if a To-Do is assigned to the role group “front desk” and
                                                            the tag “welcome” is assigned, your front desk
                                                            can filter their To-Do list by the tag “welcome” to focus
                                                            on these specific ToDos.
            </p>
          </div>
        </div>
      </div>

      <div class="block flex-1 ">
        <div class="p-4 space-y-6 bg-white shadow-md rounded-lg">
          <div class="flex mb-4 items-start">
            <label
                class="form-label mr-4 w-1/5 pt-2"
                for="assigned"
            >
              Assigned to person
            </label>
            <div class="w-full">
              <select
                  id="assigned"
                  v-model="assignedToIdModel"
                  class="form-select form-input-default block w-full"
                  name="assigned"
                  :class="{'has-error': formErrors.has('automation.todo_assigned_to_id')}"
              >
                <option :value="null">
                  Everyone
                </option>
                <option
                    v-for="user in users"
                    :key="user.id"
                    :value="user.id"
                >
                  {{ user.name }}
                </option>
              </select>
              <p class="text-xs pt-1 text-gray-600">
                Assign each To-Do created by this automation to a specific team
                member.
              </p>
            </div>
          </div>
          <div
              v-if="groups"
              class="flex mb-4 items-start"
          >
            <label
                class="form-label mr-4 w-1/5 pt-2"
                for="assigned"
            >
              Assigned to group
            </label>
            <div class="w-full">
              <select
                  id="group"
                  v-model="assignedTeamGroupIdModel"
                  class="form-select form-input-default block w-full"
                  name="group"
                  :class="{'has-error': formErrors.has('automation.todo_team_group_id')}"
              >
                <option :value="null">
                  Everyone
                </option>
                <option
                    v-for="teamGroup in groups"
                    :key="teamGroup.id"
                    :value="teamGroup.id"
                >
                  {{ teamGroup.name }}
                </option>
              </select>
              <p class="text-xs pt-1 text-gray-600">
                Assign each To-Do created by this automation to a type of staff
                member (example:
                Manager, Front Desk, Instructor). To use this feature you need to
                <router-link
                    :to="{name: routeList.studio.settings.groups}"
                    class="text-blue-600 hover:text-blue-500"
                    target="_blank"
                >
                  assign role groups to staff
                </router-link>
              </p>
            </div>
          </div>
          <div
              v-if="todo_tags_items"
              class="flex mb-4 items-start"
          >
            <label
                class="form-label mr-4 w-1/5 pt-2"
                for="assigned"
            >
              Assign tags
            </label>
            <div class=" w-full">
              <smart-select-tags
                  v-model="tagsModel"
                  :items="todo_tags_items"
                  allow-new
                  class="w-full"
              />

              <p class="text-xs pt-1 text-gray-600">
                To quickly filter through long ToDo lists, this automation can add
                tags to each associated To-Do (example: expiring intro offer, lapsed
                client, celebration etc)
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.radio-box {
  @apply rounded-lg border-4 border-gray-200 flex items-center justify-center flex-col py-8 px-4;
}

.radio-box:hover {
  @apply border-blue-200 bg-blue-100 text-blue-800;
}

.radio-box > .icon {
  @apply opacity-50;
}

.icon {
  @apply group-hover:opacity-100;
}

.radio-box.selected, .selected-box {
  @apply border-green-200 bg-green-50 text-green-900;

  .icon {
    @apply opacity-100;
  }
}
</style>
<script lang="ts" setup>

import TextInput                                  from "@/components/layout/Forms/TextInput.vue";
import RichEditor                                 from "@/components/layout/RichEditor.vue";
import PlaceholdersSelector                       from "@/components/automations/Create/partials/PlaceholdersSelector.vue";
import SmartSelectTags                            from "@/components/layout/Forms/SmartSelectTags.vue";
import SvgIcon                                    from "@/components/SvgIcon.vue";
import {computed, ref, toRef, useAttrs, useSlots} from "vue";
import {QLPlaceholder, QLTodoPriority} from "@/graphql/queries/ql/composables";
import SparkFormErrors, {SparkErrors}  from "@/classes/utils/SparkFormErrors";
import currentTeamQuery                from "@/composables/queries/currentTeamQuery";
import {tagsTodosQuery}                           from "@/composables/queries/tagsQuery";
import {DateTime}                                 from "luxon";
import routesList                                 from "@/composables/routesList";

interface Props {
  subject: string;
  content: string;
  priority: QLTodoPriority;
  overdueHours: number | null;
  postponedHours: number | null;
  assignedToId: string | null;
  assignedTeamGroupId: string | null;
  tags: string[];
  errors: SparkErrors;
}

const props = withDefaults(defineProps<Props>(), {});

const slots = useSlots();
const attrs = useAttrs();

const emit = defineEmits([
                           "update:subject",
                           "update:content",
                           "update:priority",
                           "update:overdueHours",
                           "update:postponedHours",
                           "update:assignedToId",
                           "update:assignedTeamGroupId",
                           "update:tags",
                           "update:errors"
                         ]);

const {currentTeam} = currentTeamQuery();
const {routeList} = routesList();

const groups = computed(() => {
  if (currentTeam.value) {
    return currentTeam.value.groups;
  }
  return [];
});

const users = computed(() => {
  if (currentTeam.value) {
    return currentTeam.value.users;
  }
  return [];
});

const formErrors = computed(() => {
  return new SparkFormErrors(props.errors);
});

const {tags} = tagsTodosQuery();

const todo_tags_items = computed(() => {
  return tags.value.map(item => item.name);
});

const subjectModel = computed({
                                get: () => props.subject,
                                set: (val) => {
                                  emit("update:subject", val);
                                  const err = new SparkFormErrors(props.errors);
                                  err.forget("subject");
                                  emit("update:errors", err.all());
                                }
                              });
const contentModel = computed({
                                get: () => props.content ?? "",
                                set: (val) => {
                                  emit("update:content", val);
                                  const err = new SparkFormErrors(props.errors);
                                  err.forget("content");
                                  emit("update:errors", err.all());
                                }
                              });
const priority = toRef(props, "priority");
const priorityModel = computed({
                                 get: () => {
                                   if (props.priority === "") {
                                     emit("update:priority", QLTodoPriority.NONE);
                                     return QLTodoPriority.NONE;
                                   }
                                   return props.priority;
                                 },
                                 set: (val) => emit("update:priority", val)
                               });


const overdueDays = computed({
                               get: () => {
                                 if (props.overdueHours > 0) {
                                   return Math.round(props.overdueHours / 24);
                                 }
                                 return 0;
                               },
                               set: (val) => emit("update:overdueHours", val * 24)
                             });

const postponedDays = computed({
                                 get: () => {
                                   if (props.postponedHours > 0) {
                                     return Math.round(props.postponedHours / 24);
                                   }
                                   return 0;
                                 },
                                 set: (val) => emit("update:postponedHours", val * 24)
                               });

const assignedToIdModel = computed({
                                     get: () => props.assignedToId,
                                     set: (val) => emit("update:assignedToId", val)
                                   });

const assignedTeamGroupIdModel = computed({
                                            get: () => props.assignedTeamGroupId,
                                            set: (val) => emit("update:assignedTeamGroupId", val)
                                          });

const tagsModel = computed({
                             get: () => props.tags,
                             set: (val) => emit("update:tags", val)
                           });


const editor = ref<RichEditor>(null);

const addPlaceholder = (placeholder: QLPlaceholder) => {
  editor.value.insertText(placeholder.value);
  // emit("update:content", contentModel.value + placeholder.value);
};

const priorities = QLTodoPriority;
const minDate = DateTime.local().toSeconds();
</script>
