<template>
  <div class="shadow bg-white rounded">
    <div class="flex-col">
      <div v-if="isMuted" class="p-4 bg-red-100 text-red-800 border border-red-200  rounded-t">
        <div class="flex flex-row items-center justify-center align-middle">
          <svg-icon class="h-4 w-4 mr-2 shrink-0" name="bell" />
          <span class="font-medium grow">Client is muted until <timestamp-to-date :timestamp="mute.muted_until"
                                                                             has-date
                                                                             has-time
                                                                             studio /></span>
        </div>
        <p class="text-sm mt-1">You cannot send messages to this client while they are muted.</p>
      </div>
      <div class="p-4 text-center border-b">
        <div class="h-12 w-12 inline-block">
          <client-avatar
              :client="client"
              class="block w-12 h-12" />
        </div>
        <div class="pt-2 leading-tight">
          <h1 class="font-medium text-lg mb-2 ">
            {{ client.first_name }} {{ client.last_name }}
          </h1>
          <client-status-text
              :client="client"
              as-tag
              uses-colors />
          <div v-if="client.tags.length == 0" class="pt-3 text-xs">
            <button
                class="mb-1 bg-white rounded-full border border-transparent text-green-900 hover:text-blue-800 inline-flex items-center"
                @click="modals.tags = true">
                <span class="w-4 h-4 mr-1 block">
                  <svg-icon
                      class="w-full h-full"
                      name="user-circle-gear-duotone" />
                </span>
              <span class="block">Add tag</span>
            </button>
          </div>
          <div
              v-if="client.tags.length > 0"
              class="pt-6 text-xs">
            <div class="flex flex-wrap justify-center">
              <tag
                  v-for="tag in client.tags"
                  :key="tag.id"
                  :class="{ 'text-gray-500 opacity-50': tag.archived }"
                  :deleting="deletingTags"
                  :name="tag.name"
                  class="mr-1 mb-1"
                  deletable
                  @delete="deleteTag(parseInt(tag.id))" />
              <button
                  class="mb-1 bg-white rounded-full border border-transparent text-green-900 hover:text-blue-800 inline-flex items-center"
                  @click="modals.tags = true">
                <span class="w-4 h-4 mr-1 block">
                  <svg-icon
                      class="w-full h-full"
                      name="user-circle-gear-duotone" />
                </span>
                <span class="block">Edit tags</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="text-sm divide-y divide-gray-100">
        <div
            :class="{'available': client.can_contact_by_email && me && me.policy.send_messages && !isMuted, 'disabled': !client.can_contact_by_email || !(me && me.policy.send_messages) || isMuted}"
            :disabled="!client.can_contact_by_email || isMuted"
            class="quick-action"
            role="button"
            @click="sendEmail">
          <svg-icon
              class="h-6 w-6 block mr-4 "
              name="email" />
          <div class="leading-tight">
            <span class="font-medium">Send a new email</span>
            <div v-if="me && me.policy.send_messages">
              <p
                  v-if="client.can_contact_by_email && !isMuted"
                  class="text-xs text-gray-700 group-hover:text-blue-600">
                Send a direct email to
                {{ client.first_name }}
              </p>
              <p
                  v-if="client.can_contact_by_email && isMuted"
                  class="text-xs text-gray-700 group-hover:text-blue-600">
                Cannot contact {{ client.first_name }} while muted
              </p>
              <p
                  v-if="!client.can_contact_by_email && client.unsubscribed_email_at > 0"
                  class="text-xs text-gray-700 group-hover:text-blue-600">
                {{ client.first_name }} has unsubscribed
              </p>
              <p
                  v-if="!client.can_contact_by_email && !client.unsubscribed_email_at"
                  class="text-xs text-gray-700 group-hover:text-blue-600">
                Email not present or invalid
              </p>
            </div>
            <p v-if="!(me && me.policy.send_messages)" class="text-xs text-gray-700 group-hover:text-blue-600">
              Your studio is waiting for approval
            </p>
          </div>
        </div>
        <div
            :class="{'available': client.can_contact_by_sms && me && me.policy.send_messages && !isMuted, 'disabled': !client.can_contact_by_sms || !(me && me.policy.send_messages) || isMuted}"
            :disabled="!client.can_contact_by_sms || isMuted"
            class="quick-action"
            role="button"
            @click="sendText">
          <svg-icon
              class="h-6 w-6 block mr-4"
              name="mobile" />
          <div class="leading-tight">
            <span class="font-medium">Send a new text</span>
            <div v-if="me && me.policy.send_messages">
              <p
                  v-if="client.can_contact_by_sms && !isMuted"
                  class="text-xs text-gray-700 group-hover:text-blue-600">
                Send a text message to {{
                  client.first_name
                }}
              </p>
              <p
                  v-if="client.can_contact_by_sms && isMuted"
                  class="text-xs text-gray-700 group-hover:text-blue-600">
                Cannot contact {{ client.first_name }} while muted
              </p>
              <p
                  v-if="!client.can_contact_by_sms && client.unsubscribed_sms_at > 0"
                  class="text-xs text-gray-700 group-hover:text-blue-600">
                {{ client.first_name }}
                has unsubscribed
              </p>
              <p
                  v-if="!client.can_contact_by_sms && !client.unsubscribed_sms_at"
                  class="text-xs text-gray-700 group-hover:text-blue-600">
                Mobile phone not present or
                invalid
              </p>
            </div>
            <p v-if="!(me && me.policy.send_messages)" class="text-xs text-gray-700 group-hover:text-blue-600">
              Your studio is waiting for approval
            </p>
          </div>
        </div>
        <div
            class="quick-action available"
            role="button"
            @click="createTodo">
          <svg-icon
              class="h-6 w-6 block mr-4 "
              name="todo" />
          <div class="leading-tight">
            <span class="font-medium">Create a To-Do</span>
            <p class="text-xs text-gray-700 group-hover:text-blue-600">
              Create a To-Do for {{ client.first_name }}
            </p>
          </div>
        </div>
        <div
            class="quick-action available"
            role="button"
            @click="addNote">
          <svg-icon
              class="h-6 w-6 block mr-4 "
              name="notes" />
          <div class="leading-tight">
            <span class="font-medium">Add a note</span>
            <p class="text-xs text-gray-700 group-hover:text-blue-600">
              Add a note to {{ possessive(client.first_name) }}
              profile
            </p>
          </div>
        </div>
        <div
            class="quick-action available"
            role="button"
            @click="toggleMute">
          <svg-icon
              class="h-6 w-6 block mr-4"
              name="bell" />
          <div class="leading-tight">
            <span class="font-medium">{{ isMuted ? "Unmute Client" : "Mute Client" }}</span>
            <p v-if="isMuted" class="text-xs text-gray-700 group-hover:text-blue-600">
              Muted until
              <timestamp-to-date :timestamp="mute.muted_until" has-date has-time studio />
            </p>
            <p v-else class="text-xs text-gray-700 group-hover:text-blue-600">
              Temporarily stop messages to {{ client.first_name }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <client-send-email
        v-model:open="modals.email"
        :client="client" />
    <client-send-text
        v-model:open="modals.sms"
        :client="client" />
    <client-create-todo
        v-model:open="modals.todo"
        :client="client" />
    <client-change-tags
        v-model:open="modals.tags"
        :client="client" />
    <client-add-note
        v-model:open="modals.note"
        :client="client" />
    <client-mute-modal
        v-model:open="modals.mute"
        :client="client"
        :is-muted="isMuted"
        @muted="refetchParent"
        @unmuted="refetchParent" />
  </div>
</template>
<style scoped>
.quick-action {
  @apply flex px-4 items-center py-4 w-full;
}

.quick-action.available:hover {
  @apply text-blue-800 bg-blue-50;
}

.quick-action.disabled {
  @apply cursor-not-allowed opacity-25;
}
</style>
<script lang="ts" setup>

import ClientAvatar                                                     from "@/components/client/ClientAvatar.vue";
import Tag                                                              from "@/components/layout/Tag.vue";
import ClientSendEmail                                                  from "@/components/client/ClientSendEmail.vue";
import ClientSendText                                                   from "@/components/client/ClientSendText.vue";
import ClientCreateTodo                                                 from "@/components/client/Todos/ClientCreateTodo.vue";
import ClientAddNote                                                    from "@/components/client/ClientAddNote.vue";
import ClientMuteModal                                                  from "@/components/client/ClientMuteModal.vue";
import ClientChangeTags                                                 from "@/components/client/ClientChangeTags.vue";
import { possessive }                                                   from "@/classes/vue-filters/PossessiveFilter";
import ClientStatusText                                                 from "@/components/client/ClientStatusText.vue";
import SvgIcon                                                          from "@/components/SvgIcon.vue";
import { QLClient, useClientMuteHistoryQuery, useUpdateClientMutation } from "@/graphql/queries/ql/composables";
import { computed, ref, toRaw, toRef }                                  from "vue";
import { useRouter }                                                    from "vue-router";
import routesList                                                       from "@/composables/routesList";
import meQuery                                                          from "@/composables/queries/meQuery";
import dayjs                                                            from "dayjs";
import TimestampToDate                                                  from "@/components/layout/TimestampToDate.vue";

interface Props {
  client: QLClient;
}

const props = withDefaults(defineProps<Props>(), {});

const client = toRef(props, "client");
const activeClientTags = computed(() => {
  return client.value.tags.filter((tag) => !tag.archived);
});
const router = useRouter();
const { routeList } = routesList();

const modals = ref({
                     email: false,
                     sms:   false,
                     todo:  false,
                     note:  false,
                     tags:  false,
                     mute:  false
                   }
);

const mute = computed(() => {
  if (props.client.mutes) {
    const mutes = props.client.mutes;
    // Find active mute (has mutedUntil date in the future and no unmutedAt)
    return mutes.find(mute =>
                          !mute.unmuted_at && dayjs(mute.muted_until * 1000).isAfter(dayjs())
    );
  }
  return null;
});

const isMuted = computed(() => {
  return !!mute.value;
});

const toggleMute = () => {
  modals.value.mute = true;
};

const refetchParent = () => {

}


const formatDate = (dateString: string) => {
  if (!dateString) {
    return "N/A";
  }
  return dayjs(dateString).format("MMM D, YYYY");
};

const { me } = meQuery();

const sendEmail = () => {
  if (client.value.can_contact_by_email && (me.value && me.value.policy.send_messages) && !isMuted.value) {
    modals.value.email = true;
  }
};

const sendText = () => {
  if (client.value.can_contact_by_sms && (me.value && me.value.policy.send_messages) && !isMuted.value) {
    modals.value.sms = true;
  }
};

const createTodo = () => {
  modals.value.todo = true;
};

const addNote = () => {
  modals.value.note = true;
};

const deletingTags = ref(false);

const mutation = useUpdateClientMutation({});
mutation.onDone(() => {
  deletingTags.value = false;
});
mutation.onError(() => {
  deletingTags.value = false;
});

const deleteTag = (id: number) => {
  if (client.value.tags.length == 0) {
    return;
  }
  const finalTags: string[] = client.value.tags
                                    .filter((t: any) => t && t.id && parseInt(t.id) !== id)
                                    .map((t: any) => t.name);

  deletingTags.value = true;

  mutation.mutate({
                    id:   client.value.id,
                    tags: finalTags
                  });
};
</script>
