import ToDo from "./partials/todo";
import GraphQlOperator from "hm-filters/operators/GraphQlOperator";
import QLQueriesData from "hm-filters/operators/GraphQls/QLQueriesData";
import {OperatorTypes, OperatorTypesFullName} from "hm-filters/operators/OperatorTypes";

class ToDoCategoryFilter extends ToDo {
    constructor(name: string, options: any) {
        super(name, options);

        this.operators = [
            new ToDoCategoryQlOperator("=", "at least once").setIsMultipleChoice(),
            new ToDoCategoryQlOperator("only", "only").setAloneInFilterGroup(),
            new ToDoCategoryQlOperator("!=", "never")
        ];
    }

    get type(): string {
        return "ToDoCategoryFilter";
    }
}

export default ToDoCategoryFilter;

export class ToDoCategoryQlOperator extends GraphQlOperator {
    graphQLData = QLQueriesData.CommunicationCategoryQlOperator;

    get type(): string {
        return OperatorTypes.GraphQlMultiple;
    }

    get fullType(): string {
        return OperatorTypesFullName.CommunicationCategoryQlOperator;
    }
}