import ToDo from "./partials/todo";
import OperatorGenerator from "../operatorGenerator";
import FixedValueOperator from "hm-filters/operators/FixedValueOperator";

class ToDoHistoryFilter extends ToDo {
	constructor(name: string, options: any) {
		super(name, options);

		// Set date operators for filtering by dates
		this.setOperators(OperatorGenerator.datesAll(true, true, true));
		
		// Add field selection for different date fields
		this.addFieldSelectionOperators();
		
		// Set as date filter
		this.setDateFilter();
	}

	addFieldSelectionOperators() {
		// Add separate operators for each date field
		// This approach uses multiple fixed value operators instead of a dropdown
		const createdAtOperator = new FixedValueOperator("created_at", "Created Date");
		const completedAtOperator = new FixedValueOperator("completed_at", "Completed Date");
		const overdueAtOperator = new FixedValueOperator("overdue_at", "Overdue Date");
		const postponedToOperator = new FixedValueOperator("postponed_to", "Scheduled Date");
		
		// Add the field selectors to the beginning of operators
		this.operators.unshift(
			createdAtOperator,
			completedAtOperator,
			overdueAtOperator,
			postponedToOperator
		);
	}

	get type(): string {
		return "ToDoHistoryFilter";
	}
}

export default ToDoHistoryFilter;