<template>
  <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity z-50" @click="$emit('close')"></div>
  <div class="fixed inset-0 z-50 overflow-y-auto">
    <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
      <div 
          class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6"
          @click.stop>
        <div>
          <div class="mt-3 text-center sm:mt-5">
            <h3 class="text-lg font-medium leading-6 text-gray-900">
              Edit Note Template
            </h3>
            <div class="mt-4">
              <form class="space-y-4" @submit.prevent="submitForm">
                <text-input
                    v-model="editData.title"
                    :error-message="errors.get('title')"
                    label="Title"
                    required
                    small-info="Provide an easy name to identify the use of this template" />
                
                <div class="space-y-1">
                  <label class="block text-sm font-medium text-gray-700">Content <span class="text-red-500">*</span></label>
                  <div class="relative">
                    <rich-editor
                        v-model="editData.body"
                        placeholder="Enter template content here..."
                        ref="richEditor" class="min-h-[20rem]" />
                    <div v-if="errors.get('body')" class="mt-1 text-sm text-red-600">{{ errors.get('body') }}</div>
                  </div>
                  
                  <div class="flex w-full justify-end justify-items-end items-end mt-2">
                    <Menu
                        as="div"
                        class="inline-block relative text-sm">
                      <div class="flex items-end justify-end pr-2">
                        <MenuButton class="inline-flex items-center justify-center focus:outline-none text-blue-500 text-sm font-medium px-2 py-1 rounded bg-opacity-50 bg-white hover:bg-green-200 hover:text-green-900">
                          <span class="block">Merge tags</span>
                          <svg-icon
                              class="h-4 w-4 block"
                              name="down" />
                        </MenuButton>
                      </div>
                      <transition
                          enter-active-class="transition ease-out duration-100"
                          enter-from-class="transform opacity-0 scale-95"
                          enter-to-class="transform opacity-100 scale-100"
                          leave-active-class="transition ease-in duration-75"
                          leave-from-class="transform opacity-100 scale-100"
                          leave-to-class="transform opacity-0 scale-95">
                        <MenuItems class="origin-top-right absolute right-0 mx-4 mt-2 max-h-[20rem] rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none overflow-y-auto">
                          <div class="py-1 divide-y divide-gray-100">
                            <MenuItem
                                v-for="placeholder in placeholders"
                                :key="placeholder.value"
                                v-slot="{ active }">
                              <div
                                  :class="['py-2 text-sm block cursor-pointer block text-gray-900', active ? 'bg-loopspark-50': '']"
                                  @click="addPlaceholder(placeholder.value)">
                                <div class="px-4 py-2">
                                  <span class="font-medium text-sm">{{ placeholder.name }}</span>
                                </div>
                              </div>
                            </MenuItem>
                          </div>
                        </MenuItems>
                      </transition>
                    </Menu>
                  </div>
                </div>
                
                <div class="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                  <v-button
                      :disabled="sending"
                      :loading="sending"
                      class="btn btn-green"
                      type="submit">
                    Update
                  </v-button>
                  <v-button
                      class="btn btn-white mt-3 sm:mt-0"
                      type="button"
                      @click="$emit('close')">
                    Cancel
                  </v-button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import SvgIcon from "@/components/SvgIcon.vue";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import TextInput from "@/components/layout/Forms/TextInput.vue";
import VButton from "@/components/layout/VButton.vue";
import RichEditor from "@/components/layout/RichEditor.vue";
import SparkFormErrors, {
  SparkErrors
} from "@/classes/utils/SparkFormErrors";
import { computed, reactive, ref } from "vue";
import { useNoteTemplateUpdateMutation } from "@/graphql/queries/ql/composables";
import $toast from "@/composables/toast";
import fillValidationErrorsOrToast from "@/composables/queries/mutations/fillValidationErrorsOrToast";

interface Props {
  note_template: {
    id: string;
    title: string;
    body: string;
  };
}

const props = defineProps<Props>();
const emit = defineEmits(['close']);

const editData = reactive({
  title: props.note_template.title,
  body: props.note_template.body
});

const sending = ref(false);
const richEditor = ref(null);

const errorsData = ref<SparkErrors>({});
const errors = computed(() => {
  return new SparkFormErrors(errorsData.value);
});

const addPlaceholder = (placeholder: string) => {
  if (richEditor.value) {
    richEditor.value.insertText("{{" + placeholder + "}}");
  } else {
    editData.body += "{{" + placeholder + "}}";
  }
};

const placeholders = [
  {name: "First Name", value: "FIRST_NAME"},
  {name: "Last Name", value: "LAST_NAME"},
  {name: "Club Count", value: "CLUB_COUNT"},
  {name: "Period Count", value: "PERIOD_COUNT"}
];

const updateTemplateMutation = useNoteTemplateUpdateMutation();

const submitForm = async () => {
  sending.value = true;
  
  try {
    await updateTemplateMutation.mutate({
      id: props.note_template.id,
      title: editData.title,
      body: editData.body
    });
    
    $toast({
      type: "success",
      message: "Template updated successfully",
      linkRouteObj: null,
      linkText: null
    });
    
    emit('close');
  } catch (error) {
    fillValidationErrorsOrToast(
      error,
      errorsData,
      "There was an error while updating the template. Please try again"
    );
  } finally {
    sending.value = false;
  }
};
</script>