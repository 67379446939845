<script setup lang="ts">

import {Menu, MenuButton, MenuItem, MenuItems} from "@headlessui/vue";
import SvgIcon                                 from "@/components/SvgIcon.vue";

interface Props {
  count?: number;

}

const props = withDefaults(defineProps<Props>(), {
  count:    0,
});


const emit = defineEmits(['click-delete', 'click-complete']);

</script>

<template>
  <div class="shrink-0 self-center flex" v-if="count > 0">
    <Menu as="div" class="relative z-30 inline-block text-left">
      <div>
        <MenuButton class="-m-2 p-2 rounded-full flex items-center text-gray-400 hover:text-gray-600">
          <span class="sr-only">Open options</span>
          <svg-icon name="dots-vertical" class="h-5 w-5" aria-hidden="true" />
        </MenuButton>
      </div>

      <transition enter-active-class="transition ease-out duration-100"
                  enter-from-class="transform opacity-0 scale-95"
                  enter-to-class="transform opacity-100 scale-100"
                  leave-active-class="transition ease-in duration-75"
                  leave-from-class="transform opacity-100 scale-100"
                  leave-to-class="transform opacity-0 scale-95">
        <MenuItems class="origin-top-left absolute left-0 z-40 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div class="py-1">
            <MenuItem v-slot="{ active }">
              <button
                  :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'text-left flex px-4 py-2 text-sm w-full items-center']"
                  @click="emit('click-complete')">
                <svg-icon
                    name="check"
                    class="mr-3 h-5 w-5 text-gray-400" />
                <span>Complete all ({{count}})...</span>
              </button>
            </MenuItem>
            <MenuItem v-slot="{ active }">
              <button
                  :class="[active ? 'bg-gray-100 text-red-900' : 'text-red-700', 'text-left flex px-4 py-2 text-sm w-full items-center']"
                  @click="emit('click-delete')">
                <svg-icon
                    name="trash"
                    class="mr-3 h-5 w-5 text-red-400" />
                <span>Delete all ({{ count }})...</span>
              </button>
            </MenuItem>
          </div>
        </MenuItems>
      </transition>
    </Menu>
  </div>
</template>

<style scoped>

</style>
