const Routes = {
	dashboard:     "dashboard",
	login:         "login",
	logout:        "logout",
	missingStudio: "missing-studio",
	billingIssues: "billing-issues",
	discussions:   "discussions",
	todos:         "todos",
	clients:       {
		optOutReport: "clients.opt-out-report",
		uploadLeads:  "clients.upload-leads",
		index:        "clients.index",
		mutedClients: "clients.muted-clients"
	},
	client:        {
		show:        "client.show",
		discussions: {
			index: "client.show.discussions",
			show:  "client.show.discussions.show"
		},
		events:      {
			index:    "client.show.events",
			upcoming: "client.show.events.upcoming"
		},
		sales:       "client.show.sales",
		services:    "client.show.services",
		todos:       {
			index: "client.show.todos",
			show:  "client.show.todos.show"
		},
		notes:       {
			index: "client.show.notes"
		},
		engagement:  "client.show.engagement",
		forms:       {
			index: "client.show.forms",
			show:  "client.show.forms.show"
		}
	},
	automations:   {
		email_templates: {
			index:  "automations.email_templates.index",
			show:   "automations.email_templates.show",
			create: "automations.email_templates.create"
		},
		index:           "automations.index",
		show:            {
			index:   "automation.show.index",
			history: "automation.show.history"
		},
		create:          "automation.create",
		edit:            "automation.edit",
		catalog:         {
			index:  "automations.catalog.index",
			show:   "automations.catalog.show",
			create: "automations.catalog.create",
			edit:   "automations.catalog.edit"
		}
	},
	forms:         {
		index:   "forms.index",
		show:    {
			index:   "forms.show.index",
			history: "forms.show.history"
		},
		create:  "forms.create",
		edit:    "forms.edit",
		catalog: {
			index: "forms.catalog.index",
			show:  "forms.catalog.show"
		}

	},
	insights:         {
		index:   "insights.index",
	},
	event:         {
		show:            "events.show",
		calendar:        "events.calendar",
		virtual_manager: "events.virtual_event_manager"
	},
	user:          {
		settings: "user.settings"
	},
	studio:        {
		settings: {
			profile:          "studio.settings.profile",
			clubCountManager: "studio.settings.club-count-manager",
			groups:           "studio.settings.groups",
			segments:         "studio.settings.segments",
			staff:            "studio.settings.staff",
			billing:          "studio.settings.billing",
			quickResponses:   "studio.settings.quick-responses",
			noteTemplates:    "studio.settings.note-templates",
			tagManager:       "studio.settings.tag-manager",
			usage:            "studio.settings.usage",
			zapier:           "studio.settings.zapier"
		}
	}
};
export default Routes;
