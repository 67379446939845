<template>
  <div>
    <div
        v-if="loading"
        class="p-4 shadow-md rounded-lg bg-white w-full h-40 flex items-center justify-center">
      <loader class="w-8 h-8 text-gray-300" />
    </div>

    <div
        v-if="!loading && sorted_note_templates.length > 0"
        class="shadow-md rounded-lg bg-white divide-y"
        @dragover.prevent
        @dragenter.prevent>
      <div
          v-for="(noteTemplate, index) in sorted_note_templates"
          :key="noteTemplate.id"
          class="py-4"
          draggable="true"
          @dragstart="dragFns.startDrag($event, noteTemplate)"
          @dragend="dragFns.endDragging"
          @dragenter="dragFns.onDragEnter(index, true)"
          @dragleave="dragFns.onDragEnter(index, false)"
          @drop="handleDrop(index)">
        <studio-note-templates-list-item :note_template="noteTemplate" @template-deleted="emit('template-deleted')" />
      </div>
    </div>

    <div
        v-if="!loading && sorted_note_templates.length === 0"
        class="p-4 shadow-md rounded-lg bg-white w-full text-center">
      <p class="text-gray-500">No note templates found. Create your first template below.</p>
    </div>
  </div>
</template>

<script lang="ts" setup>
import Loader from "@/components/layout/Loader.vue";
import StudioNoteTemplatesListItem from "@/views/Studio/Settings/NoteTemplate/StudioNoteTemplatesListItem.vue";
import { computed, ref, watch } from "vue";
import { useNoteTemplatesQuery } from "@/graphql/queries/ql/composables";
import dragDropComposable from "@/composables/dragDropComposable";

const emit = defineEmits(['template-deleted']);

const { status, dragFns } = dragDropComposable();
const noteTemplatesQuery = useNoteTemplatesQuery({
  fetchPolicy: "cache-first"
});
const loading = computed(() => noteTemplatesQuery.loading.value);

// Create a ref to store the ordered templates
const orderedTemplates = ref([]);

// Watch for changes in the query result and update the ordered templates
watch(
    () => noteTemplatesQuery.result.value?.noteTemplates?.data,
    (newTemplates) => {
      if (newTemplates) {
        orderedTemplates.value = [...newTemplates];
      }
    },
    { immediate: true }
);

const sorted_note_templates = computed(() => orderedTemplates.value);

const handleDrop = async (targetIndex: number) => {
  const draggedId = status.over.itemId;
  if (!draggedId) {
    return;
  }

  const draggedIndex = orderedTemplates.value.findIndex(nt => nt.id === draggedId);
  if (draggedIndex === -1 || draggedIndex === targetIndex) {
    return;
  }

  // Create a copy of the array to avoid direct mutation
  const updatedTemplates = [ ...orderedTemplates.value ];

  // Remove the dragged item
  const [ draggedItem ] = updatedTemplates.splice(draggedIndex, 1);

  // Insert it at the new position
  updatedTemplates.splice(targetIndex, 0, draggedItem);

  // Update the orderedTemplates ref with the new order
  orderedTemplates.value = updatedTemplates;

};

</script>