<template>
  <div>
    <div v-if="segment.filters.length > 0">
      <div
          v-for="(filterGroup, index) in segment.filters"
          :key="index">
        <filter-group
            v-model="segment.filters[index]"
            :editable="editable"
            :catalog-variables="catalogVariables"
            :is_event="is_event"
            @deleted="deleteGroup(index)" />
        <div
            v-if="index < segment.filters.length -1"
            class="flex items-center py-2">
          <hr class="grow">
          <div class="relative inline-block">
            <div class="mx-2 text-gray-500 text-xs inline-flex items-center">
              <span>AND</span>
            </div>
          </div>
          <hr class="grow">
        </div>
      </div>
    </div>
    <div
        v-if="!segment.filters.length"
        class="bg-yellow-100 border border-yellow-200 text-yellow-900 rounded-md p-4 text-sm mt-4 text-center">
      <div>This segment has no rules yet. As it stands, it will match any client.</div>
      <div
          v-if="editable"
          class="mt-2">
        <button
            class="btn btn-loopspark"
            @click="openNewGroup">
          Add a rule
        </button>
      </div>
    </div>
    <filter-group-edit
        :is_event="is_event"
        v-if="newFilterGroup"
        v-model:open="newgroupmodal"
        v-model="newFilterGroup"
        @closed="addGroup"
        @deleted="newFilterGroup = null" />

    <segment-import-segment
        v-model:open="importgroupmodal"
        @close="importgroupmodal = false"
        @import-segment="mergeImportedSegment" />

    <div
        v-if="editable"
        class="pt-2 flex justify-between">
      <div
          class="btn btn-transparent text-blue-500 shrink text-xs"
          role="button"
          @click="openNewGroup">
        <div class="flex items-center">
          <svg-icon
              name="plus"
              class="w-4 h-4" />
          <span class="ml-1">Add a rule...</span>
        </div>
      </div>

      <div
          class="btn btn-transparent text-blue-500 shrink text-xs"
          role="button"
          @click="openImport">
        <div class="flex items-center">
          <svg-icon
              name="loadBalancer"
              class="w-3 h-3" />
          <span class="ml-1">Import rules...</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>

import FilterGroup                                                       from "@/components/Segment/FilterGroup.vue";
import FilterGroupEdit                                                   from "@/components/Segment/FilterGroupEdit.vue";
import {FilterGroup as FilterGroupObj}                                   from "@/classes/entities/FilterGroup";
import {deepCopy}                                                        from "@/classes/helpers/DeepCopy";
import SegmentImportSegment                                              from "@/components/Segment/SegmentImportSegment.vue";
import SvgIcon                                                           from "@/components/SvgIcon.vue";
import {computed, nextTick, ref, toRef}                                  from "vue";
import {QLCatalogItemVariableFragmentFragment, QLFilterGroup, QLSegment} from "@/graphql/queries/ql/composables";
import SegmentDecorator                                                  from "@/composables/decorators/SegmentDecorator";

interface Props {
  catalogVariables?: QLCatalogItemVariableFragmentFragment[];
  editable?: boolean;
  modelValue: QLSegment;
  is_event?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  editable:         true,
  catalogVariables: () => [],
  is_event:         false
});


const emit = defineEmits(["update:modelValue"]);

const newgroupmodal = ref(false);
const importgroupmodal = ref(false);

const newFilterGroup = ref<QLFilterGroup | null>(null);
const catalogVariables = toRef(props, "catalogVariables");
const editable = toRef(props, "editable");
const is_event = toRef(props, "is_event");

const segment = computed({
                           get: () => props.modelValue,
                           set: (val) => emit("update:modelValue", val)
                         });

const deleteGroup = (index) => {
  segment.value.filters.splice(index, 1);
};
const openNewGroup = async () => {
  newFilterGroup.value = FilterGroupObj.createEmpty();
  await nextTick();
  newgroupmodal.value = true;
};

const addGroup = () => {
  segment.value.filters.push(deepCopy(newFilterGroup.value));
  newFilterGroup.value = null;
};
const openImport = () => {
  importgroupmodal.value = true;
};

const mergeImportedSegment = (segmentData) => {
  const newSegment = (SegmentDecorator(segmentData)).methods.refreshIds();
  
  newSegment.value.filters.forEach((filter) => segment.value.filters.push(filter));
};
</script>
