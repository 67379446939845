import { QLSegment }   from "@/graphql/queries/ql/composables";
import { FilterGroup } from "@/classes/entities/FilterGroup";

export class Segment {
	static createEmptyCustomSegment(withActive = true) {
		const segment: QLSegment = {
			name:                      "Custom segment",
			id:                        "0",
			filters:                   [],
			visible_dashboard:         false,
			only_deliverable_by_email: false,
			only_deliverable_by_sms:   false,
			archived:                  false
		};

		if (withActive) {
			segment.filters.push(FilterGroup.createEmptyWithActiveClients());
		}

		return segment;
	}

	static createEmptyWithEvent(
		status: "any" | "attended" | "upcoming" | "absent" | "late_cancelled" | "early_cancelled" | "any_cancelled" = "any",
		name: string) {
		const segment: QLSegment = {
			name:                      name,
			id:                        status,
			filters:                   [],
			visible_dashboard:         false,
			only_deliverable_by_email: false,
			only_deliverable_by_sms:   false,
			archived:                  false,
			is_event:                  true,
			order:                     null
		};

		segment.filters.push(FilterGroup.createEmptyWithEvent(status));

		return segment;
	}

}
