<template>
  <div v-if="addonInfo.enabled" class="shadow-md rounded-lg bg-white p-4 flex justify-between flex-col">
    <div class="grow">
      <div class="flex justify-between">
        <div class="text-xl font-bold text-gray-900">
          <slot name="title" />
        </div>
        <div v-if="addonIsActive">
          <span class="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-green-100 text-green-800">Active</span>
        </div>
      </div>
      <div>
        <p class="mt-4 flex items-baseline text-gray-900">
          <span class="text-3xl font-bold tracking-tight">${{ addonInfo.price }}</span>
          <span class="ml-1 text-lg font-bold">/ {{ addonInfo.priceUnit }}</span>
        </p>

        <p class="mt-5 text-base text-gray-500">
          <slot name="description" />
        </p>

        <!-- Feature list -->
        <ul
            class="mt-6 space-y-6"
            role="list">
          <li
              v-for="feature in addonInfo.features"
              :key="feature.title"
              class="flex">
            <svg-icon
                class="flex-shrink-0 w-6 h-6 text-indigo-500"
                name="check" />
            <span class="ml-3 text-gray-500">{{ feature }}</span>
          </li>
        </ul>
      </div>
    </div>

    <div>
      <v-button
          v-if="!addonIsActive"
          :disabled="saving"
          :loading="saving"
          class="bg-indigo-500 text-white hover:bg-indigo-600 mt-8 block w-full py-3 px-6 border border-transparent rounded-md text-center font-medium"
          type="button"
          @click="startAddon(addonInfo.addonType)">
        <slot name="addButton" />
      </v-button>
      <v-button
          v-if="addonIsActive && addonIsCancellable"
          :disabled="saving"
          :loading="saving"
          class="bg-white-500 text-red-700 hover:bg-red-50 mt-4 block w-full py-3 px-6 border border-transparent rounded-md text-center font-medium"
          type="button"
          @click="openRemoveDialog = true">
        Cancel add-on service...
      </v-button>
    </div>
  </div>
  <confirm-modal
      v-model:open="openRemoveDialog"
      :loading="saving"
      close-text="Close"
      title="Are you sure?"
      type="warning"
      @clicked="stopAddon(addonInfo.addonType)">
    <template #button>
      Yes, cancel this addon
    </template>
    <template #text>
      {{ addonInfo.cancelMessage }}
    </template>
  </confirm-modal>
</template>
<script lang="ts" setup>
import { QLFragmentCurrentTeamFragment, QLFragmentTeamSubscriptionFragment, useStartAddonMutation, useStopAddonMutation } from "@/graphql/queries/ql/composables";
import { computed, ref, toRef }                                                                                           from "vue";
import { AddonInfo }                                                                                                      from "@/config";
import SvgIcon                                                                                                            from "@/components/SvgIcon.vue";
import $toast                                                                                                             from "@/composables/toast";
import VButton                                                                                                            from "@/components/layout/VButton.vue";
import ConfirmModal                                                                                                       from "@/components/layout/ConfirmModal.vue";
import { addonCancellable, addonEnabled }                                                                                 from "@/classes/billing/addons";

interface Props {
  currentTeam: QLFragmentCurrentTeamFragment;
  mySubscription: QLFragmentTeamSubscriptionFragment;
  addonInfo: AddonInfo;
}

const props = defineProps<Props>();

const emit = defineEmits([ "added", "removed" ]);

const mySubscription = toRef(props, "mySubscription");
const addonInfo = toRef(props, "addonInfo");

const saving = ref(false);

const addonIsActive = computed(() => {
  return addonEnabled(props.currentTeam, props.addonInfo.addonType);
});

const addonIsCancellable = computed(() => {
  return addonCancellable(props.currentTeam, props.addonInfo.addonType);
});

const startAddonMutation = useStartAddonMutation({});

const startAddon = (addon: string) => {
  saving.value = true;
  startAddonMutation.mutate({ addon: addon });
};

startAddonMutation.onDone(() => {
  saving.value = false;
  $toast({
           type:         "success",
           message:      "The addon " + addonInfo.value.title + " was added to your account!",
           linkRouteObj: null,
           linkText:     null
         });
  emit("added");
});
startAddonMutation.onError(() => {
  saving.value = false;
  $toast({
           type:         "error",
           message:      "There was a problem while subscribing to the Addon. Please try again",
           linkRouteObj: null,
           linkText:     null
         });
});

const openRemoveDialog = ref(false);

const stopAddonMutation = useStopAddonMutation({});

const stopAddon = (addon: string) => {
  saving.value = true;
  stopAddonMutation.mutate({ addon: addon });
  openRemoveDialog.value = false;
};

stopAddonMutation.onDone(() => {
  saving.value = false;
  $toast({
           type:         "success",
           message:      "The addon " + addonInfo.value.title + " was removed from your account",
           linkRouteObj: null,
           linkText:     null
         });
  emit("removed");
});
stopAddonMutation.onError(() => {
  saving.value = false;
  $toast({
           type:         "error",
           message:      "There was a problem while unsubscribing to the Addon. Please try again",
           linkRouteObj: null,
           linkText:     null
         });
});

</script>
