import OperatorGenerator         from "hm-filters/operatorGenerator";
import { ThisEventGroup }        from "hm-filters/filters/partials/ThisEventGroup";
import { PackageNameQlOperator } from "hm-filters/filters/packageNameFilter";

class ThisEventBookingPackage extends ThisEventGroup {
	constructor(name: string, options: any) {
		super(name, options);

		this.operators = [
			new PackageNameQlOperator("=", "used")
				.setIsMultipleChoice()
				.allowsCustomInput(),
			new PackageNameQlOperator("!=", "did not use").setIsMultipleChoice().allowsCustomInput(),
			...OperatorGenerator.textContainsMultiple()
		];
	}

	get type(): string {
		return "ThisEventBookingPackage";
	}
}

export default ThisEventBookingPackage;

