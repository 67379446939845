<template>
  <div class="container mx-auto grow">
    <div class="">
      <div v-if="!client" class="w-full py-20 flex items-center justify-center">
        <loader class="w-12 h-12" />
      </div>
      <div v-if="client && isMuted">
        <status-banner-item color="red">
          <div class="flex items-center justify-between">
            <div><strong>{{ client.first_name }} {{ client.last_name }}</strong> is currently muted until {{
                formatDate(clientMuteInfo?.mutedUntil)
                                                                                 }}
            </div>
            <button class="bg-white text-red-600 px-3 py-1 rounded hover:bg-red-50" @click="handleUnmute">Unmute
            </button>
          </div>
        </status-banner-item>
      </div>
      <div v-if="client" class="flex flex-wrap">
        <div class="w-full md:w-1/4 mt-6">
          <client-top-info-box :client="client" class="mb-4" />
          <client-side-menu :client="client" />
        </div>

        <div class="w-full md:w-3/4 mt-6">
          <div class="mx-2 md:ml-6 md:mr-0">
            <router-view :client="client" />
          </div>
        </div>
      </div>
    </div>
    <client-mute-modal v-if="client"
        v-model:open="showMuteModal"
        :client="client"
        :is-muted="isMuted"
        @unmuted="onClientUnmuted" />
  </div>
</template>
<script lang="ts" setup>

import ClientTopInfoBox                    from "@/components/client/ClientTopInfoBox.vue";
import ClientSideMenu                      from "@/components/client/ClientSideMenu.vue";
import Loader                              from "@/components/layout/Loader.vue";
import StatusBannerItem                    from "@/components/StatusBanner/StatusBannerItem.vue";
import { computed, onMounted, ref, toRef } from "vue";
import { useClientSingleQuery }            from "@/graphql/queries/ql/composables";
import { echoListener }                    from "@/composables/echoListener";
import { EventTypes }                      from "@/classes/notifications/EventTypes";
import dayjs                               from "dayjs";
import ClientMuteModal                     from "@/components/client/ClientMuteModal.vue";

interface Props {
  id: string;
}

const props = withDefaults(defineProps<Props>(), {});

const id = toRef(props, "id");

const clientQ = useClientSingleQuery(() => ({ id: id.value }));

const client = computed(() => clientQ.result.value?.client ?? null);



const clientMuteInfo = ref<any>(null);
const isMuted = ref(false);
const showMuteModal = ref(false);



onMounted(() => {
  checkMuteStatus();
});

const checkMuteStatus = () => {
  if (client.value?.mutes) {
    // Find active mute (has mutedUntil date in the future and no unmutedAt)
    const activeMute = client.value?.mutes.find(mute =>
                                      !mute.unmuted_at && dayjs(mute.muted_until).isAfter(dayjs())
    );

    if (activeMute) {
      isMuted.value = true;
      clientMuteInfo.value = activeMute;
    } else {
      isMuted.value = false;
      clientMuteInfo.value = null;
    }
  }
};

const formatDate = (dateString: string) => {
  if (!dateString) {
    return "N/A";
  }
  return dayjs(dateString).format("MMM D, YYYY");
};

// Unmute client directly from banner
const handleUnmute = () => {
  showMuteModal.value = true;
};

// Handle unmute event from modal
const onClientUnmuted = () => {
  clientQ.refetch();
};

const refresh = () => {
  clientQ.restart();
};

echoListener([ EventTypes.BroadcastClientWasUpdated ], (ev) => {
  if (!!client.value && ev.data.client_id == (client.value?.id ?? null)) {
    clientQ.refetch();
  }
}, 5000);

</script>
