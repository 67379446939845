<template>
  <div v-if="showModal" class="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center">
    <div class="bg-white rounded-lg p-6 max-w-2xl w-full mx-4">
      <div class="flex justify-between items-center mb-4">
        <h3 class="text-lg font-medium text-gray-900">
          {{ editingSegment?.id ? "Edit Segment" : "Create New Segment" }}
        </h3>
        <button
            class="text-gray-400 hover:text-gray-500"
            @click="closeModal">
          <svg-icon
              class="w-6 h-6"
              name="close" />
        </button>
      </div>

      <div class="mb-4">
        <label class="block text-sm font-medium text-gray-700 mb-2">
          Segment Name
        </label>
        <input
            v-model="editingSegment.name"
            class="form-input block w-full rounded-md border-gray-300"
            placeholder="Enter segment name"
            type="text">
      </div>

      <segment-view-edit
          v-if="editingSegment"
          v-model="editingSegment"
          :editable="true"
          :is_event="true" />

      <div class="mt-6 flex justify-end space-x-3">
        <v-button
            class="btn btn-white"
            @click="closeModal">
          Cancel
        </v-button>
        <v-button
            :disabled="modalSaving"
            :loading="modalSaving"
            class="btn btn-loopspark"
            @click="saveSegment">
          {{ editingSegment?.id ? "Save Changes" : "Create Segment" }}
        </v-button>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { defineEmits, defineProps, ref }                      from "vue";
import VButton                                                from "@/components/layout/VButton.vue";
import SegmentViewEdit                                        from "@/components/Segment/SegmentViewEdit.vue";
import SvgIcon                                                from "@/components/SvgIcon.vue";
import SegmentDecorator                                       from "@/composables/decorators/SegmentDecorator";
import { useCreateSegmentMutation, useUpdateSegmentMutation } from "@/graphql/queries/ql/composables";

interface QLSegment {
  id: string;
  name: string;
  filters: any[];
  visible_dashboard: boolean;
  only_deliverable_by_email: boolean;
  only_deliverable_by_sms: boolean;
  archived: boolean;
  order: number | null;
  is_event: boolean;
}

interface Props {
  segment: Partial<QLSegment>;
  showModal: boolean;
}

const props = defineProps<Props>();
const emit = defineEmits([ "close", "segment-saved" ]);

const modalSaving = ref(false);

// Create a new segment object with the input properties
const editingSegment = ref<QLSegment>({
                                        id:                        props.segment?.id || "",
                                        name:                      props.segment?.name || "",
                                        filters:                   props.segment?.filters || [],
                                        visible_dashboard:         props.segment?.visible_dashboard ?? false,
                                        only_deliverable_by_email: props.segment?.only_deliverable_by_email ?? false,
                                        only_deliverable_by_sms:   props.segment?.only_deliverable_by_sms ?? false,
                                        archived:                  props.segment?.archived ?? false,
                                        order:                     props.segment?.id ? (props.segment?.order ?? 1) : 0,
                                        is_event:                  true
                                      });

const { mutate: createSegment, onDone: onCreateSegmentDone } = useCreateSegmentMutation({});
const { mutate: updateSegment, onDone: onUpdateSegmentDone } = useUpdateSegmentMutation({});

const saveSegment = () => {
  if (!editingSegment.value.name?.trim()) {
    return;
  }

  modalSaving.value = true;
  const segmentData = {
    ...SegmentDecorator(editingSegment.value).computed.toPost,
    order: editingSegment.value.id ? (editingSegment.value.order ?? 1) : 0
  };

  if (editingSegment.value.id) {
    // Update existing segment
    updateSegment({
                    id:   editingSegment.value.id,
                    data: segmentData
                  });
  } else {
    // Create new segment
    createSegment({
                    data: segmentData
                  });
  }
};

onCreateSegmentDone(async () => {
  modalSaving.value = false;
  emit("segment-saved");
  closeModal();
});

onUpdateSegmentDone(async () => {
  modalSaving.value = false;
  emit("segment-saved");
  closeModal();
});

const closeModal = () => {
  emit("close");
};
</script>