<template>
    <side-panel
            v-model:open="openModel"
            :closable="!sending">
        <template #header>
            Tags of {{ client.first_name }}
        </template>
        <div class="flex flex-col min-h-full h-full px-4 py-4">
            <div class="h-full">
                <p class="text-gray-600 text-sm mb-6">
                    Tags can be added to a client's profile directly through here.
                    Tags allow you to narrow
                    down your audience by clients who have, or do not have specific
                    tags in their LoopSpark profile.
                </p>

                <label class="form-label mb-2">
                    Tags
                </label>

                <smart-select-tags v-model="tempTags"
                                   :items="possibleTags"
                                   :allow-new="canAddTags"
                                   class="smart-select-new-line"
                                   placeholder="Select tags.." />
                
            </div>
        </div>

        <template #footer>
            <div class="flex m-2 justify-between w-full">
                <v-button
                        :disabled="sending"
                        class="btn btn-white"
                        @click="openModel = false">
                    Cancel
                </v-button>
                <v-button
                        :loading="sending"
                        class="btn btn-green"
                        @click="send">
                    Save!
                </v-button>
            </div>
        </template>
    </side-panel>
</template>
<style>
.smart-select-new-line .form-select {
    display:block;
    @apply w-full;
}
</style>
<script lang="ts" setup>
import SidePanel                                                    from "@/components/layout/SidePanel.vue";
import VButton                                                      from "@/components/layout/VButton.vue";
import {computed, onMounted, ref, toRef, useAttrs, useSlots, watch} from "vue";
import {QLClient, useUpdateClientMutation}                          from "@/graphql/queries/ql/composables";
import SparkFormErrors                                              from "@/classes/utils/SparkFormErrors";
import fillValidationErrorsOrToast
                                                                    from "@/composables/queries/mutations/fillValidationErrorsOrToast";
import $toast                                                       from "@/composables/toast";
import {tagsClientsQuery}                                           from "@/composables/queries/tagsQuery";
import SmartSelectTags
                                                                    from "@/components/layout/Forms/SmartSelectTags.vue";
import meQuery                                                      from "@/composables/queries/meQuery";
import currentTeamQuery from "@/composables/queries/currentTeamQuery";
import useCanAddTags    from "@/composables/useCanAddTags";

interface Props {
	open: boolean;
	client: QLClient;
}

const props = withDefaults(defineProps<Props>(), {});

const slots = useSlots();
const attrs = useAttrs();

const emit = defineEmits(["update:open"]);

const openModel = computed({
	                           get: () => props.open,
	                           set: (val) => emit("update:open", val)
                           });

const form = ref<HTMLFormElement>(null);
const errorsData = ref({});
const sending = ref(false);

const client = toRef(props, "client");

const errors = computed(() => {
	return new SparkFormErrors(errorsData.value);
});

const tempTags = ref<string[]>([]);

const refreshStatus = () => {
	tempTags.value = client.value.tags.map((t) => t.name);
	sending.value = false;
	errorsData.value = {};
};

onMounted(refreshStatus);
watch(client, refreshStatus);

const {tags: clientTags} = tagsClientsQuery();
const possibleTags = computed(() => {
	return clientTags.value.filter(tag => !tag.archived).map(item => item.name);
});

const addTag = (tag: string) => {
	if (tempTags.value.indexOf(tag) < 0) {
		tempTags.value.push(tag);
	}
};

const deleteTag = (tag: string) => {
	tempTags.value.splice(tempTags.value.indexOf(tag), 1);
};

const mutation = useUpdateClientMutation({});

mutation.onError((err) => {
	fillValidationErrorsOrToast(err, errorsData, "Error creating the note. Please try again!");
	sending.value = false;
});

mutation.onDone(() => {
	$toast({
		       type:         "success",
		       message:      "Successfully updated tags of " + client.value.first_name,
		       linkText:     null,
		       linkRouteObj: null
	       });
	sending.value = false;
	openModel.value = false;
	refreshStatus();
});

const send = () => {
	errorsData.value = {};

	sending.value = true;
	mutation.mutate({
		                id:   client.value.id,
		                tags: tempTags.value
	                });
};

const canAddTags = useCanAddTags();
</script>
