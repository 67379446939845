<template>
  <filter-group-base-category
      v-model:filter-selected="filterSelected"
      v-model:operator-selected="operatorSelected"
      :category-name="categoryName"
      @step-back="stepBack"
      @added-rule="addedRule($event)">
    <template #title>
      New Filter - ToDos
    </template>
    <template #subtitle>
      The ToDo filters allow you to segment clients based on their ToDos, including status, count, creation source, and dates. Use these filters to target clients with specific ToDo characteristics.
    </template>
    <template #side-description>
      <div>
        <p>
          ToDo filters help you target clients based on their ToDo items. These filters are useful for follow-up campaigns, client engagement tracking, and workflow management.
        </p>

        <div class="pt-4">
          <p>
            Examples:
          </p>

          <ol class="filter-side-examples pt-2">
            <li>
              <div class="filter-side-examples-filters">
                <span class="fake-filter">ToDo Status = Open</span>
              </div>
              <div class="filter-side-examples-info">
                Show clients that have at least one open ToDo.
              </div>
            </li>
            <li>
              <div class="filter-side-examples-filters">
                <span class="fake-filter">ToDo Status = Overdue</span>
              </div>
              <div class="filter-side-examples-info">
                Show clients that have at least one overdue ToDo.
              </div>
            </li>
            <li>
              <div class="filter-side-examples-filters">
                <span class="fake-filter">ToDo Count = more than 3</span>
              </div>
              <div class="filter-side-examples-info">
                Show clients that have more than 3 ToDos.
              </div>
            </li>
            <li>
              <div class="filter-side-examples-filters">
                <span class="fake-filter">ToDo Automation = manual</span>
              </div>
              <div class="filter-side-examples-info">
                Show clients with manually created ToDos.
              </div>
            </li>
            <li>
              <div class="filter-side-examples-filters">
                <span class="fake-filter">ToDo History</span> +
                <span class="fake-filter">Created Date</span>
              </div>
              <div class="filter-side-examples-info">
                Show clients with ToDos created within a specific timeframe.
              </div>
            </li>
            <li>
              <div class="filter-side-examples-filters">
                <span class="fake-filter">ToDo Status = Completed</span> +
                <span class="fake-filter">ToDo History</span> +
                <span class="fake-filter">Completed Date</span>
              </div>
              <div class="filter-side-examples-info">
                Show clients with ToDos completed within a specific timeframe.
              </div>
            </li>
          </ol>
        </div>
      </div>
    </template>
    <template #filters>
      <slot />
    </template>
  </filter-group-base-category>
</template>
<script lang="ts">
import {singleCategoryComposition} from "@/components/Segment/Edit/Categories/SingleCategories/singleCategoryComposition";

const cat = singleCategoryComposition();
export default {
  components: {
    ...cat.components
  },
  props:      {
    ...cat.props
  },
  emits:      cat.emits,
  setup(props, {emit}) {
    return cat.setup(props, emit);
  }
};
</script>