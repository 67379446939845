import ToDoStatusFilter from "hm-filters/filters/ToDoStatusFilter";
import ToDoCountFilter from "hm-filters/filters/ToDoCountFilter";
import ToDoAutomationFilter from "hm-filters/filters/ToDoAutomationFilter";
import ToDoHistoryFilter from "hm-filters/filters/ToDoHistoryFilter";
import ToDoCategoryFilter from "../filters/ToDoCategoryFilter";

export const ToDoFilters = [
	new ToDoStatusFilter("Status", { "field": "todo_status" })
		.setDescription(
			"Filter clients based on their ToDo status. This includes open, overdue, scheduled, completed, and deleted ToDos."),

	new ToDoCountFilter("Count", { "field": "todo_count" })
		.setDescription(
			"Filter clients based on the number of ToDos they have. Use this to find clients with many or few ToDos."),

	new ToDoAutomationFilter("Automation", { "field": "todo_automation" })
		.setDescription(
			"Filter clients based on the automation that created their ToDos. Use 'created manually' for ToDos created by staff."),

	new ToDoCategoryFilter("Automation category", { "field": "todo_automation_cat" })
		.setDescription(
			"Filter clients based on the automation that created their ToDos. Use 'created manually' for ToDos created by staff."),


	new ToDoHistoryFilter("Creation date", { "field": "created_at" })
		.setDateFilter()
		.setDescription(
			"Filter clients based on ToDo creation date."),

	new ToDoHistoryFilter("Completion date", { "field": "completed_at" })
		.setDateFilter()
		.setDescription(
			"Filter clients based on ToDo completion date."),

	new ToDoHistoryFilter("Overdue date", { "field": "overdue_at" })
		.setDateFilter()
		.setDescription(
			"Filter clients based on ToDo overdue date."),

	new ToDoHistoryFilter("Postponed date", { "field": "postponed_to" })
		.setDateFilter()
		.setDescription(
			"Filter clients based on ToDo scheduled date.")



];
