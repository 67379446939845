<template>
  <div class="p-2">
    <div class="ql-editor">
      <div v-html="item.content" />
    </div>
  </div>
</template>
<script lang="ts" setup>
import { QLFormField } from "@/graphql/queries/ql/composables";
import { toRef }       from "vue";

interface Props {
  item: QLFormField;
}

const props = withDefaults(defineProps<Props>(), {});

const emit = defineEmits([]);

const item = toRef(props, "item");
</script>
