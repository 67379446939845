<template>
  <div class="min-h-full">
    <div class="text-xl text-gray-900 font-medium">
      Rule
    </div>
    <div class="text-sm text-gray-600">
      Rules help create very targeted audience segments for reporting and
      automation triggering, and should only contain filters within the same filter
      category. Audience segments will commonly consist of multiple rules.
    </div>

    <div class="py-8">
      <div class="lg:flex">
        <div class="grow">
          <logic-toggle
            v-if="filtersAsObjects.length > 0"
            v-model="andOrSelector"
            :can-select-and="canUseAndLogic"
            :single-filter="filtersAsObjects.length === 1"
            class="mb-4"
          />

          <div
            v-if="filtersAsObjects.length === 0"
            class="rounded bg-gray-50 p-4 py-8 text-gray-700 text-center text-sm"
          >
            This rule is empty. It will match any client until you
            <button
              class="inline text-blue-700 hover:text-blue-500"
              @click="newFilter"
            >
              add a new filter
            </button>
          </div>

          <div
            v-if="filtersAsObjects.length > 0"
            class="shadow rounded px-2 py-2"
          >
            <div
              v-for="(filter, index) in filtersAsObjects"
              :key="index"
            >
              <div class="py-4">
                <div class="flex justify-between">
                  <filter-and-operator-in-list :filter-and-operator="filter"/>
                  <div class="mr-2 flex space-x-2">
                    <button @click="editFilter(filter, index)">
                      <svg-icon
                        name="edit"
                        class="w-4 h-4 text-gray-300 hover:text-gray-700"
                      />
                    </button>
                    <button @click="removeFilter(filter, index)">
                      <svg-icon
                        name="trash"
                        class="w-4 h-4 text-red-300 hover:text-red-700"
                        data-testid="trash"
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="py-10">
            <button
              class="btn btn-loopspark inline-block focus:ring-2 focus:ring-blue-300"
              role="button"
              tabindex="1"
              @click="newFilter"
            >
              <div class="inline-flex items-center">
                <svg-icon
                  name="plus"
                  class="h-4 w-4"
                />
                <span>Add a filter...</span>
              </div>
            </button>
          </div>
        </div>

        <div class="w-full order-last mb-8 lg:w-1/3 lg:ml-8 text-xs text-gray-600 shadow-inner p-4 rounded space-y-4">
          <p>
            Rules are groups of filters that relate to a similar entity, such as a specific package (example:
            Package Purchased = Intro Package & Package Active = Active & Package Start Date = 3 days ago will return
            all "Active" Intro Packages that started 3 days ago).
          </p>
          <p>On the left, you'll see a recap of the rule you're currently creating.</p>
          <p>
            By default, rules will have "Match All," which connects each filter contained in that rule
            (example: Booking Count = 5 & Event Date = In the last 30 days & Event Type = Group Class & Booking Status
            = Attended is a booking rule that will return clients that have attended 5 group classes in the past
            30 days.).
          </p>
          <p>
            Selecting "Match Any" creates a rule where clients only need to match 1 of the filters, example:
            Client Email = demo@demo.com OR Client Email = hello@hello.com).
          </p>
          <p>Writing down your intended audience on paper will help you understand what rules are required.</p>

          <div class="pt-4">
            <div class="bg-gray-100 px-2 py-2 rounded">
              <span class="font-medium leading-loose">Example</span>
              <p class="mb-4">
                I want to target clients that have created their account in the past 60 days (client
                category), that
                have attended 5 or more group classes (booking category) and have never purchased a
                membership
                (contract category). This example would require 3 rules: One for client category
                (account
                creation date) a second for booking category (group class attendance) and the third for
                contract
                category (memberships purchased).
              </p>

              <div class="mb-2">
                <span class="font-medium">Rule 1:</span>
                <div class="pl-2">
                  Client Account Creation Date = In the last 60 days
                </div>
              </div>
              <div class="mb-2">
                <span class="font-medium">Rule 2:</span>
                <div class="pl-2">
                  Booking Count = 5
                </div>
                <div class="pl-2">
                  Booking Status = Attended
                </div>
              </div>

              <div class="mb-2">
                <span class="font-medium">Rule 3:</span>
                <div class="pl-2">
                  Contract Purchased = No Contracts
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.pill {
  @apply px-2 inline-block leading-none py-1 border rounded-lg mr-2 text-xs;
}

.pill-appointment {
  @apply border-yellow-400 bg-yellow-100 text-yellow-900;
}

.pill-product {
  @apply border-purple-400 bg-purple-100 text-purple-900;
}
</style>

<script lang="ts" setup>
import { filterAndSelectedOperatorGetter } from "hm-filters/filterGetter";
import FilterAndOperatorInList from "@/components/Segment/Edit/Partials/FilterAndOperatorInList.vue";
import LogicToggle from "@/components/Segment/Edit/Partials/LogicToggle.vue";
import { computed, toRef } from "vue";
import { QLFilterGroup } from "@/graphql/queries/ql/composables";
import SvgIcon from "@/components/SvgIcon.vue";
import type Filter from "@/classes/filters/base-filter";

interface Props {
  modelValue: QLFilterGroup;
}

const props = withDefaults(defineProps<Props>(), {});
const emit = defineEmits(["update:modelValue", "edited", "newFilter"]);
const modelValue = toRef(props, "modelValue");

const selectedValue = computed({
  get: () => {
    return modelValue.value;
  },
  set: (newVal) => emit("update:modelValue", newVal)
});

const filtersAsObjects = computed(() => {
  return selectedValue.value.filters.map((filterInfo) => {
    return filterAndSelectedOperatorGetter(filterInfo);
  });
});

// Get distinct categories from filters
const distinctCategories = computed(() => {
  const categories = new Set(
    filtersAsObjects.value.map((filter) => filter.filter.path[0])
  );
  return Array.from(categories);
});

const canUseAndLogic = computed(() => {
  if (filtersAsObjects.value.length <= 1) return true;
  return distinctCategories.value.length === 1;
});

const andOrSelector = computed({
  get: () => modelValue.value.operator_is_and,
  set: (val) => {
    if (!val || (val && canUseAndLogic.value)) {
      const newValue = { ...modelValue.value, operator_is_and: val };
      emit("update:modelValue", newValue);
    }
  }
});

const newFilter = () => {
  emit("newFilter");
};

const removeFilter = (filter, index: number) => {
  const newFilters = [...modelValue.value.filters];
  newFilters.splice(index, 1);
  emit("update:modelValue", {
    ...modelValue.value,
    filters: newFilters
  });
};

const editFilter = (filter, index: number) => {
  removeFilter(filter, index);
  emit("edited", filter);
};
</script>
