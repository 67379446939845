<template>
  <div class="">
    <!-- Event Status Banner -->
    <div v-if="singleEvent.cancelled" class="bg-red-50 border-l-4 border-red-400 p-8 ">
      <div class="flex">
        <div class="flex-shrink-0">
          <svg-icon class="h-5 w-5 text-red-400" name="exclamation-circle" />
        </div>
        <div class="ml-3">
          <p class="text-base text-red-800">This {{ decorator.classDefinition.toLowerCase() }} has been
            <strong>cancelled</strong>.
          </p>
        </div>
      </div>
    </div>

    <!-- Redesigned Event Details Card -->
    <div class="bg-white rounded-b-xl shadow-md border border-gray-100 border-t-0">

      <!-- Main Information Section - Horizontal Layout -->
      <div class="p-8 border-t border-gray-100">
        <div class="grid grid-cols-1 md:grid-cols-3 gap-4 items-center">
          <!-- Date & Time Column -->
          <div class="space-y-1">
            <!-- Date -->
            <div class="flex items-center text-lg font-bold text-gray-900">
              <svg-icon class="h-5 w-5 text-loopspark-500 mr-2" name="calendar" />
              <timestamp-to-date :timestamp="singleEvent.start_time" has-day-name natural-date studio />
            </div>
            
            <!-- Time -->
            <div class="flex items-center">
              <svg-icon class="h-5 w-5 text-gray-500 mr-2" name="clock" />
              <div class="flex flex-wrap items-baseline">
                <span class="font-semibold text-gray-800">
                  <timestamp-to-date :has-date="false" :timestamp="singleEvent.start_time" has-time studio />
                </span>
                <svg-icon class="h-4 w-4 mx-1 text-gray-400" name="right" />
                <span class="font-semibold text-gray-800">
                  <timestamp-to-date :has-date="false" :timestamp="singleEvent.end_time" has-time studio />
                </span>
                <!-- Duration -->
                <div class="text-sm text-gray-600 pl-2 italic">
                  {{ durationNumber }} {{ durationUnit }}
                </div>
              </div>
            </div>
          </div>
          
          <!-- Location Column -->
          <div class="flex items-start ">
            <div class="flex-shrink-0 p-2 rounded-md bg-gray-50/70">
              <svg-icon class="h-5 w-5 text-gray-400" name="store" />
            </div>
            <div class="ml-3">
              <p class="text-base font-medium text-gray-700">{{ singleEvent.location.name ?? '-' }}</p>
              <div class="flex items-center space-x-2 mt-1">
                <div class="text-xs font-medium text-gray-500 uppercase tracking-wide">Location</div>
                <button class="text-xs text-blue-500 hover:text-blue-900" @click="$emit('open-virtual-sidebar')">Change to virtual...</button>
              </div>
            </div>
          </div>
          
          <!-- Staff Column -->
          <div v-if="!!singleEvent.staff" class="flex items-start">
            <div class="flex-shrink-0 p-2 rounded-md bg-gray-50 border border-gray-100">
              <svg-icon class="h-5 w-5 text-loopspark-500" name="user" />
            </div>
            <div class="ml-3">
              <p class="text-base font-medium text-gray-800">{{ singleEvent.staff.name }}</p>
              <div v-if="singleEvent.staff.email || singleEvent.staff.mobile_phone"
                class="text-xs text-gray-600 mt-1 flex flex-wrap gap-2">
                <a v-if="singleEvent.staff.email" :href="'mailto:' + singleEvent.staff.email"
                  class="hover:text-loopspark-600 transition-colors">
                  {{ singleEvent.staff.email }}
                </a>
                <a v-if="singleEvent.staff.mobile_phone" :href="'tel:' + singleEvent.staff.mobile_phone"
                  class="hover:text-loopspark-600 transition-colors">
                  {{ phone(singleEvent.staff.mobile_phone) }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Virtual Meeting Section - More Compact -->
      <div v-if="singleEvent.is_virtual" class="px-6 py-3 bg-gray-50 border-t border-gray-200">
        <div class="flex items-center justify-between mb-2">
          <div class="flex items-center">
            <div class="p-1.5 rounded-md bg-blue-50 mr-2">
              <svg-icon class="h-4 w-4 text-blue-500" name="monitor-play-duotone" />
            </div>
            <p class="text-sm font-medium text-gray-800">Virtual Meeting</p>
          </div>
          <div class="flex space-x-2">
            <button
              class="inline-flex items-center justify-center px-2 py-1 text-xs font-medium rounded text-gray-600 hover:text-loopspark-600 transition-colors"
              title="Copy meeting information"
              @click="copyVirtualMeetingInfo">
              <svg-icon class="h-3.5 w-3.5 mr-1" name="save-disk" />
              <span>Copy</span>
            </button>
            <button
              class="inline-flex items-center justify-center px-2 py-1 text-xs font-medium rounded text-gray-600 hover:text-loopspark-600 transition-colors"
              @click="$emit('open-virtual-sidebar')">
              <svg-icon class="h-3.5 w-3.5 mr-1" name="edit" />
              <span>Edit</span>
            </button>
          </div>
        </div>
        
        <!-- Virtual Meeting URL -->
        <div class="flex border border-gray-200 rounded mb-2">
          <div class="w-full">
            <div class="px-3 py-1.5 bg-gray-50 overflow-x-auto break-all">
              <code class="text-xs font-mono">{{ singleEvent.virtual_url }}</code>
            </div>
          </div>
        </div>
        
        <!-- Virtual Meeting Details & Additional Info -->
        <div v-if="singleEvent.virtual_data.virtual_id || singleEvent.virtual_data.virtual_password || singleEvent.virtual_data.virtual_info">
          <button
            class="w-full flex items-center justify-between text-xs text-gray-500 hover:text-gray-700 transition-colors py-1"
            @click="showAdditionalInfo = !showAdditionalInfo">
            <span class="uppercase tracking-wide font-medium">Meeting Details</span>
            <svg-icon :name="showAdditionalInfo ? 'arrow-up' : 'arrow-down'" class="h-3 w-3" />
          </button>
          
          <div v-show="showAdditionalInfo" class="mt-2 space-y-2">
            <!-- ID & Password -->
            <div v-if="singleEvent.virtual_data.virtual_id || singleEvent.virtual_data.virtual_password"
              class="text-xs text-gray-600 flex flex-wrap gap-x-4">
              <span v-if="singleEvent.virtual_data.virtual_id" class="font-mono">
                ID: {{ singleEvent.virtual_data.virtual_id }}
              </span>
              <span v-if="singleEvent.virtual_data.virtual_password" class="font-mono">
                Password: {{ singleEvent.virtual_data.virtual_password }}
              </span>
            </div>
            
            <!-- Additional Info -->
            <div v-if="singleEvent.virtual_data.virtual_info"
              class="text-xs text-gray-600 whitespace-pre-wrap p-2 bg-white rounded border border-gray-100">
              {{ singleEvent.virtual_data.virtual_info }}
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, toRef } from "vue";
import TimestampToDate from "@/components/layout/TimestampToDate.vue";
import { phone } from "@/classes/vue-filters/PhoneFilter";
import SvgIcon from "@/components/SvgIcon.vue";
import { EventDecorator } from "@/composables/decorators/EventDecorator";

interface Props {
  singleEvent: any; // Simplified type since we removed the GraphQL dependency
}

const props = withDefaults(defineProps<Props>(), {});
const emit = defineEmits(["updated", "open-virtual-sidebar"]);

const singleEvent = toRef(props, "singleEvent");
const { computed: decorator } = EventDecorator(singleEvent);

const showAdditionalInfo = ref(false);

const durationNumber = computed(() => {
  const start = new Date(singleEvent.value.start_time * 1000);
  const end = new Date(singleEvent.value.end_time * 1000);
  const diff = end.getTime() - start.getTime();
  const minutes = Math.floor(diff / 1000 / 60);
  const hours = Math.floor(minutes / 60);

  if (hours === 0) {
    return minutes;
  }

  return hours;
});

const durationUnit = computed(() => {
  const start = new Date(singleEvent.value.start_time * 1000);
  const end = new Date(singleEvent.value.end_time * 1000);
  const diff = end.getTime() - start.getTime();
  const minutes = Math.floor(diff / 1000 / 60);
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;

  if (hours === 0) {
    return "minutes";
  }

  return hours === 1
    ? `hour${remainingMinutes > 0 ? ` ${remainingMinutes} min` : ""}`
    : `hours${remainingMinutes > 0 ? ` ${remainingMinutes} min` : ""}`;
});

const copyVirtualMeetingInfo = () => {
  let copyText = `${singleEvent.value.virtual_url}`;

  // Add ID and password if available
  if (singleEvent.value.virtual_data.virtual_id) {
    copyText += `\nID: ${singleEvent.value.virtual_data.virtual_id}`;
  }
  if (singleEvent.value.virtual_data.virtual_password) {
    copyText += `\nPassword: ${singleEvent.value.virtual_data.virtual_password}`;
  }

  // Copy to clipboard
  navigator.clipboard.writeText(copyText).then(() => {
    console.log('Virtual meeting information copied to clipboard');
  }).catch(() => {
    console.error('Failed to copy to clipboard');
  });
};
</script>
