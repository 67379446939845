<template>
    <page-title title="Your Automations" section="Automations" />
    <div class="container mx-auto grow">
        <div>
            <div class="w-full">
                <div class="">
                    <div class="mb-2 flex flex-row text-sm flex-wrap items-center ">
                        <div class="flex grow">
                            <div>
                                <div class="text-gray-700 mr-2">
                                    Medium:
                                    <inline-muted-select v-model="medium" :options="mediumOptions" />
                                </div>

                                <div class="text-gray-700 mr-2">
                                    Type:
                                    <inline-muted-select v-model="automationType" :options="automationTypeOptions" />
                                </div>
                            </div>
                            <div class="ml-4">
                                <div class="text-gray-700 mr-2">
                                    Status:
                                    <inline-muted-select v-model="status" :options="statusOptions" />
                                </div>

                                <div class="text-gray-700 mr-2">
                                    Include Archived:
                                    <inline-muted-select v-model="includeArchived" :options="includeArchivedOptions" />
                                </div>
                            </div>
                            <div class="ml-4">
                                <div v-if="!loading" class="text-gray-700 mr-2">
                                    Filter by Category:
                                    <inline-muted-select v-model="selectedCategory" :max-width="240"
                                        :options="categoryNames" :with-empty="true" option="id" />
                                </div>
                            </div>
                        </div>
                        <div>
                            <input type="text" v-model="filterText" class="form-input form-input-default"
                                placeholder="Search...">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="mt-8">
            <div v-if="loading" class="w-full h-48 flex items-center justify-center">
                <loader class="w-12 h-12 text-gray-500" />
            </div>
            <div v-if="!loading">
                <div v-if="automations && automations.length > 0" class="divide-y divide-blue-100">
                    <automation-index-category v-for="(categoryAndAutomations, index) in automationsGrouped"
                        :key="index" v-model:selected-ids="bulkIds" v-model:bulk-selecting="bulkOpen"
                        :can-edit-automations="canEditAutomations" :force-open="automationsGrouped.length === 1"
                        :include-inactive-count="status === 'Any'" :automations="categoryAndAutomations" class="py-4" />
                </div>

                <div v-if="!shownAutomations.length">
                    <div class="w-full flex items-center justify-center">
                        <div
                            class="w-full md:w-1/2 bg-gray-100 border rounded-md h-32 flex items-center justify-center">
                            <div class="px-4 py-16 text-center">
                                <div class="text-xl text-bold text-gray-600">
                                    Nothing to show!
                                </div>
                                <p class="text-gray-600">
                                    There aren't any automations with the current filters
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <automation-select-confirmer v-model:open="bulkOpen" :items-count="bulkIds.length" :sending="bulkSaving"
            @perform="performBulkRequest" />
    </div>
</template>
<script lang="ts" setup>
import PageTitle from "@/components/navbar/PageTitle.vue";
import { computed, ref } from "vue";
import InlineMutedSelect from "@/components/layout/InlineMutedSelect.vue";
import AutomationIndexCategory from "@/views/Automations/Components/Index/AutomationIndexCategory.vue";
import Loader from "@/components/layout/Loader.vue";
import {
    QLAutomationBulkActionEnum,
    QLAutomationDeliveryTypeEnum, QLFragmentAutomationIndexFragment, useAutomationsIndexQuery
} from "@/graphql/queries/ql/composables";
import AutomationDecorator from "@/composables/decorators/AutomationDecorator";
import {
    echoListener
} from "@/composables/echoListener";
import {
    EventTypes
} from "@/classes/notifications/EventTypes";
import FuzzySearch from "fuzzy-search";
import {
    bulkUpdateAutomationComposable
} from "@/views/Automations/Components/Index/bulkUpdateAutomationComposable";
import AutomationSelectConfirmer from "@/views/Automations/Components/Index/AutomationSelectConfirmer.vue";
import meQuery from "@/composables/queries/meQuery";

const medium = ref("All");
const mediumOptions = [
    { label: "All", value: "All" },
    { label: "Email", value: QLAutomationDeliveryTypeEnum.EMAIL },
    { label: "Text", value: QLAutomationDeliveryTypeEnum.SMS },
    { label: "To-Do", value: QLAutomationDeliveryTypeEnum.TODO },
    { label: "Tag", value: QLAutomationDeliveryTypeEnum.TAG }
];
const automationType = ref(-1);
const automationTypeOptions = [
    { label: "All", value: -1 },
    { label: "Continuous", value: 0 },
    { label: "One Time Only", value: 1 }
];
const status = ref("Any");
const statusOptions = [
    { label: "Active", value: "Active" },
    { label: "Delayed", value: "Delayed" },
    { label: "Any", value: "Any" }
];
const includeArchived = ref(false);
const includeArchivedOptions = [
    { label: "No", value: false },
    { label: "Yes", value: true }
];
const selectedCategory = ref(null);
const filterText = ref("");

const { result, loading, refetch } = useAutomationsIndexQuery({});
const automations = computed(() => result.value?.automations_index ?? []);

echoListener([EventTypes.CommunicationNew], (ev) => {
    refetch();
}, 5000);
const filterCategories = computed(() => {
    return [
        { id: "", value: "All" },
        ...categoryNames.value
    ];
});
const clearCategoryName = (catName: string) => {
    if (!catName || catName === "" || catName.toLowerCase() === "uncategorized") {
        return null;
    }
    return catName;
};
const categoryNames = computed(() => {
    if (!automations.value) {
        return [];
    }
    return [...new Set(automations.value.map(a => clearCategoryName(a.category_name)))].sort((a, b) => {
        if (a === null) {
            return 1;
        }
        if (b === null) {
            return -1;
        }
        return (a.toLowerCase() < b.toLowerCase()) ? -1 : 1;
    }).map((name) => {
        return {
            id: name !== null ? name : "Uncategorized",
            label: name !== null ? name : "Uncategorized"
        };
    });
});

const shownAutomations = computed(() => {
    const searcher = new FuzzySearch(filteredAutomations.value, ["name"], { sort: true });
    return searcher.search(filterText.value);
});

const filteredAutomations = computed((): QLFragmentAutomationIndexFragment[] => {
    if (!automations.value) {
        return [];
    }
    return automations.value.filter((aut) => {
        if (medium.value !== "All") {
            if (aut.delivery_type !== medium.value) {
                return false;
            }
        }
        if (automationType.value !== -1) {
            if (aut.one_off !== (automationType.value === 1)) {
                return false;
            }
        }
        if (status.value !== "Any") {
            if (status.value === "Active" && aut.active === false) {
                return false;
            }
            if (status.value === "Delayed") {
                const dec = AutomationDecorator(aut).computed;
                if (!dec.isDelayed) {
                    return false;
                }
            }
        }
        if (includeArchived.value === false) {
            if (aut.archived) {
                return false;
            }
        }
        if (selectedCategory.value) {
            if (aut.category_name != (selectedCategory.value === "Uncategorized" ? null : selectedCategory.value)) {
                return false;
            }
        }
        return true;
    });
});
const automationsGrouped = computed(() => {
    return categoryNames.value.map((categoryName) => {
        return shownAutomations.value.filter((aut) => aut.category_name === (categoryName.id === "Uncategorized"
            ? null
            : categoryName.id));
    });
});

const { bulkOpen, bulkIds, bulkSaving, sendBulkRequest } = bulkUpdateAutomationComposable();

const performBulkRequest = (i: QLAutomationBulkActionEnum) => {
    sendBulkRequest(i);
};

const { me } = meQuery();
const canEditAutomations = computed(() => {
    if (!me.value) {
        return false;
    }
    return me.value.policy.edit_automations;
});
</script>
