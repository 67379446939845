import Filter from "../../base-filter";

export abstract class ThisEventGroup extends Filter {
	smallText = "This Event";
	group = "ThisEvent";
	path = ["ThisEvent"];

	getDisplayTag(): string[] {
		return ["This Event"];
	}
}

