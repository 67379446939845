import ToDo from "./partials/todo";
import FixedValueOperator, {OperatorAsFixedValueOperator} from "hm-filters/operators/FixedValueOperator";
import GraphQlOperator from "hm-filters/operators/GraphQlOperator";
import QLQueriesData from "hm-filters/operators/GraphQls/QLQueriesData";
import {OperatorTypes, OperatorTypesFullName} from "hm-filters/operators/OperatorTypes";

class ToDoAutomationFilter extends ToDo {
	constructor(name: string, options: any) {
		super(name, options);

		// Add the "manual" option for ToDos not created by automation
		this.operators = [
			new FixedValueOperator("manual", "created manually")
		];

		// For specific automation names, we'll use a GraphQL operator
		// This allows users to select from available automations
		this.operators.push(
			new ToDoAutomationQlOperator("=", "created by automation").setIsMultipleChoice()
		);
	}

	get type(): string {
		return "ToDoAutomationFilter";
	}
}

export default ToDoAutomationFilter;

export class ToDoAutomationQlOperator extends GraphQlOperator {
	graphQLData = QLQueriesData.CommunicationReceivedQlOperator;

	get type(): string {
		return OperatorTypes.GraphQlMultiple;
	}

	get fullType(): string {
		return OperatorTypesFullName.CommunicationReceivedQlOperator;
	}
}