<template>
  <page-title title="Currently Muted Clients" sub-text="Temporarily pause communications with clients." />
  <div class="container mx-auto grow">

    <div class="bg-white shadow overflow-hidden sm:rounded-md">
      <div v-if="loading" class="p-4 flex justify-center">
        <loader class="w-8 h-8" />
      </div>
      <div v-else-if="error" class="p-4 text-center text-red-500">
        {{ error }}
      </div>
      <div v-else-if="mutes.length === 0" class="p-4 text-center text-gray-500">
        No clients are currently muted.
      </div>
      <div v-else>
        <table class="min-w-full divide-y divide-gray-200">
          <thead class="bg-gray-50">
          <tr>
            <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider" scope="col">
              Client Name
            </th>
            <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider" scope="col">
              Email
            </th>
            <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider" scope="col">
              Phone
            </th>
            <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider" scope="col">
              Muted By
            </th>
            <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider" scope="col">
              Muted At
            </th>
            <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider" scope="col">
              Muted Until
            </th>
            <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider" scope="col">
              Actions
            </th>
          </tr>
          </thead>
          <tbody class="bg-white divide-y divide-gray-200">
          <tr v-for="mute in mutes" :key="mute.id">
            <td class="px-6 py-4 whitespace-nowrap">
              <div class="flex items-center">
                <div class="ml-4">
                  <div class="text-sm font-medium text-gray-900">
                    <router-link
                        :to="{ name: 'client.show', params: { id: mute.client.id } }"
                        class="text-indigo-600 hover:text-indigo-900">
                      {{ mute.client.first_name }} {{ mute.client.last_name }}
                    </router-link>
                  </div>
                </div>
              </div>
            </td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              {{ mute.client.email || "N/A" }}
            </td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              {{ mute.client.mobile_phone || "N/A" }}
            </td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              {{ mute.muted_by?.name || "N/A" }}
            </td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              <timestamp-to-date :timestamp="mute.muted_by" has-date has-time studio></timestamp-to-date>
            </td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
             <timestamp-to-date :timestamp="mute.muted_until" has-date has-time studio></timestamp-to-date>
            </td>
            <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
              <button
                  class="text-indigo-600 hover:text-indigo-900"
                  @click="confirmUnmute(mute)">
                Unmute
              </button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- Unmute Confirmation Modal -->
    <confirm-modal
        v-model:open="showUnmuteModal"
        :loading="unmuting"
        close-text="Cancel"
        title="Unmute Client?"
        type="warning"
        @clicked="handleUnmute">
      <template #text>
        <p v-if="selectedMute">
          Are you sure you want to unmute {{ selectedMute.client.first_name }} {{ selectedMute.client.last_name }}?
        </p>
        <p>
          This client will start receiving messages again.
        </p>
      </template>
      <template #button>
        Unmute Client
      </template>
    </confirm-modal>
  </div>
</template>

<script lang="ts" setup>
import { useCurrentlyMutedClientsQuery, useUnmuteClientMutation } from "@/graphql/queries/ql/composables";
import { ref, watch }                                             from "vue";
import Loader                                                     from "@/components/layout/Loader.vue";
import ConfirmModal                                               from "@/components/layout/ConfirmModal.vue";
import dayjs                                                      from "dayjs";
import $toast                                                     from "@/composables/toast";
import PageTitle                                                  from "@/components/navbar/PageTitle.vue";
import TimestampToDate                                            from "@/components/layout/TimestampToDate.vue";

const loading = ref(true);
const error = ref("");
const mutes = ref<any[]>([]);
const showUnmuteModal = ref(false);
const selectedMute = ref<any>(null);
const unmuting = ref(false);

// Fetch currently muted clients
const mutedClientsQuery = useCurrentlyMutedClientsQuery();

watch(
    () => mutedClientsQuery.result.value,
    (newResult) => {
      loading.value = mutedClientsQuery.loading.value;

      if (newResult?.currentlyMutedClients) {
        if (newResult.currentlyMutedClients.success) {
          mutes.value = newResult.currentlyMutedClients.mutes || [];
        } else {
          error.value = "Failed to load muted clients";
        }
      }
    },
    { immediate: true }
);

watch(
    () => mutedClientsQuery.error.value,
    (newError) => {
      if (newError) {
        error.value = `Error: ${ newError.message }`;
        loading.value = false;
      }
    }
);

// Unmute client mutation
const unmuteMutation = useUnmuteClientMutation({});

unmuteMutation.onDone(({ data }) => {
  unmuting.value = false;
  if (data?.unmuteClient?.success) {
    $toast({
             type:         "success",
             message:      `Successfully unmuted client`,
             linkRouteObj: null,
             linkText:     null
           });
    showUnmuteModal.value = false;
    mutedClientsQuery.refetch(); // Refresh the list
  } else {
    $toast({
             type:         "error",
             message:      data?.unmuteClient?.message || "Failed to unmute client",
             linkRouteObj: null,
             linkText:     null
           });
  }
});

unmuteMutation.onError((error) => {
  unmuting.value = false;
  $toast({
           type:         "error",
           message:      `Error unmuting client: ${ error.message }`,
           linkRouteObj: null,
           linkText:     null
         });
});

const confirmUnmute = (mute: any) => {
  selectedMute.value = mute;
  showUnmuteModal.value = true;
};

const handleUnmute = () => {
  if (!selectedMute.value) {
    return;
  }

  unmuting.value = true;
  unmuteMutation.mutate({
                          client_id: selectedMute.value.client.id
                        });
};

const formatDate = (dateString: string) => {
  if (!dateString) {
    return "N/A";
  }
  return dayjs(dateString).format('MMM D, YYYY h:mm A');
};
</script>
