<template>
  <div class="flex flex-col space-y-3">
    <div class="grid grid-cols-2 gap-3">
      <!-- AND Option -->
      <div
        :class="[
          'relative flex rounded-lg border p-4 cursor-pointer',
          modelValue ? 'border-blue-600 bg-blue-50' : 'border-gray-300',
          { 'opacity-50 cursor-not-allowed': !canSelectAnd }
        ]"
        @click="updateLogic(true)"
      >
        <div class="flex w-full">
          <div class="flex h-5 items-center">
            <input
                id="and-option"
                :checked="modelValue"
                :disabled="!canSelectAnd"
                class="h-4 w-4 border-gray-300 text-blue-600 focus:ring-blue-600"
                name="logic-option"
                type="radio"
                @change="updateLogic(true)"
            />
          </div>
          <div class="ml-3 flex flex-col">
            <label class="font-medium text-gray-900" for="and-option">Match All (AND)</label>
            <p class="text-xs text-gray-500">All filters must match</p>
          </div>

        </div>
        <div
            v-if="modelValue"
            aria-hidden="true"
            class="absolute inset-0 rounded-lg ring-2 ring-blue-600 pointer-events-none"
        ></div>
        <svg-icon
            :class="modelValue ? 'opacity-100' : 'opacity-0'"
            class="absolute right-3 top-3 h-5 w-5 text-blue-600 transition-opacity"
            name="check"
        />
      </div>

      <!-- OR Option -->
      <div
        :class="[
          'relative flex rounded-lg border p-4 cursor-pointer',
          !modelValue ? 'border-blue-600 bg-blue-50' : 'border-gray-300',
          { 'opacity-50 cursor-not-allowed': singleFilter }
        ]"
        @click="updateLogic(false)"
      >
        <div class="flex w-full">
          <div class="flex h-5 items-center">
            <input
                id="or-option"
                :checked="!modelValue"
                :disabled="singleFilter"
                class="h-4 w-4 border-gray-300 text-blue-600 focus:ring-blue-600"
                name="logic-option"
                type="radio"
                @change="updateLogic(false)"
            />
          </div>
          <div class="ml-3 flex flex-col">
            <label class="font-medium text-gray-900" for="or-option">Match Any (OR)</label>
            <p class="text-xs text-gray-500">Any filter can match</p>
          </div>
          <!-- Removed tooltip for single filter case -->
        </div>
        <div
            v-if="!modelValue"
            aria-hidden="true"
            class="absolute inset-0 rounded-lg ring-2 ring-blue-600 pointer-events-none"
        ></div>
        <svg-icon
            :class="!modelValue ? 'opacity-100' : 'opacity-0'"
            class="absolute right-3 top-3 h-5 w-5 text-blue-600 transition-opacity"
            name="check"
        />
      </div>
    </div>

    <!-- Help Text -->
    <div>
      <p v-if="!canSelectAnd && !modelValue" class="text-amber-600 text-sm">
        OR logic is required when filters are from different categories
      </p>
      <!-- Removed warning about OR logic requiring at least two filters -->
      <p v-else class="text-sm text-gray-500">
        {{ helpText }}
      </p>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import SvgIcon      from "@/components/SvgIcon.vue";

interface Props {
  modelValue: boolean;
  canSelectAnd: boolean;
  singleFilter?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  singleFilter: false
});
const emit = defineEmits(['update:modelValue']);

const helpText = computed(() => {
  if (props.modelValue) {
    return 'All filters in this rule must match for a client to be included';
  }
  return 'A client will be included if they match any of the filters in this rule';
});

const updateLogic = (value: boolean) => {
  if (value && !props.canSelectAnd) return;
  if (!value && props.singleFilter) {
    return;
  }
  emit('update:modelValue', value);
};
</script>