<template>
  <page-title title="Note Templates" section="Settings" />
  <div class="container mx-auto grow space-y-16">

    <div class="split-view-left">
      <div class="pb-4 md:pb-0 md:w-1/3">
        <div class="text-lg font-medium text-gray-900">
          Current Note Templates
        </div>
        <div class="mt-1 text-gray-600 text-sm">
          <div class="space-y-4 ">
            <p>
              Save time and standardize your team's note-taking with reusable Note Templates.
            </p>
            <p>
              Note Templates will be available in a dropdown menu when creating or editing client notes,
              allowing staff to quickly insert pre-written content for common note types.
            </p>
            <p>
              <strong class="font-semibold">Merge Tags</strong>: Include personalized merge tags such as
              <span
                  v-pre
                  class="badge-tag font-medium">{{ FIRST_NAME }}</span> in your templates. A list of
                                                               available merge tags is provided below the content
                                                               section.
            </p>
          </div>
        </div>
      </div>
      <div class="flex-1 block space-y-6">
        <studio-note-templates-list @template-deleted="handleTemplateDeleted" />
        <studio-note-templates-create />
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import PageTitle                                             from "@/components/navbar/PageTitle.vue";
import StudioNoteTemplatesList   from "@/views/Studio/Settings/NoteTemplate/StudioNoteTemplatesList.vue";
import StudioNoteTemplatesCreate from "@/views/Studio/Settings/NoteTemplate/StudioNoteTemplatesCreate.vue";
import { useNoteTemplatesQuery } from "@/graphql/queries/ql/composables";

const noteTemplatesQuery = useNoteTemplatesQuery();

const handleTemplateDeleted = () => {
  // Refresh the templates list
  noteTemplatesQuery.refetch();
};

</script>