<template>
  <div class="flex flex-col">
    <QuillEditor
        v-if="mounted"
        ref="editor"
        v-model:content="editorValue"
        :placeholder="placeholder"
        :read-only="disabled"
        :toolbar="toolBarOptions"
        class="grow"
        content-type="html"
        theme="snow" />
  </div>
</template>
<script lang="ts">
export default {
  name: "RichEditor"
};
</script>
<script lang="ts" setup>
// noinspection ES6UnusedImports
import { QuillEditor } from "@vueup/vue-quill";

import { computed, onMounted, ref, toRef, watch } from "vue";

interface Props {
  placeholder?: string;
  disabled?: boolean;
  modelValue?: string | null;
}

const props = withDefaults(defineProps<Props>(), {
  placeholder: "",
  disabled:    false
});

const placeholder = toRef(props, "placeholder");
const disabled = toRef(props, "disabled");

const emit = defineEmits([ "update:modelValue" ]);

const toolBarOptions = [
  [ { "font": [] }, { "size": [ "small", false, "large", "huge" ] } ],
  [ "bold", "italic", "underline", "strike" ],        // toggled buttons
  [ "blockquote", "link" ],

  [ { "list": "ordered" }, { "list": "bullet" } ],
  [ { "indent": "-1" }, { "indent": "+1" } ],          // outdent/indent

  [ { "color": [] }, { "background": [] } ],          // dropdown with defaults from theme

  [ { "align": [] } ],

  [ "clean" ]                                         // remove formatting button
];

const editor = ref(null);

const modelValue = toRef(props, "modelValue");
// watch(modelValue, () => {
//   editorValue.value;
//   editor.value.setContents(modelValue.value + "\n");
// })

const mounted = ref(false);

const editorValue = computed({
                               get: () => {
                                 return modelValue.value;
                               },
                               set: (val) => {
                                 emit("update:modelValue", val);
                               }
                             });

onMounted(() => {
  mounted.value = true;
});

const insertText = (text: string) => {
  editor.value.setContents(modelValue.value + text);
};

defineExpose({
               insertText
             });

</script>
