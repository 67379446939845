import Routes                from "@/router/Routes";
import UserSettings          from "@/views/user/settings/UserSettings.vue";
import Discussions           from "@/views/Discussions.vue";
import Todos                 from "@/views/Todos.vue";
import {ClientRoutes}        from "@/router/client";
import {StudioRoutes}        from "@/router/studio";
import {EventRoutes}         from "@/router/events";
import {ClientsRoutes}       from "@/router/clients";
import {AutomationsRoutes}   from "@/router/automations";
import DashboardSkeleton     from "@/views/DashboardSkeleton.vue";
import Dashboard             from "@/views/Dashboard.vue";
import Redirecter            from "@/components/layout/Redirecter.vue";
import Bus                   from "@/classes/utils/Bus";
import {UserRoutes}          from "@/router/user";
import * as VueRouter        from "vue-router";
import {FormsRoutes}         from "@/router/forms";
import {CurrentUserInstance} from "@/classes/state/CurrentUserInstance";
import {InsightsRoutes}      from "@/router/insights";

const BusVue = Bus;

// let redirectCount = 0;

export const AllRoutes: VueRouter.RouteRecordRaw[] = [

	{
		path:        "/login",
		name:        Routes.login,
		component:   Redirecter,
		props:       {url: import.meta.env.VITE_APP_MAIN_URL + "/login"},
		beforeEnter: (to, from) => {
			window.location.replace(import.meta.env.VITE_APP_MAIN_URL + "/login");
			return false;
		}
	},
	{
		path:        "/logout",
		name:        Routes.logout,
		component:   Redirecter,
		props:       {url: import.meta.env.VITE_APP_MAIN_URL + "/logout"},
		beforeEnter: (to, from) => {
			CurrentUserInstance.getInstance().logout();
			window.location.replace(import.meta.env.VITE_APP_MAIN_URL + "/logout");
			return false;
		}
	},
	...UserRoutes,
	...StudioRoutes,
	{
		path:      "/",
		component: DashboardSkeleton,
		meta:      {
			requiresAuth:    true,
			requiresTeam:    true,
			billingRequired: true
		},
		children:  [
			{
				path:      "",
				name:      Routes.dashboard,
				component: Dashboard,
				meta:      {
					title: "Dashboard"
				}
			},
			{
				path:      "/user/settings",
				name:      Routes.user.settings,
				component: UserSettings,
				meta:      {
					title: "User Settings"
				}
			},
			{
				path:      "/discussions",
				name:      Routes.discussions,
				component: Discussions,
				meta:      {
					title: "Discussions"
				}
			},
			{
				path:      "/todos",
				name:      Routes.todos,
				component: Todos,
				meta:      {
					title: "ToDos"
				}
			},
			...ClientRoutes,
			...EventRoutes,
			...ClientsRoutes,
			...AutomationsRoutes,
			...FormsRoutes,
			...InsightsRoutes
		]
	}

];

