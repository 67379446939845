import StaffValues                                                                                                       from "./filters/staffValues";
import {AbsentFilter, EarlyCancelFilter, LateCancelFilter, SignedUpFilter, UpcomingVisitFilter, VisitFilter}             from "./filters/partials/visit-history-filters";
import {FirstLateCancelFilter, FirstVisitFilter}                                                                         from "./filters/partials/visit-first-filters";
import {AbsentCount, EarlyCancelCount, LateCancelCount, UpcomingVisitCount, VisitCount}                                  from "./filters/partials/visit-count-filters";
import ClassValues                                                                                                       from "./filters/classValuesFilter";
import FilterGrouper, {GroupedFilter}                                                                                    from "./filterGrouper";
import {AbsentLocation, EarlyCancelLocation, LateCancelLocation, SignedUpLocation, UpcomingVisitLocation, VisitLocation} from "./filters/partials/visit-location-filters";
import ClassTimesFilter                                                                                                  from "./filters/classTimesFilter";
import MultipleTextOperator                                                                                              from "hm-filters/operators/MultipleTextOperator";
import FixedValueOperator                                                                                                from "hm-filters/operators/FixedValueOperator";
import Filter                                                                                                            from "hm-filters/base-filter";
import {AbsentPackageUsed, EarlyCancelPackageUsed, LateCancelPackageUsed, UpcomingVisitPackageUsed, VisitPackageUsed}    from "hm-filters/filters/partials/visit-using-package-filters";
import {AppointmentCount, AppointmentNoShowCount, AppointmentUpcomingCount}                                              from "hm-filters/filters/partials/appointments-count-filters";
import {AppointmentFilter, AppointmentNoShowFilter, AppointmentUpcomingFilter}                                           from "hm-filters/filters/partials/appointments-history-filters";
import {AppointmentLocation, AppointmentNoShowLocation, AppointmentUpcomingLocation}                                     from "hm-filters/filters/partials/appointments-location-filters";
import {AppointmentNoShowPackageUsed, AppointmentPackageUsed, AppointmentUpcomingPackageUsed}                            from "hm-filters/filters/partials/appointments-using-package-filters";
import AppointmentStaffValues                                                                                            from "hm-filters/filters/AppointmentStaffValues";
import AppointmentTimesFilter                                                                                            from "hm-filters/filters/AppointmentTimesFilter";
import ClassDayOfWeekFilter                                                                                              from "hm-filters/filters/ClassDayOfWeekFilter";
import StaffNameQlOperator                                                                                               from "hm-filters/operators/GraphQls/StaffNameQlOperator";
import ClassNameQlOperator                                                                                               from "hm-filters/operators/GraphQls/ClassNameQlOperator";
import AppointmentNameQlOperator from "hm-filters/operators/GraphQls/AppointmentNameQlOperator";
import AppointmentNameFilter     from "hm-filters/filters/AppointmentNameFilter";
import FiltersByVisibility       from "hm-filters/filtersByVisibility";
import {ClientFilters}           from "hm-filters/filtersByCategory/ClientFilters";
import {BookingFilters}          from "hm-filters/filtersByCategory/BookingFilters";
import {SaleFilters}             from "hm-filters/filtersByCategory/SaleFilters";
import {CalendarFilters}         from "hm-filters/filtersByCategory/CalendarFilters";
import {CommunicationFilters}    from "hm-filters/filtersByCategory/CommunicationFilters";
import {ToDoFilters}             from "hm-filters/filtersByCategory/ToDoFilters";
import {ContractFilters}         from "hm-filters/filtersByCategory/ContractFilters";
import {PackageFilters}          from "hm-filters/filtersByCategory/PackageFilters";
import {ProductFilters}          from "hm-filters/filtersByCategory/ProductFilters";
import {FormFilters}             from "hm-filters/filtersByCategory/FormFilters";
import { ThisEventBookingFilters } from "hm-filters/filtersByCategory/ThisEventBookingFilters";


class Filters {
	private static instance: Filters;
	_filtersByProvider: { filters: Filter[], providerId: number }[];

	providers = [1, 2, 3, 4];

	private constructor() {
		this._filters = [

			...ClientFilters,
			...BookingFilters,
			...SaleFilters,
			...CalendarFilters,
			...CommunicationFilters,
			...ToDoFilters,
			...ContractFilters,
			...PackageFilters,
			...ProductFilters,
			...FormFilters,
			...ThisEventBookingFilters,


			// Signed Visits
			new FirstVisitFilter("", {"field": "first_visit"}),
			new VisitFilter("", {"field": "visit"}),
			new VisitCount("", {"field": "visit_count"}),
			new VisitLocation("", {"field": "visit_location"}),
			new VisitPackageUsed("", {"field": "visit_package_used"}),
			// Late Cancel Visits
			new FirstLateCancelFilter("", {"field": "late_first_visit"}),
			new LateCancelFilter("", {"field": "late_visit"}),
			new LateCancelCount("", {"field": "late_count"}),
			new LateCancelLocation("", {"field": "late_location"}),
			new LateCancelPackageUsed("", {"field": "late_package_used"}),
			// Upcoming visits
			new UpcomingVisitFilter("", {"field": "upcoming_visit"}),
			new UpcomingVisitCount("", {"field": "upcoming_count"}),
			new UpcomingVisitLocation("", {"field": "upcoming_location"}),
			new UpcomingVisitPackageUsed("", {"field": "upcoming_package_used"}),
			// Signed up visits
			new SignedUpFilter("", {"field": "signed_up_visits"}),
			new SignedUpLocation("", {"field": "signed_up_location"}),
			// Absent visits
			new AbsentFilter("", {"field": "absent_visit"}),
			new AbsentCount("", {"field": "absent_count"}),
			new AbsentLocation("", {"field": "absent_location"}),
			new AbsentPackageUsed("", {"field": "absent_package_used"}),

			// Early Cancel visits
			new EarlyCancelFilter("", {"field": "early_cancel_visit"}),
			new EarlyCancelCount("", {"field": "early_cancel_count"}),
			new EarlyCancelLocation("", {"field": "early_cancel_location"}),
			new EarlyCancelPackageUsed("", {"field": "early_cancel_package_used"}),

			// Class
			new ClassValues("Type", {"field": "name"}).setOperators([
				                                                        new ClassNameQlOperator(
					                                                        "=",
					                                                        "is").setIsMultipleChoice(),
				                                                        new ClassNameQlOperator(
					                                                        "only",
					                                                        "only").setAloneInFilterGroup(),
				                                                        new MultipleTextOperator(
					                                                        "contains",
					                                                        "contains")
			                                                        ]),

			new ClassValues("Cancelled", {"field": "cancelled"}).setOperators([
				                                                                  new FixedValueOperator(
					                                                                  "true",
					                                                                  "is cancelled"),
				                                                                  new FixedValueOperator(
					                                                                  "false",
					                                                                  "is not cancelled")
			                                                                  ])
			                                                    .hideOnClubready(),

			new ClassValues("Is Virtual/Streaming", {"field": "is_virtual"}).setOperators([
				                                                                              new FixedValueOperator(
					                                                                              true,
					                                                                              "yes"),
				                                                                              new FixedValueOperator(
					                                                                              false,
					                                                                              "no")
			                                                                              ]),

			new ClassTimesFilter("Starts", {"field": "start"}),
			new ClassTimesFilter("Ends", {"field": "end"}),
			new ClassDayOfWeekFilter("Day of Week", {"field": "weekday"}),


			// Staff
			new StaffValues("Name", {"field": "name"}).setOperators([

				                                                        new StaffNameQlOperator(
					                                                        "=",
					                                                        "is").setIsMultipleChoice(),
				                                                        new StaffNameQlOperator(
					                                                        "!=",
					                                                        "is not").setIsMultipleChoice()
			                                                        ]),


			// Appointments
			new AppointmentCount("", {"field": "appointment_count"}),
			new AppointmentNoShowCount("", {"field": "appointment_no_show_count"}),
			new AppointmentUpcomingCount("", {"field": "appointment_upcoming_count"}),

			new AppointmentFilter("", {"field": "appointment_filter"}),
			new AppointmentNoShowFilter("", {"field": "appointment_no_show_filter"}),
			new AppointmentUpcomingFilter("", {"field": "appointment_upcoming_filter"}),

			new AppointmentLocation("", {"field": "appointment_location"}),
			new AppointmentNoShowLocation("", {"field": "appointment_no_show_location"}),
			new AppointmentUpcomingLocation("",
			                                {"field": "appointment_upcoming_location"}),

			new AppointmentPackageUsed("", {"field": "appointment_package_used"}),
			new AppointmentNoShowPackageUsed("",
			                                 {"field": "appointment_no_show_package_used"}),
			new AppointmentUpcomingPackageUsed("",
			                                   {"field": "appointment_upcoming_package_used"}),
			new AppointmentStaffValues("Name", {"field": "name"}).setOperators([
				                                                                   new StaffNameQlOperator(
					                                                                   "=",
					                                                                   "is").setIsMultipleChoice(),
				                                                                   new StaffNameQlOperator(
					                                                                   "!=",
					                                                                   "is not").setIsMultipleChoice()
			                                                                   ]),

			new AppointmentTimesFilter("Starts", {"field": "starts_at"}),
			new AppointmentTimesFilter("Ends", {"field": "ends_at"}),
			// Class
			new AppointmentNameFilter("Type", {"field": "name"}).setOperators([
				                                                                  new AppointmentNameQlOperator(
					                                                                  "=",
					                                                                  "is").setIsMultipleChoice(),
				                                                                  new AppointmentNameQlOperator(
					                                                                  "only",
					                                                                  "only").setAloneInFilterGroup(),
				                                                                  new MultipleTextOperator(
					                                                                  "contains",
					                                                                  "contains")
			                                                                  ]).hideOnClubready(),

			new AppointmentNameFilter("Is Virtual/Streaming", {"field": "is_virtual"}).setOperators([
				                                                                                        new FixedValueOperator(
					                                                                                        true,
					                                                                                        "yes"),
				                                                                                        new FixedValueOperator(
					                                                                                        false,
					                                                                                        "no")
			                                                                                        ])


		];
		this._filtersByProvider = [];
	}

	_filters: Filter[];

	get filters(): Filter[] {
		return this._filters;
	}


	static getInstance(): Filters {
		if (!Filters.instance) {
			Filters.instance = new Filters();
		}

		return Filters.instance;
	}

	byProvider(providerId: number) {
		const found = this._filtersByProvider.find(filter => filter.providerId === providerId);
		if (!found){
			const byProv = {
				providerId: providerId,
				filters:    FiltersByVisibility.filtersFor(this._filters,
				                                           providerId),
			}
			this._filtersByProvider.push(byProv);
			return byProv;
		}

		return found;
	}

}

export default Filters;
