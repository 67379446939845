<template>
  <div class="p-2">
    <div class="font-medium">
      {{ item.title }} <span
        v-if="!item.optional"
        class="text-red-800">*</span>
    </div>
    <div class="text-sm text-gray-800">
      {{ item.description }}
    </div>
    <div class="mt-1">
      <input
          class="form-input"
          type="date">
    </div>
  </div>
</template>
<script lang="ts" setup>
import { QLFormField } from "@/graphql/queries/ql/composables";
import { toRef }       from "vue";

interface Props {
  item: QLFormField;
}

const props = withDefaults(defineProps<Props>(), {});

const emit = defineEmits([]);

const item = toRef(props, "item");

</script>
