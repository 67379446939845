<template>
  <div
    class="bg-white rounded-xl border border-gray-100 shadow-sm hover:shadow-md transition-all duration-200 overflow-hidden group">
    <div class="p-5 flex flex-col h-full">
      <!-- Member Info -->
      <div class="flex items-start space-x-4 mb-4">
        <!-- Avatar -->
        <div class="relative flex-shrink-0">
          <client-avatar :client="visit.client" class="w-12 h-12" />
        </div>

        <!-- Info -->
        <div class="min-w-0 flex-1">
          <router-link :to="{ name: routeList.client.show, params: { id: visit.client.id } }">
            <h4 class="text-base font-semibold text-gray-900 hover:text-blue-600 transition-colors truncate leading-6">
              {{ visit.client.first_name }} {{ visit.client.last_name }}
            </h4>
          </router-link>

          <p class="text-sm text-gray-600 truncate" v-text="!!visit.package ? visit.package.name : '-'"></p>
          <div class="mt-2.5">
            <MemberVisitStatusBadge :single-event="singleEvent" :visit="visit" class="py-0" />
          </div>
        </div>
      </div>

      <!-- Stats Grid -->
      <div class="grid grid-cols-3 gap-2 mt-2">
        <router-link :to="{ name: routeList.client.events.index, params: { id: visit.client.id } }" class="block">
          <div class="text-center p-2 rounded-lg bg-gray-50 hover:bg-gray-100">
            <div class="text-base font-bold text-gray-700">
              {{
                visit.client.total_period_counts > 0 ?
                  visit.client.total_period_counts :
                  visit.client.total_visits
              }}
            </div>
            <div class="text-xs font-medium text-gray-500 uppercase tracking-wide">Visits</div>
          </div>
        </router-link>
        <router-link :to="{ name: routeList.client.todos.index, params: { id: visit.client.id } }" class="block">
          <div :class="{ 'bg-amber-50 hover:bg-amber-100': visit.client.total_todos > 0, 'bg-gray-50 hover:bg-gray-100': !visit.client.total_todos }"
            class="text-center p-2 rounded-lg">
            <div :class="{ 'text-amber-700': visit.client.total_todos > 0, 'text-gray-700': !visit.client.total_todos }"
              class="text-base font-bold">
              {{ visit.client.total_todos || 0 }}
            </div>
            <div :class="{ 'text-amber-600': visit.client.total_todos > 0, 'text-gray-500': !visit.client.total_todos }"
              class="text-xs font-medium uppercase tracking-wide">
              ToDos
            </div>
          </div>
        </router-link>
        <router-link :to="{ name: routeList.client.notes.index, params: { id: visit.client.id } }" class="block">
          <div :class="{ 'bg-blue-50 hover:bg-blue-100': visit.client.total_notes > 0, 'bg-gray-50 hover:bg-gray-100': !visit.client.total_notes }"
            class="text-center p-2 rounded-lg">
            <div :class="{ 'text-blue-700': visit.client.total_notes > 0, 'text-gray-700': !visit.client.total_notes }"
              class="text-base font-bold">
              {{ visit.client.total_notes || 0 }}
            </div>
            <div :class="{ 'text-blue-600': visit.client.total_notes > 0, 'text-gray-500': !visit.client.total_notes }"
              class="text-xs font-medium uppercase tracking-wide">
              Notes
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { QLFragmentVisitWithClientAndPackageFragment, QLSingleClassFragmentFragment } from "@/graphql/queries/ql/composables";
import { toRef } from "vue";
import { VisitDecorator } from "@/composables/decorators/VisitDecorator";
import routesList from "@/composables/routesList";
import ClientAvatar from "@/components/client/ClientAvatar.vue";
import MemberVisitStatusBadge from "@/components/MemberVisitStatusBadge.vue";

const props = defineProps<{
  singleEvent: QLSingleClassFragmentFragment;
  visit: QLFragmentVisitWithClientAndPackageFragment;
}>();

const emit = defineEmits([]);

const singleEvent = toRef(props, "singleEvent");
const visit = toRef(props, "visit");
const { routeList } = routesList();

const { computed: decorator } = VisitDecorator(visit, singleEvent);
</script>
