import ToDo from "./partials/todo";
import FixedValueOperator from "hm-filters/operators/FixedValueOperator";

class ToDoStatusFilter extends ToDo {
	constructor(name: string, options: any) {
		super(name, options);

		this.operators = [
			new FixedValueOperator("Open", "is open"),
			new FixedValueOperator("Overdue", "is overdue"),
			new FixedValueOperator("Scheduled", "is scheduled"),
			new FixedValueOperator("Completed", "is completed"),
			new FixedValueOperator("Completed/InPerson", "completed in person"),
			new FixedValueOperator("Completed/Called", "completed via call"),
			new FixedValueOperator("Completed/Texted", "completed via text"),
			new FixedValueOperator("Completed/Other", "completed via other means"),
			new FixedValueOperator("Deleted", "is deleted"),
			new FixedValueOperator("no_open", "has no open ToDos").setAloneInFilterGroup(),
			new FixedValueOperator("no_overdue", "has no overdue ToDos").setAloneInFilterGroup(),
			new FixedValueOperator("no_scheduled", "has no scheduled ToDos").setAloneInFilterGroup()
		];
	}

	get type(): string {
		return "ToDoStatusFilter";
	}
}

export default ToDoStatusFilter;