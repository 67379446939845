<template>
  <div>
    <!-- Header section remains unchanged -->
    <div class="flex justify-between items-center mb-6">
      <div class="flex items-center gap-3">
        <div class="text-gray-700 font-medium tracking-wide">
          Event insight segments
        </div>
        <div v-if="isSaving" class="flex items-center gap-2 text-sm text-blue-600">
          <loader class="w-4 h-4" />
          <span>Saving...</span>
        </div>
        <transition mode="out-in" name="fade">
          <div v-if="showSaved" class="flex items-center gap-2 text-sm text-green-600">
            <svg-icon class="w-4 h-4" name="check" />
            <span>Saved!</span>
          </div>
        </transition>
      </div>
      <v-button
          v-if="allSegments.length > 0"
          :disabled="isSaving"
          class="btn btn-loopspark"
          @click="openCreateModal">
        <div class="flex items-center">
          <svg-icon
              class="w-4 h-4 mr-2"
              name="plus" />
          Create new segment
        </div>
      </v-button>
    </div>

    <!-- Loading state remains unchanged -->
    <div
        v-if="loading"
        class="flex-1">
      <div class="w-full h-32 flex items-center justify-center">
        <loader class="w-12 h-12 text-gray-700 animate-pulse" />
      </div>
    </div>

    <div v-else>
      <!-- Empty state remains unchanged -->
      <div v-if="allSegments.length === 0" class="text-center py-12 bg-gray-50 rounded-lg">
        <p class="text-gray-600 mb-4">You don't have any event insight segments yet.</p>
        <v-button
            :disabled="isSaving"
            class="btn btn-loopspark"
            @click="openCreateModal">
          <div class="flex items-center">
            <svg-icon
                class="w-4 h-4 mr-2"
                name="plus" />
            Create new segment
          </div>
        </v-button>
      </div>

      <div v-else>
        <!-- Active Segments Table -->
        <div class="overflow-x-auto mb-6 rounded-lg border border-gray-200">
          <table class="min-w-full divide-y divide-gray-200">
            <thead>
            <tr class="bg-gray-50">
              <th class="w-12 px-4 py-3.5 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                <span class="sr-only">Order</span>
              </th>
              <th class="px-6 py-3.5 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Segment name
              </th>
              <th class="px-6 py-3.5 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                Highlighted
              </th>
              <th class="px-6 py-3.5 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                Actions
              </th>
            </tr>
            </thead>
            <draggable
                v-model="activeSegments"
                :disabled="isSaving"
                :item-key="'id'"
                class="bg-white divide-y divide-gray-200"
                handle=".drag-handle"
                tag="tbody">
              <template #item="{ element: segment }">
                <tr class="hover:bg-gray-50 transition-colors duration-150">
                  <td class="w-12 px-4 py-4 whitespace-nowrap">
                    <div class="drag-handle cursor-move">
                      <svg-icon
                          class="w-5 h-5 text-gray-400 hover:text-gray-600 transition-colors duration-150"
                          name="menu" />
                    </div>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {{ segment.name }}
                  </td>
                  <td class="px-6 py-4">
                    <div class="flex justify-center">
                      <label class="inline-flex items-center">
                        <input
                            :checked="segment.visible_dashboard"
                            :disabled="isSaving"
                            class="form-checkbox h-4 w-4 text-blue-600 transition duration-150 ease-in-out disabled:opacity-50"
                            type="checkbox"
                            @change="toggleHighlighted(segment)">
                        <span class="ml-2 text-sm text-gray-600">Highlighted</span>
                      </label>
                    </div>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-right text-sm space-x-4">
                    <button
                        :disabled="isSaving"
                        class="text-blue-700 hover:text-blue-900 font-medium transition-colors duration-150 disabled:opacity-50 disabled:hover:text-blue-700"
                        @click="openEditModal(segment)">
                      Edit
                    </button>
                    <button
                        :disabled="isSaving"
                        class="text-red-700 hover:text-red-900 font-medium transition-colors duration-150 disabled:opacity-50 disabled:hover:text-red-700"
                        @click="archiveSegment(segment)">
                      Archive
                    </button>
                  </td>
                </tr>
              </template>
            </draggable>
          </table>
        </div>

        <!-- Archived Segments Section -->
        <div v-if="archivedSegments.length > 0" class="mt-8">
          <div class="border-t border-gray-200 pt-6">
            <button
                class="flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 transition-colors duration-150"
                @click="showArchivedSegments = !showArchivedSegments">
              <svg-icon
                  :name="showArchivedSegments ? 'arrow-up' : 'arrow-down'"
                  class="w-5 h-5 mr-2" />
              Archived Segments ({{ archivedSegments.length }})
            </button>

            <div v-if="showArchivedSegments" class="mt-4">
              <div class="rounded-lg border border-gray-200 overflow-hidden">
                <table class="min-w-full divide-y divide-gray-200">
                  <thead>
                  <tr class="bg-gray-50">
                    <th class="px-6 py-3.5 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Segment name
                    </th>
                    <th class="px-6 py-3.5 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Actions
                    </th>
                  </tr>
                  </thead>
                  <tbody class="bg-white divide-y divide-gray-200">
                  <tr v-for="segment in archivedSegments"
                      :key="segment.id"
                      class="hover:bg-gray-50 transition-colors duration-150">
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {{ segment.name }}
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-right text-sm space-x-4">
                      <button
                          :disabled="isSaving"
                          class="text-blue-700 hover:text-blue-900 font-medium transition-colors duration-150 disabled:opacity-50 disabled:hover:text-blue-700"
                          @click="openEditModal(segment)">
                        Edit
                      </button>
                      <button
                          :disabled="isSaving"
                          class="text-green-700 hover:text-green-900 font-medium transition-colors duration-150 disabled:opacity-50 disabled:hover:text-green-700"
                          @click="unarchiveSegment(segment)">
                        Unarchive
                      </button>
                      <button
                          :disabled="isSaving"
                          class="text-red-700 hover:text-red-900 font-medium transition-colors duration-150 disabled:opacity-50 disabled:hover:text-red-700"
                          @click="openDeleteModal(segment.id)">
                        Delete
                      </button>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Delete Confirmation Modal -->
    <div v-if="showDeleteModal" class="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center">
      <div class="bg-white rounded-lg p-6 max-w-md w-full mx-4">
        <div class="flex justify-between items-center mb-4">
          <h3 class="text-lg font-medium text-gray-900">
            Delete Segment
          </h3>
          <button
              class="text-gray-400 hover:text-gray-500"
              @click="showDeleteModal = false">
            <svg-icon
                class="w-6 h-6"
                name="close" />
          </button>
        </div>

        <p class="text-gray-600 mb-6">
          Are you sure you want to delete this segment? This action cannot be undone.
        </p>

        <div class="flex justify-end space-x-3">
          <v-button
              class="btn btn-white"
              @click="showDeleteModal = false">
            Cancel
          </v-button>
          <v-button
              :disabled="deletingSegment"
              :loading="deletingSegment"
              class="btn btn-red"
              @click="confirmDelete">
            Delete
          </v-button>
        </div>
      </div>
    </div>

    <!-- Create/Edit Modal -->
    <studio-manage-segments-events-insight-edit

        v-if="showModal"
        :segment="editingSegment"
        :show-modal="showModal"
        @close="closeModal"
        @segment-saved="onSegmentSaved" />
  </div>
</template>

<style scoped>
.form-checkbox {
  @apply rounded border-gray-300;
}

.drag-handle {
  @apply cursor-move;
}

.fade-enter-active,
.fade-leave-active {
  transition:opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity:0;
}
</style>

<script lang="ts" setup>
// Script section remains unchanged
import { useDeleteSegmentMutation, useInsightsUsableSegmentsQuery, useSaveEventInsightSegmentsMutation } from "@/graphql/queries/ql/composables";
import { computed, ref, watch }                                                                          from "vue";
import VButton                                                                                           from "@/components/layout/VButton.vue";
import Loader                                                                                            from "@/components/layout/Loader.vue";
import { Segment }                                                                                       from "@/classes/entities/Segment";
import SvgIcon                                                                                           from "@/components/SvgIcon.vue";
import draggable                                                                                         from "vuedraggable";
import StudioManageSegmentsEventsInsightEdit                                                             from "./StudioManageSegmentsEventsInsightEdit.vue";

const { result, loading, refetch } = useInsightsUsableSegmentsQuery();
const allSegments = computed(() => result.value?.insights_usable_segments ?? []);

const showModal = ref(false);
const showDeleteModal = ref(false);
const showArchivedSegments = ref(false);
const deletingSegment = ref(false);
const segmentToDelete = ref<string | null>(null);
const editingSegment = ref(null);
const isSaving = ref(false);
const showSaved = ref(false);

const { mutate: deleteSegmentMutation, onDone: onDeleteSegmentDone } = useDeleteSegmentMutation({});
const { mutate: saveSegments, onDone: onSaveSegmentsDone } = useSaveEventInsightSegmentsMutation({});

onSaveSegmentsDone(() => {
  isSaving.value = false;
  showSavedMessage();
});

const showSavedMessage = () => {
  showSaved.value = true;
  setTimeout(() => {
    showSaved.value = false;
  }, 1000);
};

const localSegments = ref([]);

watch(allSegments, (newSegments) => {
  localSegments.value = newSegments.map(segment => ({ ...segment }));
});

const activeSegments = computed({
                                  get: () => {
                                    return localSegments.value
                                                        .filter(segment => !segment.archived)
                                                        .sort((a, b) => {
                                                          // Highlighted segments first
                                                          if (a.visible_dashboard && !b.visible_dashboard) {
                                                            return -1;
                                                          }
                                                          if (!a.visible_dashboard && b.visible_dashboard) {
                                                            return 1;
                                                          }
                                                          // Then by order
                                                          return (a.order ?? 0) - (b.order ?? 0);
                                                        });
                                  },
                                  set: (newValue) => {
                                    // Update orders based on new positions
                                    newValue.forEach((segment, index) => {
                                      const segmentIndex = localSegments.value.findIndex(s => s.id === segment.id);
                                      if (segmentIndex !== -1) {
                                        localSegments.value[segmentIndex].order = index + 1;
                                      }
                                    });
                                    updateSegmentOrders();
                                  }
                                });

const archivedSegments = computed(() => {
  return localSegments.value.filter(segment => segment.archived);
});

const updateSegmentOrders = () => {
  const segments = activeSegments.value;

  // Update orders
  segments.forEach((segment, index) => {
    const segmentIndex = localSegments.value.findIndex(s => s.id === segment.id);
    if (segmentIndex !== -1) {
      localSegments.value[segmentIndex].order = index + 1;
    }
  });

  // Save changes
  isSaving.value = true;
  saveSegments({
                 segments: localSegments.value.map(segment => ({
                   id:                segment.id,
                   order:             segment.order,
                   visible_dashboard: segment.visible_dashboard,
                   archived:          segment.archived
                 }))
               });
};

const toggleHighlighted = (segment) => {
  const segmentIndex = localSegments.value.findIndex(s => s.id === segment.id);
  if (segmentIndex !== -1) {
    localSegments.value[segmentIndex].visible_dashboard = !localSegments.value[segmentIndex].visible_dashboard;
    updateSegmentOrders();
  }
};

const archiveSegment = (segment) => {
  const segmentIndex = localSegments.value.findIndex(s => s.id === segment.id);
  if (segmentIndex !== -1) {
    // Find the highest order among all segments
    const highestOrder = Math.max(...localSegments.value.map(s => s.order || 0));

    // Set the segment as archived with the highest order + 1
    localSegments.value[segmentIndex].archived = true;
    localSegments.value[segmentIndex].order = highestOrder + 1;

    updateSegmentOrders();
  }
};

const unarchiveSegment = (segment) => {
  const segmentIndex = localSegments.value.findIndex(s => s.id === segment.id);
  if (segmentIndex !== -1) {
    localSegments.value[segmentIndex].archived = false;
    updateSegmentOrders();
  }
};

const onDragEnd = () => {
  updateSegmentOrders();
};

const createNewSegment = () => {
  const segment = Segment.createEmptyWithEvent("any", "Custom segment");
  segment.id = null;
  segment.visible_dashboard = true;
  segment.archived = false;
  segment.is_event = true;
  segment.order = 0;  // Explicitly set order to 0 for new segments
  return segment;
};

const openEditModal = (segment) => {
  const editSegment = createNewSegment();
  editSegment.id = segment.id;
  editSegment.name = segment.name;
  editSegment.filters = segment.filters || [];
  editSegment.visible_dashboard = segment.visible_dashboard || false;
  editSegment.only_deliverable_by_email = segment.only_deliverable_by_email || false;
  editSegment.only_deliverable_by_sms = segment.only_deliverable_by_sms || false;
  editSegment.archived = segment.archived || false;
  editSegment.order = segment.order;
  editSegment.is_event = true;

  editingSegment.value = editSegment;
  showModal.value = true;
};

const openCreateModal = () => {
  editingSegment.value = createNewSegment();
  showModal.value = true;
};

const closeModal = () => {
  showModal.value = false;
  editingSegment.value = null;
};

const onSegmentSaved = async () => {
  await refetch();
};

const openDeleteModal = (segmentId: string) => {
  segmentToDelete.value = segmentId;
  showDeleteModal.value = true;
};

const confirmDelete = () => {
  if (!segmentToDelete.value) {
    return;
  }

  deletingSegment.value = true;
  deleteSegment(segmentToDelete.value);
};

const deleteSegment = (segmentId: string) => {
  deleteSegmentMutation({
                          id: segmentId
                        });
};

onDeleteSegmentDone(() => {
  deletingSegment.value = false;
  segmentToDelete.value = null;
  showDeleteModal.value = false;
  refetch();
});
</script>
