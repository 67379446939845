<template>
    <div v-if="originalAutomations.length > 0">
        <div class="items-baseline">
            <div class="grow">
                <div class="flex items-center justify-between"
                    :class="{ 'cursor-pointer': !forceOpen, 'group': !forceOpen, 'hover:text-blue-900': !forceOpen }"
                    @click="listOpened = !listOpened">
                    <div class="inline-flex">
                        <div v-if="!forceOpen" class="h-6 w-6 self-center">
                            <div v-show="listOpened">
                                <svg-icon name="minus" class="h-6 w-6 self-center" />
                            </div>
                            <div v-show="!listOpened">
                                <svg-icon name="down" class="h-6 w-6 self-center" />
                            </div>
                        </div>
                        <h2 class="text-lg font-medium">
                            {{ categoryName }}
                        </h2>
                    </div>
                    <div class="ml-2 px-2 bg-gray-50 text-gray-500 rounded border border-gray-200"
                        data-test="active-count">
                        <span class="text-green-900"><span class="font-semibold">{{ automationsActiveCount }}</span>
                            active</span>
                        <span v-if="includeInactiveCount"> - </span>
                        <span v-if="includeInactiveCount" class="text-gray-500">
                            <span class="font-semibold">{{ automationsInactiveCount }}</span> inactive</span>
                    </div>
                </div>
            </div>
            <div v-show="forceOpen || listOpened" class="flex grow justify-end mt-2 mb-4">
                <div class="space-x-4 items-center flex">
                    <v-button v-if="hasChanges" :disabled="saving" :loading="saving"
                        class="btn btn-green btn-xs py-1 mr-2" @click="saveOrder">
                        <div class="inline-flex items-center">
                            <svg-icon name="save-disk" class="w-3 h-3" />
                            <span class="ml-1">Save</span>
                        </div>
                    </v-button>
                    <div v-if="bulkSelecting == false" class="inline-flex items-center text-xs text-link" role="button"
                        @click="reordering = !reordering">
                        <svg-icon name="reorder" class="h-3 w-3" />
                        <span class="ml-1">Reorder</span>
                    </div>

                    <div v-if="!bulkSelecting && canEditAutomations" class="inline-flex items-center text-xs text-link"
                        role="button" @click="bulkSelectingModel = !bulkSelectingModel">
                        <svg-icon name="table-duotone" class="h-4 w-4" />
                        <span class="ml-1">Bulk</span>
                    </div>
                </div>
            </div>
        </div>
        <div v-show="forceOpen || listOpened" class="mt-2 mb-4 bg-white rounded-lg shadow divide-y overflow-hidden">
            <TransitionGroup tag="div" name="fade" class="container px-0">
                <div v-for="(automation, index) in orderedAutomations" :key="automation.id">
                    <div class="w-full block"
                        :class="{ 'available-drop': dragStatus.over.index === index && dragStatus.over.active, 'h-2': reordering }" />
                    <div class="flex items-center">
                        <div v-if="reordering">
                            <svg-icon name="arrow-up" class="h-6 w-6 shrink-0 ml-2 text-gray-400"
                                :class="[index == 0 ? 'cursor-not-allowed opacity-40' : ' cursor-pointer hover:text-blue-500']"
                                @click="moveItem(automation.id, true)" />
                            <svg-icon name="arrow-down" class="h-6 w-6 shrink-0 ml-2 text-gray-400"
                                :class="[index == orderedAutomations.length - 1 ? 'cursor-not-allowed opacity-40' : ' cursor-pointer hover:text-blue-500']"
                                @click="moveItem(automation.id, false)" />
                        </div>

                        <input v-if="bulkSelecting" v-model="selectedIdsModel" type="checkbox"
                            class="form-checkbox ml-4 mr-1" :value="automation.id">

                        <router-link v-if="!bulkSelecting"
                            :to="{ name: routeList.automations.show.index, params: { id: automation.id } }"
                            class="py-4 hover:bg-blue-50 cursor-pointer block w-full" role="link">
                            <automation-index-single :automation="automation" class="grow mx-0" />
                        </router-link>

                        <div v-if="bulkSelecting" class="py-4 hover:bg-blue-50 cursor-pointer block w-full" role="link"
                            @click="addOrRemoveAutomationFromBulk(automation.id)">
                            <automation-index-single :automation="automation" class="grow mx-0" />
                        </div>
                    </div>
                </div>
            </TransitionGroup>
        </div>
    </div>
</template>
<style scoped>
.available-drop {
    @apply h-10 bg-gray-200;
}


/* 1. declare transition */
.fade-move,
.fade-enter-active,
.fade-leave-active {
    transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
}

/* 2. declare enter from and leave to state */
.fade-enter-from,
.fade-leave-to {
    opacity: 0;
    transform: scaleY(0.01) translate(30px, 0);
}

/* 3. ensure leaving items are taken out of layout flow so that moving
      animations can be calculated correctly. */
.fade-leave-active {
    position: absolute;
}
</style>
<script lang="ts" setup>
import routesList from "@/composables/routesList";
import SvgIcon from "@/components/SvgIcon.vue";
import AutomationIndexSingle from "@/views/Automations/Components/Index/AutomationIndexSingle.vue";
import VButton from "@/components/layout/VButton.vue";
import { computed, onMounted, Ref, ref, toRef, useAttrs, useSlots, watch } from "vue";
import { QLFragmentAutomationIndexFragment, useReorderAutomationsMutation } from "@/graphql/queries/ql/composables";
import dragDropComposable from "@/composables/dragDropComposable";
import { deepCopy } from "@/classes/helpers/DeepCopy";
import $toast from "@/composables/toast";
import WebNotificationData from "@/classes/notifications/WebNotificationData";

const { routeList } = routesList();

interface Props {
    automations: QLFragmentAutomationIndexFragment[];
    includeInactiveCount?: boolean;
    forceOpen?: boolean;
    selectedIds: string[],
    bulkSelecting: boolean,
    canEditAutomations: boolean
}

const props = withDefaults(defineProps<Props>(), {
    includeInactiveCount: false,
    forceOpen: false
});

const slots = useSlots();
const attrs = useAttrs();

const emit = defineEmits(["update:selectedIds", "update:bulkSelecting"]);
type Mutable<Type> = {
    -readonly [Key in keyof Type]: Type[Key];
};
const originalAutomations = toRef(props, "automations") as Mutable<Ref<QLFragmentAutomationIndexFragment[]>>;

const reordering = ref(false);
const listOpened = ref(false);
const saving = ref(false);

const {
    status: dragStatus,
    dragFns
} = dragDropComposable();

const mut = useReorderAutomationsMutation({});
mut.onDone(() => {
    saving.value = false;
    reordering.value = false;
});

mut.onError(() => {
    saving.value = false;
    $toast({
        type: "error",
        message: "Error saving the new order. Please try again!"
    } as WebNotificationData);
});

const automationsList = ref([]);
watch(originalAutomations, (val) => {
    automationsList.value = deepCopy(originalAutomations.value);
    reorderAutomations();
});

onMounted(() => {
    automationsList.value = deepCopy(originalAutomations.value);
    reorderAutomations();
});

const orderedAutomations = computed(() => {
    return automationsList.value
        .map((i) => i)
        .sort((a, b) => {
            return a.order > b.order ? 1 : -1;
        });
});

const reorderAutomations = () => {
    automationsList.value.sort((a, b) => {
        return (a.order != null ? a.order : 10000) > (b.order != null ? b.order : 10000) ? 1 : -1;
    })
        .map((automation, index) => {
            automation.order = index;
            return automation;
        });
};

const categoryName = computed(() => {
    if (props.automations[0].category_name == null) {
        return "Uncategorized";
    }
    return props.automations[0].category_name;
});

const automationsActiveCount = computed(() => {
    return props.automations.filter((a) => a.active).length;
});

const automationsInactiveCount = computed(() => {
    return props.automations.filter((a) => !a.active).length;
});

const onDrop = (evt, active, order) => {
    const itemID = evt.dataTransfer.getData("itemID");
    const item = props.automations.find(item => item.id == itemID);
    if (item.order == order || item.order + 1 == order) {
        // do nothing
        dragFns.endDragging();
        return;
    }

    const idWithSameOrder = props.automations.find(item => item.order == order);
    const indexWithSameOrder = props.automations.indexOf(idWithSameOrder);

    automationsList.value = automationsList.value.map((inArrayItem, index) => {
        if (inArrayItem.id === item.id) {
            inArrayItem.order = order;
            return inArrayItem;
        }
        if (index >= indexWithSameOrder) {
            inArrayItem.order += 1;
        }

        return inArrayItem;
    });
    reorderAutomations();
    dragFns.endDragging();
    return;
};

const simplifiedOrderObject = (data: QLFragmentAutomationIndexFragment[]) => {
    return data.map((automation) => {
        return { id: automation.id, order: automation.order };
    }).sort(((a, b) => a.order > b.order ? 1 : -1)).map((item, index) => {
        return { id: item.id, order: index };
    });
};

const hasChanges = computed<boolean>(() => {
    const simplifiedCurrent = simplifiedOrderObject(automationsList.value);
    const simplifiedOriginal = simplifiedOrderObject(originalAutomations.value);
    if (simplifiedCurrent.length != simplifiedOriginal.length) {
        return true;
    }

    for (let index = 0; index < simplifiedCurrent.length - 1; index++) {
        if (simplifiedCurrent[index].id != simplifiedOriginal[index].id || simplifiedCurrent[index].order != simplifiedOriginal[index].order) {
            return true;
        }
    }
    return false;
});

const saveOrder = () => {
    saving.value = true;
    const toSend = simplifiedOrderObject(automationsList.value);

    mut.mutate({ order: toSend });
};

const bulkSelectingModel = computed({
    get: () => props.bulkSelecting,
    set: (val) => emit("update:bulkSelecting", val)
});

const selectedIdsModel = computed({
    get: () => props.selectedIds,
    set: (val) => emit("update:selectedIds", val)
});

const addOrRemoveAutomationFromBulk = (automationId: string) => {
    const index = selectedIdsModel.value.indexOf(automationId);
    if (index !== -1) {
        // remove item if it exists
        selectedIdsModel.value.splice(index, 1);
    } else {
        // add item if it doesn't exist
        selectedIdsModel.value.push(automationId);
    }
};

const moveItem = (id: string, up: boolean) => {
    const current = automationsList.value[automationsList.value.findIndex((item) => item.id == id)];
    if (current.order == 0 && up) {
        return;
    }
    if (current.order == automationsList.value.length - 1 && !up) {
        return;
    }

    if (up) {
        const other = automationsList.value[automationsList.value.findIndex((item) => item.order == current.order - 1)];
        current.order = current.order - 1;
        other.order = other.order + 1;
    } else {
        const other = automationsList.value[automationsList.value.findIndex((item) => item.order == current.order + 1)];
        current.order = current.order + 1;
        other.order = other.order - 1;
    }

};

</script>
