<template>
  <div class="relative flex flex-col">
    <div v-if="label || hasRightSlot" class="flex justify-between">
      <label v-if="label" :class="{ 'has-error': hasError }" :for="id" class="form-label mb-1 shrink-0">
        {{ label }} <span v-if="required" :class="{ 'is-required': requiredAndEmpty }"
                          class="text-xs opacity-50">(required)</span>
      </label>
      <slot name="right_of_label"></slot>
    </div>

    <div
        class="p-0 m-0 grow flex flex-col bg-white relative border border-gray-300 rounded-lg shadow-sm focus-within:border-indigo-500 focus-within:ring-1 focus-within:ring-indigo-500">
      <textarea :id="id"
                ref="txtArea"
                v-model="inputData"
                :class="{ 'has-error': hasError, 'mt-1': !!label }"
                :disabled="disabled"
                :maxlength="maxLength"
                :minlength="minLength"
                :placeholder="placeholder"
                :required="required"
                :rows="rows"
                class="relative block grow overflow-hidden w-full h-full border-0 resize-none placeholder-gray-500 focus:ring-0 sm:text-sm rounded-t-lg"
                @click="updatePosition"
                @input="prepareText"></textarea>

      <!-- Spacer element to match the height of the toolbar -->
      <div v-if="hasInsideSlot" aria-hidden="true">
        <div class="py-2">
          <div class="h-2"></div>
        </div>
        <div class="h-px"></div>
        <div class="py-2">
          <div class="py-px">
            <div class="h-2"></div>
          </div>
        </div>
      </div>

      <div v-if="hasInsideSlot" class="absolute bottom-0 inset-x-px">
        <div class="relative">
          <slot name="internal"></slot>
        </div>
      </div>
    </div>
    <span v-if="hasError" class="form-error-message">{{ errorMessage }}</span>
    <div v-if="smallInfo && smallInfo.length > 0" class="leading-tight form-small-info text-gray-600">
      {{
        smallInfo
      }}
    </div>
    <slot></slot>
  </div>
</template>

<script lang="ts" setup>
import Message                                          from "@/classes/entities/Message";
import { computed, nextTick, onMounted, ref, useSlots } from "vue";

const textArea = ref(null);
const testText = ref("");

interface Props {
  label?: string;
  placeholder?: string;
  errorMessage?: string;
  smallInfo?: string;
  required?: boolean;
  disabled?: boolean;
  minLength?: number;
  maxLength?: number;
  position?: number;
  rows?: number;
  modelValue?: string | null;
  replaceUtf?: boolean;
}

const slots = useSlots();
const props = withDefaults(defineProps<Props>(), {
  replaceUtf: false,
  position:   0,
  rows: 4
});
const emit = defineEmits([ "update:modelValue", "update:position" ]);

const id = ref("");
const txtArea = ref<HTMLInputElement>(null);

onMounted(() => {
  id.value = Math.random().toString();
});
const hasError = computed(() => {
  return props.errorMessage && props.errorMessage.length > 0;
});
const requiredAndEmpty = computed(() => {
  if (hasError.value) {
    return false;
  }
  if (props.required && (!props.modelValue || (props.modelValue.toString().length === 0))) {
    return true;
  }
  return false;
});

const updatePosition = () => {
  emit("update:position", txtArea.value.selectionStart || 0);
};
const updatingText = ref(false);

const inputData = computed({
                             get: () => {
                               // txtArea.value.dispatchEvent(new Event("input", {bubbles: true}));
                               return props.modelValue;
                             },
                             set: (val) => {
                               updatingText.value = true;

                               const cursorPosition = txtArea.value.selectionStart || 0;

                               if (props.replaceUtf) {
                                 val = Message.replaceInconsequentCharacters(val);
                               }

                               emit("update:modelValue", val);

                               nextTick(() => {
                                 if (updatingText.value) {
                                   txtArea.value.dispatchEvent(new Event("input", { bubbles: true, composed: true }));
                                   txtArea.value.setSelectionRange(cursorPosition, cursorPosition);
                                   updatePosition();
                                   updatingText.value = false;
                                 }
                               });

                             }
                           });

const hasInsideSlot = computed(() => {
  return !!slots.internal;
});

const hasRightSlot = computed(() => {
  return !!slots.right_of_label;
});

</script>
