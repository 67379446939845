<template>
  <side-panel
      v-model:open="openModel"
      :closable="!sending">
    <template #header>
      Send an email to {{ client.first_name }}
    </template>
    <div class="px-4 py-4 h-full ">
      <form
          ref="form"
          class="h-full flex flex-col" @submit.prevent="send">
        <text-input
            v-model="subject"
            :disabled="sending"
            :error-message="errors.get('subject')"
            class="mb-4"
            label="Subject"
            placeholder="Email subject"
            required />
        <label class="form-label mb-1">
          Email
        </label>
        <KeepAlive max="1">
          <rich-editor
              v-model="message"
              :disabled="sending"
              class="mb-4 grow"
              name="content"
              placeholder="Email message..." />
        </KeepAlive>
      </form>
    </div>

    <template #footer>
      <div
          v-if="!sending && openModel"
          class="flex m-2 justify-between w-full">
        <button
            class="btn btn-white"
            @click="openModel = false">
          Cancel
        </button>
        <button
            class="btn btn-green"
            @click="send">
          Send!
        </button>
      </div>
      <div
          v-if="sending && openModel"
          class="flex m-2 justify-center">
        <div class="h-8 w-8">
          <loader />
        </div>
      </div>
    </template>
  </side-panel>
</template>
<script lang="ts" setup>
import SidePanel                                                      from "@/components/layout/SidePanel.vue";
import RichEditor                                                     from "@/components/layout/RichEditor.vue";
import TextInput                                                      from "@/components/layout/Forms/TextInput.vue";
import Loader                                                         from "@/components/layout/Loader.vue";
import { computed, onMounted, ref, toRef, useAttrs, useSlots, watch } from "vue";
import SparkFormErrors                                                from "@/classes/utils/SparkFormErrors";
import { QLClient, useDiscussionCreateMutation }                      from "@/graphql/queries/ql/composables";
import currentTeamQuery                                               from "@/composables/queries/currentTeamQuery";
import fillValidationErrorsOrToast                                    from "@/composables/queries/mutations/fillValidationErrorsOrToast";
import $toast                                                         from "@/composables/toast";
import routesList                                                     from "@/composables/routesList";
import { toastIfRateLimitWasHit }                                     from "@/composables/rateLimits";

interface Props {
  open: boolean;
  client: QLClient;
}

const props = withDefaults(defineProps<Props>(), {});

const slots = useSlots();
const attrs = useAttrs();

const emit = defineEmits([ "update:open", "sent" ]);

const openModel = computed({
                             get: () => props.open,
                             set: (val) => emit("update:open", val)
                           });

const form = ref<HTMLFormElement>(null);
const errorsData = ref({});

const message = ref("");
const subject = ref("");
const sending = ref(false);

const client = toRef(props, "client");

const errors = computed(() => {
  return new SparkFormErrors(errorsData.value);
});

const resetForm = () => {
  sending.value = false;
  message.value = "";
  subject.value = "";
  addSignature();
  errorsData.value = {};
};

onMounted(() => {
  resetForm();
});

watch(client, (old, newer) => {
  resetForm();
});

const currentTeamQ = currentTeamQuery();
const { currentTeam } = currentTeamQ;

const addSignature = () => {
  if (!currentTeamQ.loading.value && !!currentTeam.value && message.value.length == 0) {
    message.value = ("\n\n\n----\n" + currentTeam.value.email_signature);
  }
};
watch(currentTeam, () => {
  if (!!currentTeam.value) {
    addSignature();
  }
}, { immediate: true });

const mutation = useDiscussionCreateMutation({});

mutation.onError((err) => {
  if (!toastIfRateLimitWasHit(err)) {
    fillValidationErrorsOrToast(err, errorsData, "Error sending the email. Please try again!");
  }
  sending.value = false;
});

mutation.onDone((res) => {
  $toast({
           type:     "success",
           message:  "Email sent!",
           linkText: "View it now",
           linkRouteObj:
                     {
                       name:   routesList().routeList.client.discussions.show,
                       params: {
                         id:           client.value.id,
                         discussionId: res.data.CreateDiscussion.id
                       }
                     }
         });
  sending.value = false;
  openModel.value = false;
  resetForm();
  emit("sent");
});

const send = () => {
  errorsData.value = {};
  if (!form.value.reportValidity()) {
    return;
  }

  sending.value = true;
  mutation.mutate({
                    client_id: client.value.id,
                    subject:   subject.value,
                    message:   message.value,
                    is_email:  true
                  });
};
</script>
