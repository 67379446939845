<template>
  <span :class="{
    'event-attendance-signed-in': decorator.isSignedIn,
    'event-attendance-upcoming': decorator.isUpcoming,
    'event-attendance-early': decorator.isEarlyCancelled,
    'event-attendance-absent': decorator.isAbsent || decorator.isLateCancelled
  }"
        class="inline-flex items-center px-2.5 py-0.5 rounded-full text-sm font-medium">
    <svg v-if="decorator.isLateCancelled || decorator.isAbsent"
         class="w-3.5 h-3.5 mr-1 text-red-500"
         fill="currentColor"
         viewBox="0 0 20 20">
      <path clip-rule="evenodd"
            d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
            fill-rule="evenodd" />
    </svg>
    <svg-icon v-if="decorator.isSignedIn" class="w-3.5 h-3.5 mr-1" name="check" />
    <svg-icon v-if="decorator.isUpcoming" class="w-3.5 h-3.5 mr-1" name="clock" />
    <svg-icon v-if="decorator.isEarlyCancelled" class="w-3.5 h-3.5 mr-1" name="exclamation" />
    {{ decorator.statusText }}
  </span>
</template>

<script lang="ts" setup>
import { defineProps } from "vue";
import SvgIcon from "@/components/SvgIcon.vue";
import { VisitDecorator } from "@/composables/decorators/VisitDecorator";

const props = defineProps<{
  visit: any;          // Replace 'any' with the appropriate type if available
  singleEvent: any;    // Replace 'any' with the appropriate type if available
}>();

const { computed: decorator } = VisitDecorator(props.visit, props.singleEvent);
</script>

<style scoped>
.event-attendance-signed-in {
  @apply bg-green-100 text-green-800;
}

.event-attendance-upcoming {
  @apply bg-teal-100 text-teal-800;
}

.event-attendance-early {
  @apply bg-yellow-100 text-yellow-800;
}

.event-attendance-absent {
  @apply bg-red-100 text-red-800;
}
</style>
