<template>
  <modal
      v-model:open="openModel"
      :has-close-button="false"
      full-heigth
      full-width
      @close="close">
    <div class="w-full grow h-full">
      <filter-group-recap
          v-if="step === 'recap'"
          v-model="filterGroup"
          @close="close"
          @delete="deleted"
          @edited="editedFilter"
          @new-filter="newFilterBtn" />
      <filter-group-select-category
          v-if="step === 'category'"
          :is_event="is_event"
          @step-back="stepBack"
          @selected-category="selectedCategory" />
      <component
          :is="getCategoryComponent(newCategory)"
          v-if="step === 'selectFilter'"
          v-model:filter-selected="filterSelected"
          v-model:operator-selected="selectedOperator"
          :category-name="newCategory"
          @step-back="stepBack"
          @added-rule="addedRule" />
    </div>
    <template #buttons>
      <v-button
          v-if="step == 'recap'"
          class="modal-button modal-button-green"
          @click="close">
        Save!
      </v-button>
      <v-button
          v-if="step == 'recap' && hasChanges"
          class="modal-button modal-button-text modal-button-text-loopspark"
          @click="revert">
        Revert changes
      </v-button>

      <v-button
          v-if="step == 'recap'"
          class="modal-button modal-button-text modal-button-text-red"
          @click="deleted">
        Delete
      </v-button>

      <div
          v-if="step != 'recap'"
          class="grow w-full">
        <v-button
            class="modal-button modal-button-white"
            @click="stepBack">
          <div class="inline-flex items-center">
            <svg-icon
                class="w-4 h-4"
                name="left" />
            <span class="">{{ backButtonText }}</span>
          </div>
        </v-button>
      </div>
    </template>
  </modal>
</template>

<script lang="ts" setup>
import Modal from "@/components/layout/Modal.vue";
import VButton from "@/components/layout/VButton.vue";
import FilterGroupRecap from "@/components/Segment/Edit/FilterGroupRecap.vue";
import FilterGroupSelectCategory from "@/components/Segment/Edit/FilterGroupSelectCategory.vue";
import FilterGroupCategoryClient from "@/components/Segment/Edit/Categories/SingleCategories/FilterGroupCategoryClient.vue";
import FilterGroupCategoryCalendar from "@/components/Segment/Edit/Categories/SingleCategories/FilterGroupCategoryCalendar.vue";
import FilterGroupCategoryCommunication from "@/components/Segment/Edit/Categories/SingleCategories/FilterGroupCategoryCommunication.vue";
import FilterGroupCategoryContract from "@/components/Segment/Edit/Categories/SingleCategories/FilterGroupCategoryContract.vue";
import FilterGroupCategoryPackage from "@/components/Segment/Edit/Categories/SingleCategories/FilterGroupCategoryPackage.vue";
import FilterGroupCategoryProduct from "@/components/Segment/Edit/Categories/SingleCategories/FilterGroupCategoryProduct.vue";
import FilterGroupCategorySale from "@/components/Segment/Edit/Categories/SingleCategories/FilterGroupCategorySale.vue";
import FilterGroupCategoryBooking from "@/components/Segment/Edit/Categories/SingleCategories/FilterGroupCategoryBooking.vue";
import FilterGroupCategoryForm from "@/components/Segment/Edit/Categories/SingleCategories/FilterGroupCategoryForm.vue";
import FilterGroupCategoryThisEvent from "@/components/Segment/Edit/Categories/SingleCategories/FilterGroupCategoryThisEvent.vue";
import SvgIcon from "@/components/SvgIcon.vue";
import { QLFilter, QLFilterGroup } from "@/graphql/queries/ql/composables";
import { computed, onMounted, provide, ref, toRef, watch } from "vue";
import Filter from "hm-filters/base-filter";
import Operator from "hm-filters/operators/operator";
import { filterAndSelectedOperatorGetter } from "hm-filters/filterGetter";
import { deepCopy } from "@/classes/helpers/DeepCopy";
import FilterGroupCategoryToDo from "@/components/Segment/Edit/Categories/SingleCategories/FilterGroupCategoryToDo.vue";

const categoryComponents = {
  Client: FilterGroupCategoryClient,
  Calendar: FilterGroupCategoryCalendar,
  Communication: FilterGroupCategoryCommunication,
  Contract: FilterGroupCategoryContract,
  Package: FilterGroupCategoryPackage,
  Product: FilterGroupCategoryProduct,
  Sale: FilterGroupCategorySale,
  Booking: FilterGroupCategoryBooking,
  Form: FilterGroupCategoryForm,
  ToDo: FilterGroupCategoryToDo,
  ThisEvent: FilterGroupCategoryThisEvent
};

const getCategoryComponent = (category: string | null) => {
  if (!category) {
    return null;
  }
  return categoryComponents[category as keyof typeof categoryComponents];
};

interface Props {
  modelValue: QLFilterGroup;
  open: boolean;
  is_event?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  is_event: false
});
const emit = defineEmits<{
  (e: "closed"): void;
  (e: "deleted"): void;
  (e: "update:modelValue", value: QLFilterGroup): void;
  (e: "update:open", value: boolean): void;
  (e: "close"): void;
}>();

const step = ref<"recap" | "category" | "selectFilter">("recap");
const newCategory = ref<string | null>(null);
const filterSelected = ref<Filter | null>(null);
const selectedOperator = ref<Operator | null>(null);
const isEditing = ref(false);
const editedOriginal = ref<any>(null);
provide("oldData", editedOriginal);
const filterGroup = ref<QLFilterGroup>(props.modelValue);
const is_event = toRef(props, "is_event");

const openModel = computed({
  get: () => props.open,
  set: (val) => emit("update:open", val)
});

const loadLocalFromProp = () => {
  filterGroup.value = deepCopy(props.modelValue);
};

onMounted(() => {
  loadLocalFromProp();
  // Check initial state
  if (filterGroup.value.filters.length === 1 && !filterGroup.value.operator_is_and) {
    filterGroup.value.operator_is_and = true;
    emit("update:modelValue", deepCopy(filterGroup.value));
  }
});

watch(() => props.modelValue, loadLocalFromProp);

// Deep watch the filter group
watch(filterGroup, (newGroup) => {
  if (newGroup.filters.length === 1 && !newGroup.operator_is_and) {
    newGroup.operator_is_and = true;
    emit("update:modelValue", deepCopy(newGroup));
  }
}, { deep: true });

const alreadySelectedCategory = computed(() => {
  if (filterGroup.value.filters.length > 0) {
    // Ensure filter and path exist before accessing
    const firstFilterInfo = filterAndSelectedOperatorGetter(filterGroup.value.filters[0]);
    return firstFilterInfo?.filter?.path?.[0] || "";
  }
  return "";
});

const selectedCategory = (category: string) => {
  const newCat = category.replace(/\s+/g, '');
  let operatorChanged = false;
  
  // If we have filters and switching to different category
  if (filterGroup.value.filters.length > 0) {
    const currentCat = alreadySelectedCategory.value;
    if (currentCat && currentCat !== newCat) {
      // Silently switch to OR logic if it's currently AND
      if (filterGroup.value.operator_is_and) {
        filterGroup.value.operator_is_and = false;
        operatorChanged = true;
      }
    }
  }
  
  newCategory.value = newCat;
  step.value = "selectFilter";

  // Emit update if the operator changed
  if (operatorChanged) {
    emit("update:modelValue", deepCopy(filterGroup.value));
  }
};

const newFilterBtn = () => {
  editedOriginal.value = null;
  if (filterGroup.value.filters.length > 0) {
    // If operator is AND, default to the same category
    if (filterGroup.value.operator_is_and) {
      const currentCategory = alreadySelectedCategory.value;
      if (currentCategory) {
        // Skip category selection and go directly to filter selection
        newCategory.value = currentCategory;
        step.value = "selectFilter";
      } else {
        // Fallback if category couldn't be determined (should ideally not happen)
        step.value = "category";
      }
    } else {
      // If operator is OR, always show category selection
      step.value = "category";
    }
  } else {
    // No filters yet, show category selection
    step.value = "category";
  }
};

const editedFilter = (filter) => {
  isEditing.value = true;
  newCategory.value = filter.filter.path[0];
  filterSelected.value = filter.filter;
  selectedOperator.value = filter.operator;
  editedOriginal.value = filter.original;
  step.value = "selectFilter";
};

const close = () => {
  openModel.value = false;
  emit("update:modelValue", filterGroup.value);
  emit("closed");
};

const deleted = () => {
  openModel.value = false;
  emit("deleted");
};

const revert = () => {
  loadLocalFromProp();
};

const hasChanges = computed(() => {
  return JSON.stringify(deepCopy(props.modelValue)) !== JSON.stringify(deepCopy(filterGroup.value));
});

const stepBack = () => {
  if (isEditing.value) {
    // If editing, revert the filter and go back to recap
    if (editedOriginal.value) {
       filterGroup.value.filters.push(editedOriginal.value);
    }
    isEditing.value = false;
    filterSelected.value = null;
    selectedOperator.value = null;
    editedOriginal.value = null; // Clear edited state
    step.value = "recap";
    emit("update:modelValue", deepCopy(filterGroup.value)); // Emit reverted state
    return;
  }
  
  filterSelected.value = null;
  selectedOperator.value = null;
  
  if (step.value === "selectFilter") {
    // Always allow going back to category selection
    step.value = "category";
    newCategory.value = null;
    return;
  }
  
  if (step.value === "category") {
    step.value = "recap";
  }
};


const backButtonText = computed(() => {
  if (step.value === "selectFilter") {
    return "Other categories";
  }
  return "Back";
});

const addedRule = (rule: QLFilter) => {
  filterGroup.value.filters.push(rule);
  // Force AND if this is the first filter
  if (filterGroup.value.filters.length === 1) {
    filterGroup.value.operator_is_and = true;
  }
  // Check if adding this rule requires switching to OR for multiple different categories
  else if (filterGroup.value.filters.length > 1) {
    const categories = new Set(
      filterGroup.value.filters.map(f => filterAndSelectedOperatorGetter(f)?.filter?.path?.[0])
    );
    if (categories.size > 1 && filterGroup.value.operator_is_and) {
      filterGroup.value.operator_is_and = false;
    }
  }
  
  newCategory.value = null;
  step.value = "recap";
  isEditing.value = false;
  filterSelected.value = null;
  selectedOperator.value = null;
  editedOriginal.value = null; // Clear edited state
  emit("update:modelValue", deepCopy(filterGroup.value)); // Emit final state
};
</script>
