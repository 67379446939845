<template>
  <div class="bg-white shadow overflow-hidden sm:rounded-lg">
    <div class="px-4 py-5 sm:px-6">
      <div class="flex justify-between items-center">
        <h3 class="text-lg leading-6 font-medium text-gray-900 ">
          <slot name="title" />
        </h3>
        <slot name="right" />
      </div>
      <!--      <p class="mt-1 max-w-2xl text-sm text-gray-500">-->
      <!--        <slot name="subtitle" />-->
      <!--      </p>-->
    </div>
    <div class="border-t border-gray-200 px-4 py-5 sm:p-0">
      <dl class="divide-y divide-gray-100">
        <slot name="items" />
      </dl>
    </div>
  </div>
</template>
<script lang="ts" setup>

</script>
