<template>
  <modal v-model:open="openModel" :quick-close="!sending" :wide="true">
    <div class="w-full">
      <div class="pb-4">
        <div class="px-4 py-5 border-b border-gray-200">
          <div class="-ml-4 -mt-4 flex justify-between items-start flex-wrap sm:flex-nowrap">
            <div class="ml-4 mt-4">
              <h3 class="text-lg leading-6 font-medium text-gray-900">
                Contact
              </h3>
              <p class="mt-1 text-sm text-gray-500">
                Here you can prepare a message to send to the clients booked for this event
              </p>
            </div>
          </div>
        </div>

        <div class="grid grid-cols-2 gap-6 pt-4 px-4">
          <!-- Left Column - Member List -->
          <div class="border-r pr-4">
            <!-- Selection Options -->
            <div class="mb-4 space-y-3">
              <div class="space-y-2">
                <div class="text-sm font-medium text-gray-700">Select:</div>
                <div class="flex flex-wrap gap-2 items-center">
                  <!-- All button -->
                  <button :disabled="sending"
                    :class="isAllSelected ? 'bg-blue-100 text-blue-800' : 'bg-gray-100 text-gray-700'"
                    class="inline-flex items-center px-2.5 py-1.5 rounded-full text-xs font-medium" @click="toggleAll">
                    All ({{ eligibleVisits.length }})
                  </button>

                  <!-- Visible dashboard segments -->
                  <button v-for="segment in visibleSegments" :key="segment.name" :disabled="sending"
                    :class="isSegmentSelected(segment) ? 'bg-blue-100 text-blue-800' : 'bg-gray-100 text-gray-700'"
                    class="inline-flex items-center px-2.5 py-1.5 rounded-full text-xs font-medium"
                    @click="toggleSegment(segment)">
                    {{ segment.name }} ({{ getSegmentVisits(segment).length }})
                  </button>

                  <!-- Accordion for other segments -->
                  <div v-if="otherSegments.length > 0" class="w-full mt-2">
                    <button :disabled="sending" :class="[
                      'w-full flex items-center justify-between px-3 py-2 text-sm text-gray-600 border rounded-md transition-colors',
                      isDropdownOpen ? 'bg-gray-50 border-gray-300' : 'bg-white border-gray-200 hover:bg-gray-50'
                    ]" type="button" @click="toggleDropdown">
                      <span class="font-medium">Show {{ otherSegments.length }} more segments</span>
                      <svg-icon :name="isDropdownOpen ? 'arrow-up' : 'arrow-down'" class="w-4 h-4 text-gray-400" />
                    </button>
                  </div>

                  <!-- Accordion content -->
                  <div v-if="isDropdownOpen" class="flex flex-wrap gap-2 mt-2 pl-2 pt-2 border-l-2 border-gray-200">
                    <button v-for="segment in otherSegments" :key="segment.name" :disabled="sending"
                      :class="isSegmentSelected(segment) ? 'bg-blue-100 text-blue-800' : 'bg-gray-100 text-gray-700'"
                      class="inline-flex items-center px-2.5 py-1.5 rounded-full text-xs font-medium"
                      @click="toggleSegment(segment)">
                      {{ segment.name }} ({{ getSegmentVisits(segment).length }})
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <!-- Members List -->
            <div class="space-y-3 max-h-[400px] overflow-y-auto">
              <div v-for="visit in filteredVisits" :key="visit.client.id" :class="{
                'opacity-50 cursor-not-allowed': !hasContactMethod(visit.client)
              }"
                class="flex items-center space-x-4 p-3 bg-white rounded-lg border border-gray-100 hover:border-gray-200 transition-colors">
                <input v-model="selectedClientIds" :disabled="sending || !hasContactMethod(visit.client)"
                  :value="visit.client.id" class="h-4 w-4 text-blue-600 border-gray-300 rounded" type="checkbox">
                <client-avatar :client="visit.client" class="w-10 h-10" />
                <div class="min-w-0 flex-1">
                  <div class="flex items-center justify-between">
                    <div>
                      <p class="text-sm font-medium text-gray-900 truncate">
                        {{ visit.client.first_name }} {{ visit.client.last_name }}
                      </p>
                      <p v-if="deselectionMessages[visit.client.id]" class="text-xs text-red-600 mt-0.5">
                        {{ deselectionMessages[visit.client.id] }}
                      </p>
                    </div>
                    <div class="flex items-center space-x-2">
                      <!-- Contact method icons -->
                      <div class="flex items-center space-x-1">
                        <!-- Contact method tags -->
                        <div class="flex space-x-2">
                          <!-- SMS Tag -->
                          <tooltip>
                            <template #visible>
                              <span :class="[
                                'inline-flex items-center px-2 py-1 rounded-md text-xs font-medium',
                                getContactMethodState(visit.client, 'sms') === 'cannot_receive'
                                  ? 'bg-gray-100 text-red-400 opacity-50 line-through'
                                  : (getContactMethodState(visit.client, 'sms') === 'selected' ? 'bg-green-100 text-green-800' : 'bg-gray-100 text-gray-600')
                              ]">
                                <svg-icon
                                  :name="getContactMethodState(visit.client, 'sms') === 'cannot_receive' ? 'close' : (getContactMethodState(visit.client, 'sms') === 'selected' ? 'check' : 'mobile')"
                                  class="mr-1 w-3 h-3" />
                                SMS
                              </span>
                            </template>
                            <template #tooltip>
                              <div class="px-2 py-1">{{ getContactMethodTooltip(visit.client, "sms") }}</div>
                            </template>
                          </tooltip>
                          <!-- Email Tag -->
                          <tooltip>
                            <template #visible>
                              <span :class="[
                                'inline-flex items-center px-2 py-1 rounded-md text-xs font-medium',
                                getContactMethodState(visit.client, 'email') === 'cannot_receive'
                                  ? 'bg-gray-100 text-red-400 opacity-50 line-through'
                                  : (getContactMethodState(visit.client, 'email') === 'selected' ? 'bg-green-100 text-green-800' : 'bg-gray-100 text-gray-600')
                              ]">
                                <svg-icon
                                  :name="getContactMethodState(visit.client, 'email') === 'cannot_receive' ? 'close' : (getContactMethodState(visit.client, 'email') === 'selected' ? 'check' : 'email-solid')"
                                  class="mr-1 w-3 h-3" />
                                Email
                              </span>
                            </template>
                            <template #tooltip>
                              <div class="px-2 py-1">{{ getContactMethodTooltip(visit.client, "email") }}</div>
                            </template>
                          </tooltip>
                        </div>
                      </div>
                      <!-- Send status -->
                      <transition name="fade">
                        <span v-if="contactStatuses[visit.client.id]" :class="[
                          contactStatuses[visit.client.id] === 'Sending'
                            ? 'pulsating text-yellow-600'
                            : (contactStatuses[visit.client.id] === 'Sent'
                              ? 'text-green-600'
                              : 'text-red-600'),
                          'text-xs font-medium'
                        ]">
                          {{ contactStatuses[visit.client.id] }}
                        </span>
                      </transition>
                    </div>
                  </div>
                  <MemberVisitStatusBadge :singleEvent="singleEvent" :visit="visit" class="py-0.5 px-2 text-xs" />
                </div>
              </div>
            </div>
          </div>

          <!-- Right Column - Message Form -->
          <div>
            <form :disabled="sending" class="space-y-4" @submit.prevent="sendNow">
              <div>
                <label class="form-label mb-1" for="sendingMethod">Medium</label>
                <select v-model="sendingMethod" :disabled="sending" class="form-select form-input-default"
                  name="sendingMethod">
                  <option value="all">Any method (SMS, or email if not possible)</option>
                  <option value="prefer_email">Email if possible, SMS fallback</option>
                  <option value="sms">Only SMS</option>
                  <option value="email">Only Email</option>
                </select>
              </div>
              <div v-if="sendingMethod != 'sms'">
                <text-input v-model="subject" :disabled="sending" :error="validationErrors.subject" label="Subject"
                  placeholder="Subject of the Email" required />
              </div>
              <div>
                <text-area-input v-model="message" :disabled="sending" :error="validationErrors.message"
                  :max-length="smsData.maxLength" :rows="5" placeholder="Content of your message" replace-utf
                  label="Message" required>
                  <template #internal>
                    <s-m-s-units-counter :msg="message" :units="3" class="pl-2 text-gray-500 text-sm pb-2" />
                  </template>
                  <template #right_of_label>
                    <merge-tags-dropdown @tag="addMergeTag" />
                  </template>
                </text-area-input>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <template #buttons>
      <div class="flex items-center">
        <p v-if="validationErrors.clients" class="text-red-500 text-sm mr-4">
          {{ validationErrors.clients }}
        </p>
        <button v-if="selectedClientIds.length > 0" :disabled="sending" class="btn btn-loopspark" type="button"
          @click="sendNow">
          {{
            sending ?
              "Sending..." :
              `Send to ${selectedClientIds.length} ${selectedClientIds.length === 1 ? "client" : "clients"}`
          }}
        </button>
      </div>
    </template>
  </modal>
</template>

<style>
.contact-method-cannot_receive {
  @apply text-red-500 opacity-50;
}

.contact-method-available {
  @apply text-gray-500;
}

.contact-method-selected {
  @apply text-blue-600;
}

/* Pulsating animation for "Sending" */
@keyframes pulsate {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

.pulsating {
  animation: pulsate 1s ease-in-out infinite;
}

/* Fade transition for status changes */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>

<script lang="ts" setup>
import Modal from "@/components/layout/Modal.vue";
import TextInput from "@/components/layout/Forms/TextInput.vue";
import TextAreaInput from "@/components/layout/Forms/TextAreaInput.vue";
import MergeTagsDropdown from "@/views/Events/Components/Show/MergeTagsDropdown.vue";
import Tooltip from "@/components/layout/Tooltip.vue";
import { computed, ref, toRef, watch } from "vue";
import { QLCreateDiscussionEventMutationVariables, QLSingleClassFragmentFragment, useCreateDiscussionEventMutation } from "@/graphql/queries/ql/composables";
import SMSUnitsCounter from "@/components/layout/SMSUnitsCounter.vue";
import { smsLengthInit } from "@/composables/smsLength";
import ClientAvatar from "@/components/client/ClientAvatar.vue";
import SvgIcon from "@/components/SvgIcon.vue";
import MemberVisitStatusBadge from "@/components/MemberVisitStatusBadge.vue";

// Accordion state
const isDropdownOpen = ref(false);

// Default segments that should always be visible
const DEFAULT_SEGMENTS = ["upcoming", "any_cancelled", "any", "absent", "attended"];

// Computed properties for segment filtering
const visibleSegments = computed(() => {
  if (!props.segments) {
    return [];
  }
  return props.segments.filter(segment =>
    segment.visible_dashboard === true || DEFAULT_SEGMENTS.includes(segment.id)
  );
});

const otherSegments = computed(() => {
  if (!props.segments) {
    return [];
  }
  return props.segments.filter(segment =>
    !segment.visible_dashboard && !DEFAULT_SEGMENTS.includes(segment.id)
  );
});

// Toggle accordion
const toggleDropdown = () => {
  isDropdownOpen.value = !isDropdownOpen.value;
};

interface SegmentData {
  id: string;
  name: string;
  loading: boolean;
  clientIds: string[];
  visible_dashboard: boolean;
}

interface Props {
  singleEvent: QLSingleClassFragmentFragment;
  open: boolean;
  segments?: SegmentData[];
}

const props = withDefaults(defineProps<Props>(), {});
const emit = defineEmits(["update:open", "sent"]);

const openModel = computed({
  get: () => props.open,
  set: (val) => emit("update:open", val)
});

const sendingMethod = ref<"all" | "sms" | "email" | "prefer_email">("all");
const subject = ref("");
const message = ref("");
const sending = ref(false);
const smsData = smsLengthInit(message);
const selectedClientIds = ref<string[]>([]);
const contactStatuses = ref<Record<string, string>>({});
const deselectionMessages = ref<Record<string, string>>({});

// Reset validation errors when inputs change
watch([message, subject], () => {
  // Clear specific validation errors when the related fields change
  if (message.value.trim() && validationErrors.value.message) {
    delete validationErrors.value.message;
  }

  if (subject.value.trim() && validationErrors.value.subject) {
    delete validationErrors.value.subject;
  }
});

// Reset validation errors when client selection changes
watch(selectedClientIds, (newValue) => {
  if (newValue.length > 0 && validationErrors.value.clients) {
    delete validationErrors.value.clients;
  }
});

// Reset form when modal is closed
watch(openModel, (isOpen) => {
  if (!isOpen) {
    // Clear validation errors when modal is closed
    validationErrors.value = {};
  }
});

// Watch for changes in sending method to re-evaluate eligibility
watch(sendingMethod, (newMethod) => {
  const previouslySelected = [...selectedClientIds.value];

  // Clear previous deselection messages
  deselectionMessages.value = {};

  // Re-evaluate each selected client
  previouslySelected.forEach(clientId => {
    const client = filteredVisits.value.find(v => v.client.id === clientId)?.client;
    if (client && !hasContactMethod(client)) {
      // Remove client from selection
      selectedClientIds.value = selectedClientIds.value.filter(id => id !== clientId);

      // Store deselection message
      let reason = "";
      if (newMethod === "sms" && !client.can_contact_by_sms) {
        reason = "Cannot be contacted via SMS";
      } else {
        if (newMethod === "email" && !client.can_contact_by_email) {
          reason = "Cannot be contacted via email";
        } else {
          if (newMethod === "prefer_email" && !client.can_contact_by_email && !client.can_contact_by_sms) {
            reason = "No valid contact method available";
          }
        }
      }
      deselectionMessages.value[clientId] = reason;
    }
  });
});

const singleEvent = toRef(props, "singleEvent");

const getContactMethodState = (client: any, method: "sms" | "email") => {
  const isSelected = selectedClientIds.value.includes(client.id);
  const canContactSMS = client.can_contact_by_sms;
  const canContactEmail = client.can_contact_by_email;

  if (method === "sms" && !canContactSMS) {
    return "cannot_receive";
  }

  if (method === "email" && !canContactEmail) {
    return "cannot_receive";
  }

  if (!isSelected) {
    return "available";
  }

  // If selected, determine which method will be used based on sendingMethod
  if (sendingMethod.value === "all") {
    if (method === "sms" && canContactSMS) {
      return "selected";
    }
    if (method === "email" && !canContactSMS && canContactEmail) {
      return "selected";
    }
    return "available";
  }

  if (sendingMethod.value === method) {
    return "selected";
  }

  if (sendingMethod.value === "prefer_email") {
    if (method === "email" && canContactEmail) {
      return "selected";
    }
    if (method === "sms" && !canContactEmail && canContactSMS) {
      return "selected";
    }
  }

  return "available";
};

const getContactMethodTooltip = (client: any, method: "sms" | "email") => {
  const canContact = method === "sms" ? client.can_contact_by_sms : client.can_contact_by_email;
  const name = `${client.first_name}`;

  if (!canContact) {
    if (method === "sms") {
      return `${name} cannot be contacted via SMS`;
    } else {
      return `${name} cannot be contacted via email`;
    }
  }

  const state = getContactMethodState(client, method);
  if (state === "selected") {
    return `${name} will be contacted via ${method.toUpperCase()}`;
  }

  return `${name} can be contacted via ${method.toUpperCase()}`;
};

const hasContactMethod = (client: any) => {
  switch (sendingMethod.value) {
    case "sms":
      return client.can_contact_by_sms;
    case "email":
      return client.can_contact_by_email;
    case "prefer_email":
      return client.can_contact_by_email || client.can_contact_by_sms;
    case "all":
      return client.can_contact_by_sms || client.can_contact_by_email;
    default:
      return false;
  }
};

const filteredVisits = computed(() => {
  return singleEvent.value.visits;
});

const eligibleVisits = computed(() => filteredVisits.value.filter(visit => hasContactMethod(visit.client)));

const allClientIds = computed(() => eligibleVisits.value.map(v => v.client.id));

const isAllSelected = computed(() => {
  const eligibleIds = allClientIds.value;
  return eligibleIds.length > 0 && eligibleIds.every(id => selectedClientIds.value.includes(id));
});

const getSegmentVisits = (segment: SegmentData) => {
  return filteredVisits.value.filter(visit =>
    segment.clientIds.includes(visit.client.id) && hasContactMethod(visit.client)
  );
};

const isSegmentSelected = (segment: SegmentData) => {
  const segmentVisits = getSegmentVisits(segment);
  const eligibleVisits = segmentVisits.filter(visit => hasContactMethod(visit.client));
  return eligibleVisits.length > 0 && eligibleVisits.every(visit => selectedClientIds.value.includes(visit.client.id));
};

const toggleAll = () => {
  if (isAllSelected.value) {
    selectedClientIds.value = [];
  } else {
    // Only select clients that can be contacted with current medium
    const eligibleClients = filteredVisits.value.filter(visit => hasContactMethod(visit.client));
    selectedClientIds.value = eligibleClients.map(visit => visit.client.id);
  }
};

const toggleSegment = (segment: SegmentData) => {
  const segmentVisits = getSegmentVisits(segment);
  const eligibleSegmentVisits = segmentVisits.filter(visit => hasContactMethod(visit.client));
  const eligibleClientIds = eligibleSegmentVisits.map(visit => visit.client.id);

  if (isSegmentSelected(segment)) {
    selectedClientIds.value = selectedClientIds.value.filter(id => !eligibleClientIds.includes(id));
  } else {
    selectedClientIds.value = [...new Set([...selectedClientIds.value, ...eligibleClientIds])];
  }
};

const addMergeTag = (tag: string) => {
  message.value = message.value + tag;
};

const sendMutation = useCreateDiscussionEventMutation({});

// Validation state
const validationErrors = ref<Record<string, string>>({});

const validateForm = (): boolean => {
  validationErrors.value = {};

  // Validate client selection
  if (selectedClientIds.value.length === 0) {
    validationErrors.value.clients = "Please select at least one client to send to";
    return false;
  }

  // Validate message
  if (!message.value.trim()) {
    validationErrors.value.message = "Message is required";
    return false;
  }

  // Validate subject for email
  if (sendingMethod.value !== 'sms' && !subject.value.trim()) {
    validationErrors.value.subject = "Subject is required for email messages";
    return false;
  }

  return true;
};

const sendNow = async () => {
  if (sending.value) {
    return false;
  }

  // Validate form before sending
  if (!validateForm()) {
    return false;
  }

  sending.value = true;

  // Reset status for selected clients
  selectedClientIds.value.forEach(id => {
    contactStatuses.value[id] = "Sending";
  });

  try {
    await Promise.all(selectedClientIds.value.map(async (clientId) => {
      const visitFound = filteredVisits.value.find(v => v.client.id === clientId);
      if (!visitFound) {
        console.error(`Visit not found for clientId: ${clientId}`);
        return;
      }
      let vars: QLCreateDiscussionEventMutationVariables = {
        client_id: clientId,
        message: message.value,
        is_email: getContactMethodState(visitFound.client, "email") == "selected"
      };

      if (vars.is_email) {
        vars.subject = subject.value;
      }

      await sendMutation.mutate(vars);
      contactStatuses.value[clientId] = "Sent";
    }));

    emit("sent");
    setTimeout(() => {
      sending.value = false;
      openModel.value = false;
    }, 500);
  } catch (error) {
    console.error("Error sending messages:", error);
    selectedClientIds.value.forEach(id => {
      if (contactStatuses.value[id] === "Sending") {
        contactStatuses.value[id] = "Failed";
      }
    });
    sending.value = false;
  }
};
</script>
