<template>
  <div class="min-h-full">
    <div class="text-xl text-gray-900 font-medium">New Filter - Select a category</div>
    <div class="text-sm text-gray-600">Create a new filter to segment your audience by selecting a category below.
                                       Audience segments commonly contain multiple rules, each rule being a
                                       collection of filters that your clients must meet in order to appear in your
                                       segment.
    </div>

    <div class="py-4">
      <ul class="divide-y" ref="ullist">
        <li v-for="(catInfo,index) in displayedCategories"
            v-bind:key="catInfo.name"
            class="py-4 px-2 hover:bg-blue-50 cursor-pointer focus:bg-green-50 focus:ring-0 focus:outline-none"
            v-bind:class="{'bg-green-50': selectedIndex === index}"
            role="menuitem"
            tabindex="0"
            v-on:keyup.enter="selectCategory(catInfo.name)"
            v-on:keyup.space="selectCategory(catInfo.name)"
            @click="selectCategory(catInfo.name)">
          <div class="flex items-center justify-between">
            <div class="grow">
              <span class="font-medium text-base">{{ catInfo.name }}</span>
              <div class="text-xs text-gray-500">{{ catInfo.description }}</div>

            </div>
            <div>
              <svg-icon name="right" class="h-4 w-4 text-gray-500" />
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script lang="ts" setup>

import SvgIcon from "@/components/SvgIcon.vue";

import {computed, onBeforeUnmount, onMounted, ref, toRef} from "vue";

interface Props {
  is_event?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  is_event: false
});

const emit = defineEmits(["selectedCategory", "stepBack"]);

const ullist = ref(null);

const selectedIndex = ref(-1);
const is_event = toRef(props, "is_event");

// Base categories that are always available
const baseCategories = [
  {
    name:        "Booking",
    description: "The Booking category contains filters regarding all client bookings (group classes, appointments, etc). Bookings can be for events that occurred in the past, are scheduled to occur in the future, and can be further filtered by the client's attendance status"
  },

  {
    name:        "Calendar",
    description: "The Calendar category of filters deals with a specific day of the week or day of the month, and will almost never be used to create a segment, but may be useful for controlling trigger points for automations."
  },
  {
    name:        "Client",
    description: "The Client category of filters deals with information specific to your client. This is mostly data that is synced from the information section of your client within your booking platform, but also includes a few other data points added by LoopSpark."
  },
  {
    name:        "Communication",
    description: "The Communications filters are all related to the content, timeframe, and user action created from your communication. Want to target users who opened an email but never purchased anything? Or create a retention campaign to contact clients 30 days after each prior retention email? These are the filter for you."
  },
  {
    name:        "Contract",
    description: "The Contract filter category contains filters for auto-pay contracts."
  },
  {
    name:        "Package",
    description: "The package filter category contains filters about the a clients packages. These filters will almost always be used in rules containing other package filters."
  },
  {
    name:        "Product",
    description: "The Product filter category contains filters about the a clients products."
  },
  {
    name:        "Sale",
    description: "The Sales filter category contains filters regarding the general sales of packages and/or products."
  },
  {
    name:        "Form",
    description: "The Form filters are all related to the completion, timeframe, and number of times a client has completed a specific form, or forms within a form category."
  },
  {
    name:        "ToDo",
    description: "The Form filters are all related to the completion, timeframe, and number of times a client has completed a specific form, or forms within a form category."
  }
];

// This Event category - only shown when is_event is true
const thisEventCategory = {
  name:        "This Event",
  description: "The This Event filter category contains filters about the current event. These filters help you segment clients based on their interaction with this specific event."
};

// Computed categories based on is_event prop
const displayedCategories = computed(() => {
  if (is_event.value) {
    return [...baseCategories, thisEventCategory];
  }
  return baseCategories;
});

const selectCategory = (name) => {
  emit('selectedCategory', name);
}

const nextItem = (event: KeyboardEvent) =>{
  if (event.key == "ArrowDown" || event.key == "ArrowUp") {
    if (event.key == "ArrowDown") {
      selectedIndex.value += 1;
    }
    if (event.key == "ArrowUp") {
      selectedIndex.value -= 1;
    }

    if (selectedIndex.value < 0) {
      selectedIndex.value = 0;
    }
    if (selectedIndex.value > displayedCategories.value.length - 1) {
      selectedIndex.value = displayedCategories.value.length - 1;
    }

    ullist.value.getElementsByTagName("li")[selectedIndex.value].focus();
  }
}
onMounted(() => {
  selectedIndex.value = -1;
  if (document.activeElement instanceof HTMLElement) {
    document.activeElement.blur();
  }

  document.addEventListener("keyup", nextItem);
})

onBeforeUnmount(() => {
  document.removeEventListener("keyup", nextItem);
})


</script>
