import Operator from "hm-filters/operators/operator";

import { v4 as uuidv4 } from "uuid";

export interface FilterOption {
	field: string;
}

export default abstract class Filter {
	name: string;

	uid: string = uuidv4();
	on_mindbody: boolean = true;
	on_clubready: boolean = true;
	// true is all
	visible_on: number[] | boolean = true;
	hidden_on: number[] = [];
	enabledIfOthersPresent: boolean = false;
	operators: Operator[] = [];
	reversedDisplay: boolean = false;

	group?: string;
	path: string[] = [];
	smallText?: string;

	description: string = null;
	isDateFilter = false;
	deprecated: boolean = false;

	constructor(name: string, options: FilterOption) {
		this.name = name;
		this._options = options;
	}

	_options: FilterOption;

	get options() {
		return this._options;
	}

	abstract get type(): string;

	get hasOperators() {
		if (this.operators && this.operators.length > 0) {
			return true;
		}
		return false;
	}

	get fullId(): string {
		return this.path.join(".") + "." + this.group + "." + this.type + "." + this.smallText + "." + this.name;
	}

	setOptions(options: FilterOption) {
		this._options = options;
		return this;
	}

	setDescription(description: string) {
		this.description = description;
		return this;
	}

	getCloned() {
		let cloned: Filter = Object.assign(Object.create(Object.getPrototypeOf(this)),
		                                   this);
		cloned.operators = cloned.operators.map((res) => {
			return Object.assign(Object.create(Object.getPrototypeOf(res)), res);
		});
		return cloned;
	}

	newUid(): string {
		this.uid = uuidv4();
		return this.uid;
	}

	setReversedDisplay(reversed: boolean = true) {
		this.reversedDisplay = reversed;
		return this;
	}

	visibleOnlyOn(providerId: number[] | number) {
		if (Array.isArray(providerId)) {
			this.visible_on = providerId;
		} else {
			this.visible_on = [ providerId ];
		}
		return this;
	}

	hideOn(providerId: number[] | number) {
		if (Array.isArray(providerId)) {
			this.hidden_on = providerId;
		} else {
			this.hidden_on = [ providerId ];
		}
		return this;
	}

	hideOnMindbody() {
		return this.hideOn(1);
	}

	hideOnClubready() {
		return this.hideOn(2);
	}

	setPath(path: string[]) {
		this.path = path;
		return this;
	}

	clearOperators() {
		this.operators = [];
		return this;
	}

	addOperators(operators: Operator[]) {
		operators.forEach((item) => this.operators.push(item));

		return this;
	}

	withoutOperators() {
		return this.clearOperators();
	}

	setOperators(value: Operator[]) {
		this.operators = value;
		return this;
	}

	changeGroup(newGroup: string) {
		this.group = newGroup;
		return this;
	}

	setDateFilter(isDate: boolean = true) {
		this.isDateFilter = true;
		return this;
	}

	get toPost() {
		return {
			uid:     <string> this.uid,
			type:    <string> this.type,
			options: <string> JSON.stringify(this.options)
		};
	}

	setDeprecated() {
		this.deprecated = true;
		return this;
	}

	getDisplayTag(): string[] {
		return this.path;
	}

	// get toPost() {
	// 	let ret = {
	// 		type:    this.type,
	// 		options: this._options,
	// 		uid:     this._uid
	// 	};
	//
	// 	if (this._selectedOperator != false) {
	// 		ret.options.operator = this._selectedOperator.value;
	//
	// 		if (this._selectedOperator.hasFixedValue) {
	// 			ret.options.value = this._selectedOperator.fixedValue;
	// 		}
	//
	// 	}
	//
	//
	// 	return ret;
	// }
	//
	// get toPostQl() {
	// 	let ret = this.toPost;
	// 	ret.options = JSON.stringify(ret.options);
	// 	return ret;
	// }
}
