<template>
  <div class="px-4 py-2">
    <div class="flex items-center justify-between">
      <div class="flex-1 min-w-0">
        <h3 class="text-sm font-medium text-gray-900 truncate">
          {{ note_template.title }}
        </h3>
        <div class="mt-1 text-xs text-gray-500 max-h-24 overflow-hidden" v-html="note_template.body"></div>
      </div>
      <div class="ml-4 flex-shrink-0 flex space-x-2">
        <button
            class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            @click="editTemplate">
          <span class="sr-only">Edit</span>
          <svg-icon name="edit" class="h-5 w-5" />
        </button>
        <button
            class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            @click="confirmDelete">
          <span class="sr-only">Delete</span>
          <svg-icon name="trash" class="h-5 w-5" />
        </button>
      </div>
    </div>
    <div v-if="showEditModal">
      <studio-note-templates-edit
          :note_template="note_template"
          @close="showEditModal = false" />
    </div>
    <div v-if="showDeleteModal">
      <confirm-modal
          :open="showDeleteModal"
          :title="`Delete Template: ${note_template.title}`"
          :description="`Are you sure you want to delete this template? This action cannot be undone.`"
          confirm-text="Delete"
          confirm-type="danger"
          @update:open="showDeleteModal = $event"
          @clicked="deleteTemplate" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref } from "vue";
import SvgIcon from "@/components/SvgIcon.vue";
import ConfirmModal from "@/components/layout/ConfirmModal.vue";
import StudioNoteTemplatesEdit from "@/views/Studio/Settings/NoteTemplate/StudioNoteTemplatesEdit.vue";
import { useNoteTemplateDeleteMutation } from "@/graphql/queries/ql/composables";
import $toast from "@/composables/toast";

interface Props {
  note_template: {
    id: string;
    title: string;
    body: string;
  };
}

const props = defineProps<Props>();
const emit = defineEmits(['template-deleted']);

const showEditModal = ref(false);
const showDeleteModal = ref(false);

const editTemplate = () => {
  showEditModal.value = true;
};

const confirmDelete = () => {
  showDeleteModal.value = true;
};

const deleteTemplateMutation = useNoteTemplateDeleteMutation();

const deleteTemplate = async () => {
  try {
    await deleteTemplateMutation.mutate({ id: props.note_template.id });
    $toast({
      type: "success",
      message: "Template deleted successfully",
      linkRouteObj: null,
      linkText: null
    });
    showDeleteModal.value = false;
    
    // Emit event after successful deletion
    emit('template-deleted');
  } catch (error) {
    $toast({
      type: "error",
      message: "Failed to delete template",
      linkRouteObj: null,
      linkText: null
    });
  }
};
</script>