import ToDo from "./partials/todo";
import OperatorGenerator from "../operatorGenerator";

class ToDoCountFilter extends ToDo {
	constructor(name: string, options: any) {
		super(name, options);

		this.operators = OperatorGenerator.numericOperators("ToDos");
	}

	get type(): string {
		return "ToDoCountFilter";
	}
}

export default ToDoCountFilter;