<template>
  <div>
    <router-link
        :to="{name: routeList.client.todos.index, params:{id: client.id}}"
        class="text-blue-600 hover:text-blue-800 flex items-center mb-4">
      <div class="block">
        <svg-icon
            name="left"
            class="w-4 h-4"
            icon="left" />
      </div>
      <span class="text-sm">All To-Dos with {{ client.first_name }}</span>
    </router-link>

    <div class="w-full h-64 flex items-center justify-center animate animate-pulse" v-if="!todo">
      <loader class="w-16 h-16 text-gray-500"></loader>
    </div>
    <main
        v-if="todo"
        class="flex-1">
      <div class="bg-white p-4 py-8 rounded shadow">
        <div class="px-2 sm:px-2 lg:px-2 xl:grid xl:grid-cols-3">
          <div class="xl:col-span-2 xl:pr-8 xl:border-r xl:border-gray-200">
            <div>
              <div>
                <div class="md:flex md:items-center md:justify-between md:space-x-4 xl:border-b xl:pb-6">
                  <div>
                    <h1 class="text-2xl font-bold text-gray-900">
                      {{ todo.subject }}
                    </h1>

                    <div class="mt-2 text-sm text-gray-500">
                      Created by
                      <span
                          v-if="!!todo.created_by"
                          class="font-medium text-gray-900">{{ todo.created_by.name }}</span>
                      <router-link
                          v-if="!!todo.automation"
                          :to="{name: routeList.automations.show.index, params:{id: todo.automation.id}}"
                          class="inline font-medium text-blue-900 hover:text-blue-600">
                        automation:
                        {{
                          todo.automation.name
                        }}
                      </router-link>
                      on
                      <timestamp-to-date
                          :timestamp="todo.created_at"
                          class="inline font-medium text-gray-900"
                          has-date
                          has-time
                          natural-date />
                      <span class=""> (<time-ago
                          :timestamp="todo.created_at"
                          class="inline" />)</span>
                    </div>
                  </div>
                </div>
                <aside class="mt-8 xl:hidden">
                  <h2 class="sr-only">
                    Details
                  </h2>
                  <h2 class="sr-only">
                    Details
                  </h2>
                  <div v-if="decorator.isOpen || todo.is_postponed">
                    <div class="flex justify-between">
                      <button
                          type="button"
                          class="inline-flex justify-center px-4 py-2 border border-green-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-green-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-900"
                          @click="modals.complete = true">
                        <svg-icon
                            name="check"
                            class="-ml-1 mr-2 h-5 w-5 text-green-400" />
                        <span>Complete...</span>
                      </button>
                      <button
                          type="button"
                          class="inline-flex justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                          @click="modals.edit = true">
                        <svg-icon
                            name="edit"
                            class="-ml-1 mr-2 h-5 w-5 text-gray-400" />
                        <span>Edit</span>
                      </button>
                    </div>
                  </div>

                  <div
                      class="space-y-5"
                      :class="decorator.isOpen ? 'mt-8 border-t border-gray-200 py-8' : 'pb-8'">
                    <div
                        v-if="decorator.isOpen && !decorator.isOverduePast"
                        class="flex items-center space-x-2">
                      <svg-icon
                          name="lock-open"
                          class="w-5 h-5 text-green-500" />
                      <span class="text-green-700 text-sm font-medium">Open To-Do</span>
                    </div>

                    <div
                        v-if="decorator.isOpen && decorator.isOverduePast"
                        class="flex items-center space-x-2">
                      <svg-icon
                          name="lock-open"
                          class="w-5 h-5 text-red-500" />
                      <div class="text-red-700 text-sm font-medium">
                        Past Overdue since
                        <timestamp-to-date
                            :timestamp="todo.overdue_at"
                            class="font-bold"
                            has-date
                            has-time
                            natural-date />
                      </div>
                    </div>

                    <div
                        v-if="decorator.isOpen && decorator.isPostponed"
                        class="flex items-center space-x-2">
                      <svg-icon
                          name="calendar"
                          class="w-5 h-5 text-gray-500" />
                      <div class="text-gray-700 text-sm font-medium">
                        Postponed till
                        <timestamp-to-date
                            :timestamp="todo.postponed_to"
                            class="font-bold"
                            has-date
                            has-time
                            natural-date />
                      </div>
                    </div>

                    <div
                        v-if="decorator.isOpen && decorator.isOverdueFuture"
                        class="flex items-center space-x-2">
                      <svg-icon
                          name="calendar-duotone"
                          class="w-5 h-5 text-gray-500" />
                      <div class="text-gray-700 text-sm font-medium">
                        Overdue on
                        <timestamp-to-date
                            :timestamp="todo.overdue_at"
                            class="font-bold"
                            has-date
                            has-time
                            natural-date />
                      </div>
                    </div>

                    <div v-if="todo.is_completed">
                      <div class="flex items-start space-x-2">
                        <svg-icon
                            name="check"
                            class="w-5 h-5 text-gray-500" />
                        <div class="space-y-1">
                          <div class="text-gray-700 text-sm font-medium">
                            Completed by <span class="font-bold">{{ todo.completed_by.name }}</span>
                          </div>
                          <div class="text-xs text-gray-500">
                      <span class="font-bold">{{
                          completedType
                                              }}</span>
                            on
                            <timestamp-to-date
                                :timestamp="todo.completed_at"
                                class="inline"
                                has-date
                                has-time
                                natural-date />
                            <span> (<time-ago
                                :timestamp="todo.completed_at"
                                class="inline" />)</span>
                          </div>
                          <div
                              v-if="todo.completed_action_note && todo.completed_action_note.length > 0"
                              class="text-xs italic text-gray-500">
                            "{{ todo.completed_action_note }}"
                          </div>
                        </div>
                      </div>
                    </div>

                    <div v-if="todo.is_deleted">
                      <div class="flex items-start space-x-2">
                        <svg-icon
                            name="trash"
                            class="w-5 h-5 text-gray-500" />
                        <div class="space-y-1">
                          <div class="text-gray-700 text-sm font-medium">
                            Deleted by <span class="font-bold">{{ todo.deleted_by.name }}</span>
                          </div>
                          <div class="text-xs text-gray-500">
                            on
                            <timestamp-to-date
                                :timestamp="todo.deleted_at"
                                class="inline"
                                has-date
                                has-time
                                natural-date />
                            <span> (<time-ago
                                :timestamp="todo.deleted_at"
                                class="inline" />)</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                        v-if="todo.priority === priorities.URGENT"
                        class="flex items-center space-x-2">
                      <svg-icon
                          name="thermometerHigh"
                          class="w-5 h-5 text-orange-500" />
                      <span class="text-orange-900 text-sm font-medium">High priority</span>
                    </div>
                    <div
                        v-if="todo.priority === priorities.MEDIUM"
                        class="flex items-center space-x-2">
                      <svg-icon
                          name="thermometerMed"
                          class="w-5 h-5 text-purple-500" />
                      <span class="text-purple-900 text-sm font-medium">Medium priority</span>
                    </div>
                    <div
                        v-if="todo.priority === priorities.LOW"
                        class="flex items-center space-x-2">
                      <svg-icon
                          name="thermometerMed"
                          class="w-5 h-5 text-gray-500" />
                      <span class="text-gray-900 text-sm font-medium">Low priority</span>
                    </div>
                  </div>

                  <div class="border-t border-gray-200 py-8 space-y-8">
                    <div>
                      <h2 class="text-sm font-medium text-gray-500">
                        Assignees
                        <button
                            class="font-base text-xs text-blue-500 hover:text-blue-700"
                            @click="modals.assign = true">
                          (edit)
                        </button>
                      </h2>
                      <ul
                          role="list"
                          class="mt-3 space-y-3">
                        <li
                            v-if="decorator.assignedToEveryone"
                            class="flex justify-start">
                          <div
                              class="flex items-center space-x-3">
                            <div class="shrink-0">
                              <div class="bg-green-300 rounded-full h-5 w-5 flex items-center justify-center">
                                <svg-icon
                                    name="users"
                                    class="w-3 h-3" />
                              </div>
                            </div>
                            <div class="text-sm font-medium text-gray-900">
                              Everyone
                            </div>
                          </div>
                        </li>
                        <li
                            v-if="todo.team_group"
                            class="flex justify-start">
                          <div
                              class="flex items-center space-x-3">
                            <div class="shrink-0">
                              <div class="bg-loopspark-300 rounded-full h-5 w-5 flex items-center justify-center">
                                <svg-icon
                                    name="users"
                                    class="w-3 h-3" />
                              </div>
                            </div>
                            <div class="text-sm font-medium text-gray-900">
                              {{ todo.team_group.name }}
                            </div>
                          </div>
                        </li>
                        <li
                            v-if="todo.assigned_to"
                            class="flex justify-start">
                          <div
                              class="flex items-center space-x-3">
                            <div class="shrink-0">
                              <avatar
                                  :image="todo.assigned_to.photo_url"
                                  :username="todo.assigned_to.name"
                                  class="w-5 h-5 block"
                                  style="display:block" />
                            </div>
                            <div class="text-sm font-medium text-gray-900">
                              {{ todo.assigned_to.name }}
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div
                      v-if="decorator.isOpen"
                      class="border-t border-gray-200 py-6 space-y-8">
                    <button
                        type="button"
                        class="inline-flex justify-center px-4 py-2 border border-red-300 shadow-sm text-sm font-medium rounded-md text-red-700 bg-white hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-900"
                        @click="modals.delete = true">
                      <svg-icon
                          name="trash"
                          class="-ml-1 mr-2 h-5 w-5 text-red-400" />
                      <span>Delete...</span>
                    </button>
                  </div>
                </aside>
                <div class="py-3 xl:pt-6 xl:pb-0">
                  <h2 class="sr-only">
                    Description
                  </h2>
                  <div class="prose max-w-none ql-editor">
                    <div v-html="todo.message" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <aside class="hidden xl:block xl:pl-8">
            <h2 class="sr-only">
              Details
            </h2>
            <div v-if="decorator.isOpen">
              <div class="flex justify-between flex-wrap">
                <div>
                  <button
                      type="button"
                      class="inline-flex justify-center px-4 py-2 border border-green-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-green-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-900"
                      @click="modals.complete = true">
                    <svg-icon
                        name="check"
                        class="-ml-1 mr-2 h-5 w-5 text-green-400" />
                    <span>Complete...</span>
                  </button>
                </div>
                <div>
                  <button
                      type="button"
                      class="inline-flex justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                      @click="modals.edit = true">
                    <svg-icon
                        name="edit"
                        class="-ml-1 mr-2 h-5 w-5 text-gray-400" />
                    <span>Edit</span>
                  </button>
                </div>
              </div>
            </div>

            <div
                class="space-y-5"
                :class="decorator.isOpen ? 'mt-8 border-t border-gray-200 py-8' : 'pb-8'">
              <div
                  v-if="decorator.isOpen && !decorator.isOverduePast"
                  class="flex items-center space-x-2">
                <svg-icon
                    name="lock-open"
                    class="w-5 h-5 text-green-500" />
                <span class="text-green-700 text-sm font-medium">Open To-Do</span>
              </div>

              <div
                  v-if="decorator.isOpen && decorator.isOverduePast"
                  class="flex items-center space-x-2">
                <svg-icon
                    name="lock-open"
                    class="w-5 h-5 text-red-500" />
                <div class="text-red-700 text-sm font-medium">
                  Past Overdue since
                  <timestamp-to-date
                      :timestamp="todo.overdue_at"
                      class="font-bold"
                      has-date
                      has-time
                      natural-date />
                </div>
              </div>

              <div
                  v-if="decorator.isOpen && decorator.isPostponed"
                  class="flex items-center space-x-2">
                <svg-icon
                    name="calendar"
                    class="w-5 h-5 text-gray-500" />
                <div class="text-gray-700 text-sm font-medium">
                  Postponed till
                  <timestamp-to-date
                      :timestamp="todo.postponed_to"
                      class="font-bold"
                      has-date
                      has-time
                      natural-date />
                </div>
              </div>

              <div
                  v-if="decorator.isOpen && decorator.isOverdueFuture"
                  class="flex items-center space-x-2">
                <svg-icon
                    name="calendar-duotone"
                    class="w-5 h-5 text-gray-500" />
                <div class="text-gray-700 text-sm font-medium">
                  Overdue on
                  <timestamp-to-date
                      :timestamp="todo.overdue_at"
                      class="font-bold"
                      has-date
                      has-time
                      natural-date />
                </div>
              </div>

              <div v-if="todo.is_completed">
                <div class="flex items-start space-x-2">
                  <svg-icon
                      name="check"
                      class="w-5 h-5 text-gray-500" />
                  <div class="space-y-1">
                    <div class="text-gray-700 text-sm font-medium">
                      Completed by <span class="font-bold">{{ todo.completed_by.name }}</span>
                    </div>
                    <div class="text-xs text-gray-500">
                      <span class="font-bold">{{
                          completedType
                                              }}</span>
                      on
                      <timestamp-to-date
                          :timestamp="todo.completed_at"
                          class="inline"
                          has-date
                          has-time
                          natural-date />
                      <span> (<time-ago
                          :timestamp="todo.completed_at"
                          class="inline" />)</span>
                    </div>
                    <div
                        v-if="todo.completed_action_note && todo.completed_action_note.length > 0"
                        class="text-xs italic text-gray-500">
                      "{{ todo.completed_action_note }}"
                    </div>
                  </div>
                </div>
              </div>

              <div v-if="todo.is_deleted">
                <div class="flex items-start space-x-2">
                  <svg-icon
                      name="trash"
                      class="w-5 h-5 text-gray-500" />
                  <div class="space-y-1">
                    <div class="text-gray-700 text-sm font-medium">
                      Deleted by <span class="font-bold">{{ todo.deleted_by.name }}</span>
                    </div>
                    <div class="text-xs text-gray-500">
                      on
                      <timestamp-to-date
                          :timestamp="todo.deleted_at"
                          class="inline"
                          has-date
                          has-time
                          natural-date />
                      <span> (<time-ago
                          :timestamp="todo.deleted_at"
                          class="inline" />)</span>
                    </div>
                  </div>
                </div>
              </div>

              <div
                  v-if="todo.priority === priorities.URGENT"
                  class="flex items-center space-x-2">
                <svg-icon
                    name="thermometerHigh"
                    class="w-5 h-5 text-orange-500" />
                <span class="text-orange-900 text-sm font-medium">High priority</span>
              </div>
              <div
                  v-if="todo.priority === priorities.MEDIUM"
                  class="flex items-center space-x-2">
                <svg-icon
                    name="thermometerMed"
                    class="w-5 h-5 text-purple-500" />
                <span class="text-purple-900 text-sm font-medium">Medium priority</span>
              </div>
              <div
                  v-if="todo.priority === priorities.LOW"
                  class="flex items-center space-x-2">
                <svg-icon
                    name="thermometerMed"
                    class="w-5 h-5 text-gray-500" />
                <span class="text-gray-900 text-sm font-medium">Low priority</span>
              </div>
            </div>

            <div class="border-t border-gray-200 py-8 space-y-8">
              <div>
                <h2 class="text-sm font-medium text-gray-500">
                  Assignees
                  <button
                      class="font-base text-xs text-blue-500 hover:text-blue-700"
                      @click="modals.assign = true">
                    (edit)
                  </button>
                </h2>
                <ul
                    role="list"
                    class="mt-3 space-y-3">
                  <li
                      v-if="decorator.assignedToEveryone"
                      class="flex justify-start">
                    <div
                        class="flex items-center space-x-3">
                      <div class="shrink-0">
                        <div class="bg-green-300 rounded-full h-5 w-5 flex items-center justify-center">
                          <svg-icon
                              name="users"
                              class="w-3 h-3" />
                        </div>
                      </div>
                      <div class="text-sm font-medium text-gray-900">
                        Everyone
                      </div>
                    </div>
                  </li>
                  <li
                      v-if="todo.team_group"
                      class="flex justify-start">
                    <div
                        class="flex items-center space-x-3">
                      <div class="shrink-0">
                        <div class="bg-loopspark-300 rounded-full h-5 w-5 flex items-center justify-center">
                          <svg-icon
                              name="users"
                              class="w-3 h-3" />
                        </div>
                      </div>
                      <div class="text-sm font-medium text-gray-900">
                        {{ todo.team_group.name }}
                      </div>
                    </div>
                  </li>
                  <li
                      v-if="todo.assigned_to"
                      class="flex justify-start">
                    <div
                        class="flex items-center space-x-3">
                      <div class="shrink-0">
                        <avatar
                            :image="todo.assigned_to.photo_url"
                            :username="todo.assigned_to.name"
                            class="w-5 h-5 block"
                            style="display:block" />
                      </div>
                      <div class="text-sm font-medium text-gray-900">
                        {{ todo.assigned_to.name }}
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div
                v-if="decorator.isOpen"
                class="border-t border-gray-200 py-6 space-y-8">
              <button
                  type="button"
                  class="inline-flex justify-center px-4 py-2 border border-red-300 shadow-sm text-sm font-medium rounded-md text-red-700 bg-white hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-900"
                  @click="modals.delete = true">
                <svg-icon
                    name="trash"
                    class="-ml-1 mr-2 h-5 w-5 text-red-400" />
                <span>Delete...</span>
              </button>
            </div>
          </aside>
        </div>
      </div>
    </main>

    <confirm-modal
        v-model:open="modals.delete"
        type="warning"
        :loading="deleteLoading"
        title="Are you sure?"
        @clicked="deleteTodo">
      <template #text>
        The To-Do for {{ todo.client.first_name }} will be marked as Deleted. It will still be
        visible in the list of
        To-Dos of {{ todo.client.first_name }}. Are you sure you want to delete it?
      </template>
      <template #button>
        Yes, delete it!
      </template>
    </confirm-modal>

    <client-single-todo-complete-modal
        v-if="todo"
        v-model:open="modals.complete"
        :todo="todo" />
    <client-single-todo-edit
        v-if="todo"
        v-model:open="modals.edit"
        :todo="todo" />
    <todo-assign-modal
        v-if="todo"
        v-model:open="modals.assign"
        :todo="todo" />
  </div>
</template>
<script lang="ts" setup>
import SvgIcon                                                                                     from "@/components/SvgIcon.vue";
import Loader                                                                                      from "@/components/layout/Loader.vue";
import TimestampToDate                                                                             from "@/components/layout/TimestampToDate.vue";
import TimeAgo                                                                                     from "@/components/layout/TimeAgo.vue";
import Avatar                                                                                      from "@/components/layout/Avatar.vue";
import ClientSingleTodoCompleteModal                                                               from "@/components/client/Todos/ClientSingleTodoCompleteModal.vue";
import ClientSingleTodoEdit                                                                        from "@/components/client/Todos/ClientSingleTodoEdit.vue";
import TodoAssignModal                                                                             from "@/components/client/Todos/TodoAssignModal.vue";
import { QLClient, QLTodoCompletionTypeEnum, QLTodoPriority, useTodoQuery, useUpdateTodoMutation } from "@/graphql/queries/ql/composables";
import routesList                                                                                  from "@/composables/routesList";
import { computed, reactive, ref, toRef }                                                          from "vue";
import todoDecorator                                                                               from "@/composables/decorators/TodoDecorator";
import ConfirmModal                                                                                from "@/components/layout/ConfirmModal.vue";
import $toast                                                                                      from "@/composables/toast";
import { EventTypes }                                                                              from "@/classes/notifications/EventTypes";
import { echoListener }                                                                            from "@/composables/echoListener";

interface Props {
  client: QLClient;
  todoId: string;
}

const { routeList } = routesList();

const props = withDefaults(defineProps<Props>(), {});

const client = toRef(props, "client");

ref(false);
const deleteLoading = ref(false);
ref(false);

const modals = reactive({
                          assign:   false,
                          delete:   false,
                          complete: false,
                          edit:     false
                        });

const priorities = QLTodoPriority;

const todoQ = useTodoQuery(() => ({ id: props.todoId }));
const todo = computed(() => todoQ.result.value?.todo ?? undefined);
const { loading } = todoQ;

echoListener([ EventTypes.TodoUpdated ], (ev) => {
  if (ev.data.todo_id == props.todoId) {
    todoQ.refetch();
  }
}, 5000);

const decorator = todoDecorator(todo).computed;

const completedType = computed(() => {
  if (!!todo.value) {
    switch (todo.value.completed_action_type) {
      case QLTodoCompletionTypeEnum.EMAIL:
        return "By email";
      case QLTodoCompletionTypeEnum.IN_PERSON:
        return "In person";
      case QLTodoCompletionTypeEnum.OTHER:
        return "Other";
      case QLTodoCompletionTypeEnum.PHONE_CALL:
        return "By phone call";
      case QLTodoCompletionTypeEnum.SMS:
        return "Via text message";
    }
  }
  return "";
});

const deleteTodoMutation = useUpdateTodoMutation({});

deleteTodoMutation.onDone(() => {
  deleteLoading.value = false;
  $toast({
           type:         "success",
           message:      "Successfully deleted the To-Do for " + todo.value.client.first_name,
           linkRouteObj: null,
           linkText:     null
         });
  modals.delete = false;
});

deleteTodoMutation.onError(() => {
  deleteLoading.value = false;
  $toast({
           type:         "error",
           message:      "There was a problem with your request. Please try again",
           linkRouteObj: null,
           linkText:     null
         });
});

const deleteTodo = () => {
  deleteLoading.value = true;
  deleteTodoMutation.mutate({
                              id:      todo.value.id,
                              deleted: true

                            });
};

</script>
